import React from "react";
import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import { RegularOrIndicacionProps } from './types';
import './RegularOrIndicacion.scss';

import Add from "../../assets/add-button.png"
import Vet from "../../assets/veterinary.png"
import Continue from "../Continue";

const RegularOrIndicacion: React.FC<RegularOrIndicacionProps> = ({
  addingSvfElement,
  setAddingSvfElement,
  setPrevSvf,
  setShowSvfOptions,
  currentSVF,
  regularLabel,
  indicacionLabel,
  goForward
}) => {
  return (
    <div className="regular-or-indicacion">
      <IonGrid>
        <div>
          <IonRow onClick={() => {
            setShowSvfOptions(false);
            setAddingSvfElement(true);
          }}>
            <IonCol size="4">
              <img src={Vet} />
            </IonCol>
            <IonCol offset="0.5">
              <div className="label">{regularLabel}</div>
            </IonCol>
          </IonRow>

          <IonRow onClick={() => { setPrevSvf(currentSVF) }}>
            <IonCol size="4">
              <img src={Add} />
            </IonCol>
            <IonCol offset="0.5">
              <div className="label">{indicacionLabel}</div>
            </IonCol>
          </IonRow>
        </div>

        <Continue hidden={addingSvfElement} modifyStep={goForward} />
      </IonGrid>
    </div>
  )
}

export default RegularOrIndicacion;