import React, { useState, useContext } from "react";
import { IonHeader, IonIcon, IonCol, IonGrid, IonRow } from "@ionic/react";
import { chatboxEllipsesOutline, chevronBackOutline, eyeOutline } from "ionicons/icons";

import { HeaderProps } from './types';
import './Header.scss';
import FielpetLogo from "../../assets/FielPetLogo.png";

import Stepper from "../Stepper";
import SvfInfo from "../SvfInfo";
import { HideHeaderContext } from "../../context/HideHeaderContext";
import { TutorialContext } from "../../context/TutorialContext";
import { findStepIndexByValue } from "../../utils/Form";
import Alert from "../Alert";

const Header: React.FC<HeaderProps> = ({ formStep, goBack, hideGoBack, confirmedSvf, addingSvfElement }) => {
  const { setTutorialDoneStatus } = useContext(TutorialContext);
  const { hideHeader } = useContext(HideHeaderContext);

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const [showAlert, setShowAlert] = useState<boolean>(false);

  return (
    <div
      className="header"
    >
      <IonHeader className="ion-no-border" collapse={hideHeader ? "condense" : undefined}>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol className="col">

              {formStep === findStepIndexByValue("datosUsuario") ?
                <IonIcon
                  onClick={() => setTutorialDoneStatus(false)}
                  data-tut="reactour__tutorial"
                  icon={eyeOutline}
                  color="primary"
                /> :
                <IonIcon
                  className={hideGoBack ? "hideIcon" : undefined}
                  onClick={goBack}
                  data-tut="reactour__go-back-btn"
                  icon={chevronBackOutline}
                  color="primary"
                />}

              <img src={FielpetLogo} onClick={() => setShowAlert(true)} />

              <IonIcon
                data-tut="reactour__stepTutorial"
                icon={chatboxEllipsesOutline}
                color="primary"
                onClick={() => setShowPopover({ showPopover: true, event: undefined })}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <div hidden={!confirmedSvf || addingSvfElement}>
          <Stepper
            formStep={formStep}
          />
        </div>

        <SvfInfo
          formStep={formStep}
          popoverState={popoverState}
          setShowPopover={setShowPopover}
        />
      </IonHeader>

      <Alert
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        subHeader={"¿Desea visitar el sitio web de Fielpet?"}
        buttonsLabel={{
          primary: "Visitar Fielpet",
          secondary: "Cerrar"
        }}
        actions={{
          primary: () => window.location.href = "https://fielpet.com",
          secondary: () => setShowAlert(false)
        }}
      />
    </div>
  )
}

export default Header;
