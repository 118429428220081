import React, { useEffect } from "react";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { paw, person, reader } from "ionicons/icons";
import { useGetDatosUsuario } from "../../hooks/useDatosUsuario";
import { DatosUsuarioProps } from "./types";
import "./DatosUsuario.scss"
import { getPetAge } from "../../utils/DateUtilities";
import Title from "../Title";
import Continue from "../Continue";
import Spinner from "../Spinner";

const DatosUsuario: React.FC<DatosUsuarioProps> = ({
  siniestroId,
  setDatosUsuario,
  goForward,
}) => {
  const { datos } = useGetDatosUsuario(siniestroId);
  const { nombre, apellido, tel_celular } = datos?.persona || {};
  const { nombre: nombreMascota, animal, raza, sexo, fecha_nacimiento } = datos?.mascota || {};
  const denuncia = datos?.denuncia_administrativa || {};

  useEffect(() => {
    datos && setDatosUsuario(datos);
  }, [datos]);

  return (
    <div className="datos-usuario ion-margin">
      {datos ? (
        <IonGrid className="ion-no-padding">
          <Title title="Datos del tutor" />

          <IonRow>
            <IonCol>
              <div>
                <IonIcon icon={person} />
              </div>
              <div>
                <div>{nombre} {apellido}</div>
                <div>{tel_celular}</div>
              </div>
            </IonCol>
          </IonRow>

          <Title title="Datos del paciente" />

          <IonRow>
            <IonCol>
              <div>
                <IonIcon icon={paw} />
              </div>
              <div>
                <div>{nombreMascota}</div>
                <div>{animal === "Perro" ? "Canino" : "Felino"}, {raza}</div>
                <div>{sexo === "h" || "H" ? "Macho" : "Hembra"}, {getPetAge(fecha_nacimiento)}</div>
              </div>
            </IonCol>
          </IonRow>

          <Title title="Descripción del tutor" />

          <IonRow>
            <IonCol>
              <div>
                <IonIcon icon={reader} />
              </div>
              <div>
                <div>{denuncia}</div>
              </div>
            </IonCol>
          </IonRow>

          <div className='ion-margin-top'>
            <Continue modifyStep={goForward} />
          </div>
        </IonGrid>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default DatosUsuario;
