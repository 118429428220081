import React, { useRef, useState, useEffect, useMemo, ReactNode } from 'react';
import { CSSProperties } from 'react';
import { DataNode } from './NestedSelector';
import ArcItem from './ArcItem';
import './ArcList.css';

const getGradientArray = (amountOfElements: number): string[] => {
  const result: string[] = [];
  const getRatio = (index: number) => Math.floor((255 / amountOfElements) * index);
  // const getColor = (index: number): string => `rgba(60,${160 + getRatio(index) * 0.2},${50 + getRatio(index) * 0.4},0.8)`;
  const getColor = (index: number): string => '#444444';
  for (let i = 0; i < amountOfElements; i++) {
    result.push(getColor(i));
  }
  return result;
}

type ArclistProps = { items: DataNode[], selectFunction: (a: number) => any, itemClass?: string, itemDetailIcon?: string | ReactNode };

const ArcList: React.FC<ArclistProps> = ({ items, selectFunction, itemClass, itemDetailIcon }) => {

  const [itemOffsets, setItemOffsets] = useState<number[]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const itemsRefs = useRef<HTMLDivElement[]>([]);
  const PADDING_TOP = 10;
  const ITEMS_SHOWN = Math.min(items.length, 7);
  const ITEM_HEIGHT = 35;
  const COMPONENT_HEIGHT = scrollRef.current?.clientHeight || -1;;
  const SCROLL_PADDING = 20;
  const ARC_WIDTH = (scrollRef.current?.clientWidth || 600) / 2.2;

  const calcHorizontalOffset = (screenHeight: number) => {
    let unbiasedHeight = screenHeight;
    if (scrollRef.current) {
      unbiasedHeight = screenHeight - scrollRef.current?.getBoundingClientRect().top;
    }
    const distanceFromCenter = Math.abs(unbiasedHeight - COMPONENT_HEIGHT / 2) - SCROLL_PADDING;
    const distanceToEdge = Math.abs(COMPONENT_HEIGHT / 2 - distanceFromCenter);
    const percentageToEdge = (100 / (COMPONENT_HEIGHT / 2)) * distanceToEdge;
    // @TODO mejorar calculo
    return Math.log(percentageToEdge) * (ARC_WIDTH / 15);
  }

  const itemColors = useMemo(() => getGradientArray(items.length), [items]);

  const handleScroll = () => {

    const newItemOffsets: number[] = []
    itemsRefs.current.forEach((ir, index) => {
      if (ir) {
        const { x, y } = ir.getBoundingClientRect();
        newItemOffsets[index] = calcHorizontalOffset(y);
      }
      setItemOffsets(newItemOffsets);
    });
  };

  useEffect(() => {
    // initialize offsets
    handleScroll();
  }, [COMPONENT_HEIGHT, items]);

  const listStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: "1rem",
    // gap: `${((COMPONENT_HEIGHT - PADDING_TOP) - ITEMS_SHOWN * ITEM_HEIGHT) / ITEMS_SHOWN}px`,
    // maxHeight: '85%',
    // paddingTop: `${PADDING_TOP}px`
  };

  return (
    <div onScroll={handleScroll} ref={scrollRef}
      className='arc-list-container'
    >
      <div className="arc-list"
        style={listStyles}
      >
        {items.map((item, index) => (
          <ArcItem
            itemClass={itemClass}
            key={index}
            label={item.label}
            currentItemsRefs={itemsRefs.current}
            index={index}
            item_height={ITEM_HEIGHT}
            item_offset={itemOffsets[index]}
            detailColor={itemColors[index]}
            detailIcon={itemDetailIcon}
            onClick={() => selectFunction(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ArcList;
