export interface Veterinario {
  id: string;
  nombre: string;
  apellido: string;
  colegio: string;
  dni: string;
  matricula: string;
  email: string;
  telefono: string;
  domicilio: string;
  tipo: string;
}
export const getEmptyVeterinario = (): Veterinario => ({
  id: "",
  nombre: "",
  apellido: "",
  colegio: "",
  dni: "",
  matricula: "",
  email: "",
  telefono: "",
  domicilio: "",
  tipo: "",
});
