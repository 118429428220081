import React from "react";
import { IonPopover } from "@ionic/react";

import { SvfInfoProps } from "./types";
import "./SvfInfo.scss";
import { stepsData } from "../../utils/Form";
import Help from "../../assets/help.png"

const SvfInfo: React.FC<SvfInfoProps> = ({
  popoverState,
  setShowPopover,
  formStep,
}) => {
  return (
    <IonPopover
      mode="ios"
      cssClass="popover"
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() =>
        setShowPopover({ showPopover: false, event: undefined })
      }
    >
      <div>
        <img src={Help} />
      </div>
      <div>
        <div className="title">{stepsData[formStep]?.stepInfo?.title}</div>
        <div>{stepsData[formStep]?.stepInfo?.info}</div>
      </div>
    </IonPopover>
  );
};

export default SvfInfo;
