import React from "react";
import { IonButton } from "@ionic/react";
import { ContinueProps } from "./types";
import { findStepIndexByValue } from "../../utils/Form";
import "./Continue.scss";

const Continue: React.FC<ContinueProps> = ({
  modifyStep,
  formStep,
  hidden
}) => {

  const label = formStep === findStepIndexByValue("usuario") ? "Completar historia clínica" : "Siguiente Paso"

  return (
    <div
      hidden={hidden} className="continue" data-tut="reactour__go-forward-btn">
      {modifyStep ? (
        <IonButton onClick={() => modifyStep()} mode="ios" expand="block" data-tut="reactour__disabled-goForward-btn">{label}</IonButton>
      ) : (
        <IonButton type="submit" mode="ios" expand="block" data-tut="reactour__disabled-goForward-btn">{label}</IonButton>
      )}
    </div>
  );
};

export default Continue;
