import React from "react";
import { TitleProps } from './types';

import './Title.scss';


const Title: React.FC<TitleProps> = ({ title, hidden }) => {
  return (
    <div
      className="title ion-margin-bottom"
    >
      {!hidden && <>{title}</>}
    </div>
  )
}

export default Title;