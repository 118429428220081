import React, { useEffect } from 'react';

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react';
import {
  globeOutline,
  logoFacebook,
  logoInstagram,
  logoWhatsapp,
} from 'ionicons/icons';

import Logo from '../../assets/FielPetLogo.png';
import './ErrorPage.scss';
import { useLocation } from 'react-router';

interface data {
  vetForm: string;
  siniestroId: string;
}

const ErrorPage: React.FC = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const datos = searchParams.get("data") ?? "-";
  //const data = location.state !== undefined ? location.state : emptyData;
  const msg = `https://wa.me/5493412828296?text=Hola%2C%20quise%20completar%20el%20formulario%20número%20${datos.split("-")[0]}%20del%20siniestro%20${datos.split("-")[1]}%20y%20figura%20como%20completado.`
  

  return (
    <IonPage className="error-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent>
        <div>
          <div className="ion-padding">
              <p><strong>El formulario al que quiere acceder ya fue completado</strong></p><br></br>
              <p>Si cree que es un error o tiene alguna alguna duda comuníquese con nosotros por <a href={msg} target="_blank">
              WhatsApp <IonIcon icon={logoWhatsapp} />
                </a></p> <br></br>
                <p><strong>Formulario {datos.split("-")[0]} correspondiente al siniestro {datos.split("-")[1]}</strong></p>
          </div>

          <div className='ion-padding-top'>
            <p>
              ¿Tenes alguna consulta o estas interesado en trabajar con FielPet?
            </p>

            <div className='ion-padding'>
              <p className="confirm-page__tel-email-box">
                Comunicate con nosotros por teléfono
                <a href="https://wa.me/5493412828296" target="_blank">
                  <IonIcon icon={logoWhatsapp} />
                </a>
                o por mail a
                <a href="mailto:info@fielpet.com.ar">info@fielpet.com.ar</a>
              </p>
            </div>
          </div>

          <IonGrid className='ion-no-padding ion-padding-top'>
            <IonRow>
              <IonCol>
                <p className="confirm-page__redes">
                  Conoce más de nosotros en nuestras redes:
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <a href="https://www.instagram.com/fiel.pet/">
                  <IonIcon icon={logoInstagram} />
                </a>
              </IonCol>
              <IonCol size="4">
                <a href="https://www.facebook.com/FielPet-2038763733099486">
                  <IonIcon icon={logoFacebook} />
                </a>
              </IonCol>
              <IonCol>
                <a href="https://www.fielpet.com.ar">
                  <IonIcon icon={globeOutline} />
                </a>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ErrorPage;
