import { useState, useEffect } from 'react';
import { getEstudios as axiosGetEstudios } from '../axios/getEstudios';
import { Estudios } from '../interfaces/Estudios';

export const useEstudios = () => {
  const [estudios, setEstudios] = useState<Estudios[]>([]);
  const [allEstudios, setAllEstudios] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const getEstudios = async () => {
    setIsLoading(true);
    const estudio = await axiosGetEstudios();
    setEstudios(estudio)
    setAllEstudios(estudio.map((e)=>e.tipo_estudio))
    setIsLoading(false);
  };

  useEffect(() => {
    getEstudios();
  }, []);
  // Se genera el array de string de allEstudios ya que donde se consumen se necesita un array de strings
  return { estudios, allEstudios, isLoading  };
};
