import { getVetFormLink } from "../../axios/getVetFormLink";
import VetForm from "../../components/VetForm";
import { SiniestroContextProvider } from "../../context/SiniestroContext";
import "./VetFormPage.scss";
import { useIonRouter } from "@ionic/react";
import React, { useEffect, useState } from "react";

type RefType = {
  vetForm: string;
  siniestro: string;
  //@TODO type
  subVetForms: any[];
};

const VetFormPage: React.FC = () => {
  const defaultRef: RefType = {
    vetForm: "",
    siniestro: "",
    subVetForms: [],
  };

  const router = useIonRouter();
  const ref = router.routeInfo.search.split("ref=")[1];
  const [vetFormLink, setVetFormLink] = useState<RefType>(defaultRef);
  const [loading, setLoading] = useState<boolean>(false);

  async function handleVetFormLink() {
    setLoading(true);
    const data = await getVetFormLink(ref);
    console.log(data);

    if (data) {
      setVetFormLink(data);
      setLoading(true);
    }
  }

  useEffect(() => {
    handleVetFormLink();
  }, [loading]);

  return (
    <SiniestroContextProvider siniestroId={vetFormLink.siniestro}>
      {loading && <VetForm vetFormLink={vetFormLink} loading={loading} />}
    </SiniestroContextProvider>
  );
};

export default VetFormPage;
