import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  IonCol,
  IonGrid,
  IonRow
} from '@ionic/react';

import { capitalizeFirstLetter } from '../../utils/General';
import { PracticasFormProps } from './types';
import { Practica } from '../../interfaces/forms/vetForms/Practica';
import {
  validationData,
  validationMoney,
} from '../../assets/validations/validationRegex';
import { practicaOptions, setFormValuesFromObject, submitSvf } from '../../utils/Form';
import Title from '../Title';
import FormInput from '../FormInput';
import FormSelectPopover from '../FormSelectPopover';
import AddingSvfBtns from '../AddingSvfBtns';
import { usePracticas } from '../../hooks/usePracticas';

const PracticasForm: React.FC<PracticasFormProps> = ({
  practicas,
  setPracticas,
  index,
  setAddingSvfElement,
  setShowToast
}) => {
  const { handleSubmit, control, errors, setValue } = useForm<Practica>();
  const formMethods = { control, errors };

  const { allPracticas, isLoading } = usePracticas();
  
  const onSubmit = (values: Practica) => {
    values.practicaId = String(practicaOptions.indexOf(values.practica!)+1);
    submitSvf(index, practicas, setPracticas, values, setShowToast);
  };
  // useEffect(()=>{
  //   setPracticasOptions(allPracticas)
  // },[isLoading])

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(practicas[index], setValue);
    }
  }, [index]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <IonGrid className='ion-no-padding'>

        <Title title={index !== undefined ? "Editar practica" : "Nueva practica"} />

        <IonRow>
          <IonCol hidden>
            {/* Practica Id*/}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: 'practicaId', label: '',
              }}
            />
          </IonCol>
          <IonCol>
            {/* Practica */}
            <FormSelectPopover
              formMethods={formMethods}
              options={practicaOptions}
              fieldProps={{
                name: 'practica', label: 'Práctica realizada'
              }}
              validationProps={{
                errorMessage: 'Por favor seleccione una práctica',
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {/* Descripción */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: 'descripcion', label: 'Descripción de la práctica',
                format: capitalizeFirstLetter,
              }}
              validationProps={{
                errorMessage: 'Por favor ingrese una descripción',
                pattern: validationData,
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {/* Costo */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: 'costo', label: 'Costo de la práctica',
                typeNumber: true
              }}
              validationProps={{
                errorMessage: 'Por favor ingrese un monto',
                pattern: validationMoney,
              }}
            />
          </IonCol>
        </IonRow>

        <AddingSvfBtns
          label={index !== undefined ? "cambios" : "práctica"}
          action={() => { setAddingSvfElement(false); }}
        />
      </IonGrid>
    </form>
  );
};

export default PracticasForm;
