import axiosInstance from ".";
import { Sintoma } from "../interfaces/Sintoma";
import { GET_SINTOMAS } from "./constants";
import { AxiosResponse } from "axios";

export const getSintomas = async () => {
  try {
    const { data: sintoma }: AxiosResponse<Sintoma[]> = await axiosInstance.get(
      GET_SINTOMAS
    );
    return sintoma;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
