import { validationMoney } from "../../assets/validations/validationRegex";
import { useSubVetForm } from "../../context/SubVetFormContext";
import {
  Consulta as ConsultaInterface,
  getEmptyConsulta,
} from "../../interfaces/forms/Consulta";
import { indicacionesMinDate, today } from "../../utils/DateUtilities";
import { getErrors } from "../../utils/Form";
import Continue from "../Continue";
import FormDate from "../FormDate";
import FormInput from "../FormInput";
import Title from "../Title";
import "./Consulta.scss";
import { ConsultaProps } from "./types";
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

const Consulta: React.FC<ConsultaProps> = ({ goForward }) => {
  const { consulta, setConsulta } = useSubVetForm();
  const { handleSubmit, control, errors } = useForm<ConsultaInterface>({
    defaultValues: consulta ?? getEmptyConsulta(),
  });

  const formMethods = { control, errors };

  const localizacion = [
    { value: "consultorio", label: "En el consultorio" },
    { value: "domicilio", label: "A domicilio" },
    { value: "virtual", label: "Virtual (Telefónica / WhatsApp)" },
  ];

  const onSubmit = (values: ConsultaInterface) => {
    setConsulta(values);
    goForward();
  };

  return (
    <div className="consulta animate__animated animate__fadeInRight">
      <form onSubmit={handleSubmit(onSubmit)} className="ion-margin">
        <IonGrid className="ion-no-padding">
          <Title title="Datos de la consulta" />

          {/* <IonRow>
            <IonCol>
              Motivo consulta
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'motivoConsulta', label: 'Motivo de la consulta / anamnesis',
                  placeholder: "Opcional", format: capitalizeFirstLetter
                }}
              />
            </IonCol>
          </IonRow> */}

          <IonRow>
            <IonCol>
              {/* Fecha Inicio */}
              <FormDate
                formMethods={formMethods}
                fieldProps={{
                  name: "fechaInicio",
                  label: "Fecha de la atención",
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese una fecha",
                }}
                date={{ max: today, min: indicacionesMinDate() }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Costo */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "costo",
                  label: "Costo total de la consulta",
                  typeNumber: true,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese un monto",
                  pattern: validationMoney,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-margin-bottom">
            <IonCol>
              {/* Localización de la consulta */}
              <Controller
                name="localizacion"
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: "Por favor, seleccione una opción",
                  },
                }}
                render={({ onChange, name, value }) => (
                  <>
                    <IonItem lines="none" className="ion-no-padding">
                      <IonRadioGroup
                        onIonChange={onChange}
                        name={name}
                        value={value}
                      >
                        <>
                          <IonLabel className="consulta__checkbox-label ion-margin-top">
                            Lugar donde se realizo la consulta
                          </IonLabel>

                          {localizacion.map((e, index) => (
                            <IonItem
                              key={index}
                              lines="none"
                              className="ion-no-padding"
                            >
                              <IonRadio value={e.value} mode="md" />
                              <IonLabel
                                color="medium"
                                className="ion-margin-start"
                              >
                                {e.label}
                              </IonLabel>
                            </IonItem>
                          ))}
                        </>
                      </IonRadioGroup>
                    </IonItem>

                    <div
                      className="consulta__error-message"
                      style={
                        getErrors(errors, name) ? { opacity: 1 } : undefined
                      }
                    >
                      {getErrors(errors, name)?.message}
                    </div>
                  </>
                )}
              />
            </IonCol>
          </IonRow>

          <div className="ion-padding-top">
            <Continue />
          </div>
        </IonGrid>
      </form>
    </div>
  );
};

export default Consulta;
