import React, { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { call, mail, person } from "ionicons/icons";
import { VetContactWrapperProps } from './types';
import './VetContactWrapper.scss';
import Plus from "../../assets/plus.png";
import Bin from "../../assets/bin.png"
import Pen from "../../assets/pen.png"
import { deleteElement } from "../../utils/Form";
import VetContactForm from "../VetContactForm";
import Alert from "../Alert";
import ToastMsg from "../ToastMsg";

const VetContactWrapper: React.FC<VetContactWrapperProps> = ({ indicaciones, index, tipoIndicacion, suggestedVets, setSuggestedVets, popoverState, setShowPopover }) => {
  const [vetIndex, setVetIndex] = useState<number>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);

  const vetContactFormProps = {
    indicaciones,
    index,
    suggestedVets,
    setSuggestedVets,
    popoverState,
    setShowPopover,
    vetIndex,
    setVetIndex,
  }

  useEffect(() => {
    if (index !== undefined) {
      setSuggestedVets(indicaciones[index].suggestedVets!)
    }
  }, [index])

  return (
    <div
      className="vet-contact-wrapper"
    >
      {/* //suggestedVets.length */}
      {suggestedVets.length !== 0 &&
        <div className="vet-contact-wrapper__newVet_box ion-margin-top">
          <div className="title">
            Veterinarios sugeridos
          </div>
          <div hidden={!tipoIndicacion || tipoIndicacion === "Farmacológica"}
            onClick={() => setShowPopover({ showPopover: true, event: undefined })}>
            <img src={Plus} />
          </div>
        </div>
      }

      <div>
        {tipoIndicacion && tipoIndicacion !== "Farmacológica" && !suggestedVets.length ?
          // NO VET CARD
          <div className="vet-contact-wrapper__no-vet-added"
            onClick={() => setShowPopover({ showPopover: true, event: undefined })}>
            <div>
              <div className="title">Veterinarios sugeridos</div>
              <div className="subtitle">No hay profesionales sugeridos</div>
            </div>
            <div hidden={!tipoIndicacion || tipoIndicacion === "Farmacológica"}>
              <img src={Plus} />
            </div>
          </div>

          //ADDED VETS
          : suggestedVets.map((vet, index) => (
            <div key={index} className="vet-contact-wrapper__suggestedVetCard ion-margin-top">

              <div className="options-icons">
                <div onClick={() => {
                  setVetIndex(index);
                  setShowAlert(true);
                }}>
                  <img src={Bin} />
                </div>

                <div onClick={() => {
                  setVetIndex(index);
                  setShowPopover({ showPopover: true, event: undefined });
                }}>
                  <img src={Pen} />
                </div>
              </div>

              <div className="vetData_items">
                <div className="name"><IonIcon icon={person} /> <span>{vet.nombre}{" "}{vet.apellido}</span></div>
                <div><IonIcon icon={mail} /> <span>{vet.email}</span></div>
                <div><IonIcon icon={call} /> <span>{vet.telefono}</span></div>
              </div>
            </div>
          ))
        }
      </div>

      <VetContactForm
        {...vetContactFormProps}
      />

      {/* Delete */}
      <Alert
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        subHeader={"¿Desea eliminar el contacto de este veterinario?"}
        buttonsLabel={{
          primary: "Conservar",
          secondary: "Eliminar"
        }}
        actions={{
          primary: () => setShowAlert(false),
          secondary: () => {
            deleteElement(setSuggestedVets, vetIndex!);
            setShowToast(true);
          }
        }}
        didDismissActions={() => setVetIndex(undefined)}
      />

      <ToastMsg
        isOpen={showToast}
        setIsOpen={setShowToast}
        message={"Contacto eliminado"}
        color={"success"}
      />
    </div>
  )
}

export default VetContactWrapper;