import React, { createContext, useState, useEffect, useRef } from 'react';
import { Storage } from "@ionic/storage";

type StorageContextType = { storage: Storage, ready: boolean };

export const StorageContext: React.Context<StorageContextType> = createContext<StorageContextType>({ storage: new Storage(), ready: false });

export const StorageContextProvider: React.FC = ({ children }) => {
  const initialize = (readyStorage: Storage) => {
    // cada vez que se carga un vetform se recuperan nuevamente los sintomas por si hubo cambios en la bdd
    readyStorage.remove('sintomas');
    readyStorage.remove('searchableSintomas');
  }
  const [ready, setReady] = useState<boolean>(false);
  const storageRef = useRef<Storage>(new Storage());
  useEffect(() => {
    storageRef.current.create().then(() => {
      initialize(storageRef.current);
      setReady(true);
    });
  }, []);

  return (
    <StorageContext.Provider value={{ storage: storageRef.current, ready }}>
      {children}
    </StorageContext.Provider>
  );
}
