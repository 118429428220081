import React, { createContext, useState } from 'react';
type HideHeaderContextType = { hideHeader: boolean, setHideHeader: (hidden: boolean) => any };
export const HideHeaderContext: React.Context<HideHeaderContextType> = createContext<HideHeaderContextType>({ hideHeader: false, setHideHeader: (b) => b });

export const HideHeaderContextProvider: React.FC = ({ children }) => {

  const [hideHeader, setHideHeader] = useState<boolean>(false);

  return (
    <HideHeaderContext.Provider value={{ hideHeader, setHideHeader }}>
      {children}
    </HideHeaderContext.Provider>
  );
}
