import React, { useEffect, useState, useRef, useContext } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { useForm } from "react-hook-form";
import { SintomasFormProps } from "./types";
import './Sintomas.scss';
import Warning from "../../assets/warning.png";
import { Option, setFormValuesFromObject, submitSvf } from "../../utils/Form";
import ProcessDataForSelector from '../../utils/SintomasToDataNodes';
import { SintomasContext } from "../../context/SintomasContext";
import { TempSintoma } from '../../hooks/useSintomas';
import { Sintoma, getEmptySintoma } from "../../interfaces/forms/vetForms/Sintoma";
import Title from "../Title";
import FormSelectOrCreate from "../FormSelectOrCreate";
import NestedSelector from '../NestedSelector/NestedSelector';
import AddingSvfBtns from "../AddingSvfBtns";
import FormInput from "../FormInput";
import FormSelectPopover from "../FormSelectPopover";
import Continue from "../Continue";

const SintomasForm: React.FC<SintomasFormProps> = ({
  sintomas,
  setSintomas,
  setAddingSvfElement,
  index,
  setShowToast,
  goBack,
  goForward
}) => {
  const { searchableSintomas, isLoading } = useContext(SintomasContext);

  const { handleSubmit, control, errors, setValue, watch, register, unregister, getValues } = useForm<Sintoma>();
  const formMethods = { control, errors, setValue };
  const sintoma: string = watch('sintoma');

  const [selectedSintoma, setSelectedSintoma] = useState<TempSintoma | undefined>();
  const [showSubSelector, setShowSubselector] = useState<number | undefined>(undefined);
  const [option, setOption] = useState<Option | null>();
  const dynamicFieldsIds = useRef<string[]>([]);

  const fechasEstimadas = [
    "Desconozco",
    "Muy reciente: Última semana",
    "Reciente: Entre 1 y 2 semanas",
    "Intermedia: Entre 1 y 6 meses",
    "Antigua: Entre 6 meses y 1 año",
    "Muy antigua: más de un año"
  ];

  const onSubmit = (values: Sintoma) => {
    const caracteristicasSeleccionadas = {};
    let descripcion = "";

    dynamicFieldsIds.current.forEach((dfi, i) => {
      caracteristicasSeleccionadas[dfi] = values[dfi];
      descripcion += `${values[dfi]}${i < dynamicFieldsIds.current.length - 1 ? ', ' : ''}`
    })
    values.fechaEstimadaId = String(fechasEstimadas.indexOf(values.fechaEstimada!));
    submitSvf(index, sintomas, setSintomas, { ...values, descripcion, caracteristicasSeleccionadas }, setShowToast)
  };

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(sintomas[index], setValue);
      setOption({ label: sintomas[index].sintoma, value: "" })
    }
  }, [index]);

  //Reset campos si no existe sintoma
  useEffect(() => {
    if (!sintoma) {
      const emptySintoma = getEmptySintoma();
      Object.keys(emptySintoma).forEach(key => {
        setValue(key, emptySintoma[key]);
      });
    }
  }, [sintoma]);

  useEffect(() => {
    /* Si existen clasificaciones registradas en el form, son de un sintoma anterior, desregistrarlas. */
    if (dynamicFieldsIds.current.length > 0) {
      dynamicFieldsIds.current.map(unregister);
    }

    if (sintoma) {
      // @TODO innecesario y lento => cambiar las options que se arman en el FormSelectOrCreate para evitarlo
      const actualSintoma = (searchableSintomas as TempSintoma[]).find((ss) => ss.nombre.toLowerCase() === sintoma.toLowerCase() || ss.label.toLowerCase() === sintoma.toLocaleLowerCase())
      /* Guardado de los nombres de los campos para desregistrarlos del form si cambia el sintoma  */
      dynamicFieldsIds.current = actualSintoma?.clasificaciones.map(c => c.grupo) ?? [];
      setSelectedSintoma(actualSintoma);
    } else {
      setSelectedSintoma(undefined);
      setShowSubselector(undefined);
    }
  }, [sintoma]);

  useEffect(() => {
    if (typeof index === 'number' && sintomas[index].caracteristicasSeleccionadas !== undefined) {
      Object.entries(sintomas[index].caracteristicasSeleccionadas!).map(([k, v]) => { console.log('setting', k, v); setValue(k, v); });
    }
  }, [selectedSintoma]);
  const _getCsValue = key => (typeof index === 'number') ? sintomas[index][key] : undefined;

  return (
    <div className="sintomas">
      {sintoma && typeof showSubSelector === 'number' && selectedSintoma && selectedSintoma.clasificaciones && selectedSintoma.clasificaciones.length &&
        <NestedSelector
          data={ProcessDataForSelector(selectedSintoma, showSubSelector)}
          title={selectedSintoma.clasificaciones[showSubSelector].grupo}
          submitCallback={(val) => {
            // @TODO seria bueno agregar un prefijo a este id
            const element = document.getElementById(selectedSintoma.clasificaciones[showSubSelector].grupo);
            if (element) {
              (element as HTMLInputElement).value = val[val.length - 1].value;
            }
            setValue(selectedSintoma.clasificaciones[showSubSelector].grupo, val[val.length - 1].value);
            setShowSubselector(undefined);
          }}
          cancelCallback={_ => { setShowSubselector(undefined); }}
        />
      }

      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <IonGrid className="ion-no-padding">
          <Title title={index !== undefined ? "Editar síntoma" : "Nuevo síntoma"} />

          <IonRow>
            <IonCol>
              <FormSelectOrCreate
                options={searchableSintomas as Option[]}
                option={option}
                isLoading={isLoading}

                formMethods={formMethods}

                fieldProps={{
                  name: 'sintoma',
                  label: 'Puede buscar por nombre del síntoma',
                  placeholder: "Seleccione un síntoma..."
                }}

                validationProps={{
                  errorMessage: 'Por favor ingrese un síntoma',
                }}
              />
            </IonCol>
          </IonRow>

          <div className="ion-margin-top">
            {selectedSintoma && selectedSintoma.clasificaciones?.length > 0 &&
              !selectedSintoma.clasificaciones
                .map(c => c.grupo)
                .reduce((acc, e) => (typeof getValues(e) === 'string' && (getValues(e) as string).length > 0) && acc, true)
              &&
              <div className="addedSintoma_title">
                <img src={Warning} />
                Por favor complete las caracteristicas del síntoma indicado
              </div>
            }

            {selectedSintoma && selectedSintoma.clasificaciones.map((c, index) =>
              <div key={c.grupo}>
                <input
                  style={{
                    backgroundColor: getValues(c.grupo) ? '#2dd36f' : '#eb445a'
                  }}
                  className="input"
                  id={c.grupo}
                  defaultValue={_getCsValue(c.grupo)}
                  readOnly
                  // @ts-ignore
                  {...register(c.grupo, { required: true })}
                  type="text" onClick={() => setShowSubselector(index)}
                  onFocus={() => setShowSubselector(index)} placeholder={"Completar " + (c.grupo)} name={c.grupo}
                />
              </div>
            )}
          </div>

          <IonRow>
            <IonCol hidden>
              {/* Fecha estimada Id*/}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'fechaEstimadaId', label: '',
                }}
              />
            </IonCol>

            <IonCol>
              {/* Fecha estimada */}
              <FormSelectPopover
                formMethods={formMethods}
                options={fechasEstimadas}
                disabled={!sintoma}
                fieldProps={{
                  name: 'fechaEstimada', label: 'Fecha estimada de origen de síntoma'
                }}
                validationProps={{
                  errorMessage: 'Por favor seleccione una fecha estimada',
                }}
              />
            </IonCol>
          </IonRow>

          <AddingSvfBtns
            label={index !== undefined ? "cambios" : "síntomas"}
            action={() => {
              setOption(null);
              !sintomas.length ? goBack() : setAddingSvfElement(false);
            }}
            disableSubmitBtn={!sintomas.length && !sintoma}
          />

          <div className="ion-margin-top">
            {sintomas.length === 0 && <Continue modifyStep={goForward} />}
          </div>
        </IonGrid>
      </form>
    </div>
  );
};

export default SintomasForm;
