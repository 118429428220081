import Logo from "../../assets/FielPetLogo.png";
import { useGanadores } from "../../hooks/useGanadores";
import "./SorteoPage.scss";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
} from "@ionic/react";
import { person } from "ionicons/icons";
import React, { useState } from "react";

const SorteoPage: React.FC = () => {
  const [mostrarObjetos, setMostrarObjetos] = useState<boolean>(false);
  const { ganadores } = useGanadores();
  const mostrarObjetosHandler = () => {
    setMostrarObjetos(true);
  };

  const copiarAlPortapapeles = () => {
    const texto = ganadores.map((objeto) => `${objeto.nombre}`).join("\n");
    navigator.clipboard.writeText(texto);
    alert("Datos copiados al portapapeles");
  };

  const descargarComoCSV = () => {
    const csv = ganadores
      .map(
        (objeto) =>
          `${objeto.id},${objeto.nombre},${objeto.email},${objeto.telefono},${objeto.identificador}`
      )
      .join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "datos.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <IonPage className="sorteo-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent className="ion-padding">
        <IonButton onClick={mostrarObjetosHandler} mode="ios" expand="block">
          Mostrar Participantes
        </IonButton>
        <IonButton onClick={copiarAlPortapapeles} mode="ios" expand="block">
          Copiar al Portapapeles
        </IonButton>
        <IonButton onClick={descargarComoCSV} mode="ios" expand="block">
          Descargar como CSV
        </IonButton>

        {mostrarObjetos && (
          <div className="participantes">
            <p>
              <u>Participantes:</u>
            </p>
            {ganadores.map((objeto) => (
              <p key={objeto.id}>
                <IonIcon icon={person} color="primary" />
                {objeto.nombre}
              </p>
            ))}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SorteoPage;
