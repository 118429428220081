import React from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { StepperProps } from "./types";
import "./Stepper.scss";
import { findStepIndexByValue, stepsData } from "../../utils/Form";

const Stepper: React.FC<StepperProps> = ({ formStep }) => {

  const prevStep = stepsData[formStep - 1]?.label;
  const currentStep = stepsData[formStep]?.label;
  const nextStep = stepsData[formStep + 1]?.label;

  //Indíce del primer elemento que posea la propiedad label
  const index = stepsData.findIndex(step => step && step.label !== undefined);

  return (
    <div className="stepper">
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol>
            <div hidden={formStep <= findStepIndexByValue("sintomas")!}>
              {formStep - index}
            </div>
            <span hidden={formStep <= findStepIndexByValue("sintomas")!}>{prevStep}</span>
          </IonCol>
          <IonCol className="current">
            <div>
              {formStep - (index - 1)}
            </div>
            <span>{currentStep}</span>
          </IonCol>
          <IonCol>
            <div>
              {formStep - (index - 2)}
            </div>
            <span>{nextStep}</span>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Stepper;
