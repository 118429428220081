import React from 'react';
import {
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import {
  ValidationMail,
  validationAlphanumeric,
  validationName,
  validationPhone,
} from '../../assets/validations/validationRegex';
import {
  Veterinario,
  getEmptyVeterinario,
} from '../../interfaces/Veterinario';
import { VeterinarioFormProps } from './types';
import { capitalizeEveryWord } from '../../utils/General';
import { colegios } from '../../utils/Colegios';
import Title from '../Title';
import FormInput from '../FormInput';
import FormSelectPopover from '../FormSelectPopover';
import Continue from '../Continue';

const VeterinarioForm: React.FC<VeterinarioFormProps> = ({
  veterinario,
  setVeterinario,
  goForward,
}) => {

  const { handleSubmit, control, errors } = useForm<Veterinario>({
    defaultValues: veterinario ?? getEmptyVeterinario(),
  });

  const formMethods = { control, errors }

  const onSubmit = (values: Veterinario) => {
    setVeterinario(values);
    goForward();
  };

  return (
    <div className="ion-margin">
      <form onSubmit={handleSubmit(onSubmit)}
      >
        <IonGrid className='ion-no-padding'>

          <Title title="Datos del veterinario" />

          <IonRow>
            <IonCol className='ion-padding-end'>
              {/* Nombre */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{ name: 'nombre', label: 'Nombre', format: capitalizeEveryWord }}
                validationProps={{
                  errorMessage: 'Por favor ingrese su nombre',
                  pattern: validationName,
                }}
              />
            </IonCol>

            <IonCol>
              {/* Apellido */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'apellido', label: 'Apellido', format: capitalizeEveryWord,
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese su apellido',
                  pattern: validationName,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className='ion-padding-end'>
              {/* Colegio */}
              <FormSelectPopover
                formMethods={formMethods}
                options={colegios}
                fieldProps={{
                  name: 'colegio', label: 'Colegio'
                }}
                validationProps={{
                  errorMessage: 'Por favor seleccione su colegio',
                }}
              />
            </IonCol>

            <IonCol>
              {/* Matrícula */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'matricula', label: 'Matrícula'
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese su matrícula',
                  pattern: validationAlphanumeric,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className='ion-padding-end'>
              {/* Email */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'email', label: 'Email'
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese su email',
                  pattern: ValidationMail,
                }}
              />
            </IonCol>

            <IonCol>
              {/* Teléfono */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'telefono', label: 'Teléfono', typeNumber: true
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese su teléfono',
                  pattern: validationPhone,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='6'>
              {/* Domicilio de la clínica */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'domicilio', label: 'Domicilio de la clínica', placeholder: "Opcional", format: capitalizeEveryWord
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className='ion-margin-top'>
          <Continue />
        </div>
      </form>
    </div>
  );
};

export default VeterinarioForm;

