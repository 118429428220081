import Checked from "../../assets/checked.png";
import {
  findStepIndexByValue,
  getEmptyFieldProps,
  stepsData,
} from "../../utils/Form";
import Continue from "../Continue";
import RegularOrIndicacion from "../RegularOrIndicacion";
import SvfElement from "../SvfElement";
import "./SubVetForm.scss";
import { SubVetFormProps } from "./types";
import { IonButton, IonPopover } from "@ionic/react";
import React, { useEffect, useState } from "react";

const SubVetForm: React.FC<SubVetFormProps> = ({
  state,
  setState,
  form,
  formStep,
  prevSvf,
  setPrevSvf,
  setIndex,
  setShowPopover,
  confirmedSvf,
  setConfirmSvf,
  addingSvfElement,
  setAddingSvfElement,
  editingFromResumen,
  showSvfOptions,
  setShowSvfOptions,
  setCurrentSvfHasLength,
  setShowToast1: setShowToast,
  goForward,
}) => {
  //Indicaciones.length alert
  const [popoverState1, setShowPopover1] = useState({
    showPopover: false,
    event: undefined,
  });

  const fieldProps = stepsData[formStep]?.fieldProps || getEmptyFieldProps();

  const { Svf_title, newLabel, cardTitleProp, cardSubtitleProp, deleteLabel } =
    fieldProps;

  const regularOrIndicacionProps = {
    addingSvfElement,
    setAddingSvfElement,
    setPrevSvf,
    setShowSvfOptions,
    goForward,
  };

  const hideSvfOptions = stepsData[formStep].hideOptions

  useEffect(() => {
    setCurrentSvfHasLength(state.length > 0);
  }, [state]);

  //Handler popover de confirmación para c/u SVF
  useEffect(() => {
    if (!state.length && !prevSvf) {
      setShowPopover({ showPopover: true, event: undefined });
    } else {
      setConfirmSvf(true);
      formStep !== findStepIndexByValue("indicaciones") && setPrevSvf(undefined);
    }

    return () => setConfirmSvf(false);
  }, []);

  //Handler popover indicaciones cargadas
  useEffect(() => {
    if (
      formStep === findStepIndexByValue("indicaciones") &&
      state.length !== 0 &&
      !addingSvfElement &&
      !editingFromResumen
    ) {
      const timeout = setTimeout(() => {
        setShowPopover1({ showPopover: true, event: undefined });
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [formStep]);

  return (
    <div className="sub-vet-form ion-margin">
      {confirmedSvf && (
        <>
          <div
            className="svf__title ion-margin-bottom"
            hidden={addingSvfElement || (hideSvfOptions && !state.length)}
          >
            {Svf_title}
          </div>

          {!addingSvfElement && (
            <>
              {/* hideSvfOptions hace referencia a aquellos SVF cuyos elementos no pueden ser añadidos como una indicación */}
              {!hideSvfOptions && (state.length === 0 || showSvfOptions) ? (
                <RegularOrIndicacion
                  regularLabel={stepsData[formStep]?.regularLabel}
                  indicacionLabel={stepsData[formStep]?.indicacionLabel}
                  currentSVF={stepsData[formStep].value}
                  {...regularOrIndicacionProps}
                />
              ) : (
                <>
                  {/* Show, edit and delete */}
                  {state.map((item, currentIndex) => (
                    <div key={currentIndex}>
                      <SvfElement
                        index={currentIndex}
                        setIndex={setIndex}
                        state={state}
                        setter={setState}
                        title={item[cardTitleProp]}
                        setAddingSvfElement={setAddingSvfElement}
                        subtitle={item[cardSubtitleProp ?? ""]}
                        deleteLabel={deleteLabel}
                        setShowToast={setShowToast}
                      />
                    </div>
                  ))}

                  <div
                    hidden={addingSvfElement || !state.length}
                    className="ion-margin-top"
                  >
                    <IonButton
                      mode="ios"
                      expand="block"
                      fill="outline"
                      color="dark"
                      onClick={() =>
                        hideSvfOptions
                          ? setAddingSvfElement(true)
                          : setShowSvfOptions(true)
                      }
                    >
                      {newLabel}
                    </IonButton>

                    <Continue modifyStep={goForward} />
                  </div>
                </>
              )}
            </>
          )}

          {(addingSvfElement || (hideSvfOptions && !state.length)) && (
            <div className="animate__animated animate__fadeInRight">{form}</div>
          )}

          {/* Indicaciones cargadas  */}
          <IonPopover
            mode="ios"
            cssClass="popover"
            event={popoverState1.event}
            isOpen={popoverState1.showPopover}
            onDidDismiss={() =>
              setShowPopover1({ showPopover: false, event: undefined })
            }
          >
            <img src={Checked} alt="checked" />
            <div className="subtitle">
              Usted ya ha cargado indicaciones para que el paciente realice en
              el futuro. Si desea agregar otra, seleccione el botón "Nueva
              indicación", si no, seleccione "Siguiente paso".
            </div>
          </IonPopover>
        </>
      )}
    </div>
  );
};

export default SubVetForm;
