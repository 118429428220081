import React from 'react';
import { useSiniestro } from '../../context/SiniestroContext';
import { SintomaMascota as SintomaMascotaInferface } from '../../interfaces/SintomaMascota';
import { HistorialSintomasProps } from './types';
import SintomaMascota from '../SintomaMascota';
import Title from '../Title';
import Continue from '../Continue';

const HistorialSintomas: React.FC<HistorialSintomasProps> = ({
  goForward,
}) => {
  const { data: sintomas } = useSiniestro().sintomas;

  return (
    <div className="historial-sintomas ion-margin">
      <Title title={"Historial de síntomas"} />
      {sintomas &&
        sintomas.map((sintoma: SintomaMascotaInferface, index: number) => (
          <SintomaMascota key={index} sintoma={sintoma} />
        ))}
      <Continue modifyStep={goForward} />
    </div>
  );
};

export default HistorialSintomas;
