export interface ValidationObject {
  value: RegExp;
  message: string;
}

export const validationData: ValidationObject = {
  value: /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ\s,.()\/-]{0,255}$/,
  message:
    'Este campo solo acepta letras, números y algunos caracteres especiales (como la coma, el punto, los paréntesis y la barra "/")No sobrepasar los 255 caracteres por favor.',
};

export const validationName: ValidationObject = {
  value: /^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]+$/,
  message: 'Solo se permiten letras y espacios.',
};

export const validationPhone: ValidationObject = {
  value: /^(\+?\d{1,3}\s?|549\s?)?(\d{3}[\s.-]?\d{7}|\d{10})$/,
  message: 'Ingrese un número de teléfono válido sin guiones o espacios (ej. 3414567890 o +5493414567890).',
};

export const ValidationMail: ValidationObject = {
  value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
  message: 'Por favor, ingrese un correo electrónico válido.',
};

export const validationNumber: ValidationObject = {
  value: /^[0-9]*$/,
  message: 'Solo se permiten números.',
};

export const validationMoney: ValidationObject = {
  value: /^[0-9\,\$/]+$/,
  message: 'Ingrese un monto válido.',
};

export const validationAlphanumeric: ValidationObject = {
  value: /^[A-Za-z0-9-]+$/,
  message: 'Solo se permiten letras, números y guiones.',
};
