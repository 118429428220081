import React, { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { EstudiosFormProps } from './types';
import { Estudio } from '../../interfaces/forms/vetForms/Estudio';
import { validationData, validationMoney } from '../../assets/validations/validationRegex';
import { estudioOptions, setFormValuesFromObject, submitSvf } from '../../utils/Form';
import { capitalizeFirstLetter } from '../../utils/General';
import Title from '../Title';
import FormInput from '../FormInput';
import FormSelectPopover from '../FormSelectPopover';
import AddingSvfBtns from '../AddingSvfBtns';
import { useEstudios} from '../../hooks/useEstudios'

const EstudiosForm: React.FC<EstudiosFormProps> = ({
  estudios,
  setEstudios,
  index,
  setAddingSvfElement,
  setShowToast
}) => {
  const { handleSubmit, control, errors, setValue } = useForm<Estudio>();
  const formMethods = { control, errors };

  const { allEstudios, isLoading } = useEstudios();

  const onSubmit = (values: Estudio) => {
    values.estudioId = String(estudioOptions.indexOf(values.estudio!)+1);
    submitSvf(index, estudios, setEstudios, values, setShowToast)
  };
  // useEffect(()=>{
  //   setEstudiosOptions(allEstudios)
  // },[isLoading])

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(estudios[index], setValue);
    }
  }, [index]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <IonGrid className='ion-no-padding'>

        <Title title={index !== undefined ? "Editar estudio" : "Nuevo estudio"} />

        <div hidden>
          {/* Estudio Id*/}
          <FormInput
            formMethods={formMethods}
            fieldProps={{
              name: 'estudioId', label: '',
            }}
          />
        </div>

        <IonRow>
          <IonCol>
            {/* Estudio */}
            <FormSelectPopover
              formMethods={formMethods}
              options={estudioOptions}
              fieldProps={{
                name: 'estudio', label: 'Estudio realizado'
              }}
              validationProps={{
                errorMessage: 'Por favor seleccione un estudio',
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {/* Descripción */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: 'descripcion', label: 'Descripción del estudio',
                format: capitalizeFirstLetter,
              }}
              validationProps={{
                errorMessage: 'Por favor ingrese una descripción',
                pattern: validationData,
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {/* Costo */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: 'costo', label: 'Costo del estudio',
                typeNumber: true
              }}
              validationProps={{
                errorMessage: 'Por favor ingrese un monto',
                pattern: validationMoney,
              }}
            />
          </IonCol>
        </IonRow>

        <AddingSvfBtns
          label={index !== undefined ? "cambios" : "estudio"}
          action={() => setAddingSvfElement(false)}
        />
      </IonGrid>
    </form>
  );
};

export default EstudiosForm;
