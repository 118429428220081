import { validationData } from "../../assets/validations/validationRegex";
import { MedicamentoContext } from "../../context/MedicamentosContext";
import {
  Medicamento,
  getEmptyMedicamento,
} from "../../interfaces/forms/Medicamento";
import { Option, setFormValuesFromObject } from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import FormInput from "../FormInput";
import FormSelectOrCreate from "../FormSelectOrCreate";
import MedicamentoCard from "../MedicamentoCard";
import "./IndicacionMedForm.scss";
import { IndicacionMedFormProps } from "./types";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

const IndicacionMedForm: React.FC<IndicacionMedFormProps> = ({
  indicaciones,
  index,
  formMethods,
}) => {
  const { searchableMedicamentos, medicamentos } =
    useContext(MedicamentoContext);
  const { setValue, watch } = formMethods;
  const nombre_comercial: string = watch("medicamento");

  const [option, setOption] = useState<Option | null>();
  const [medicamento, setMedicamento] =
    useState<Medicamento>(getEmptyMedicamento);

  const medicamentoSetter = () => {
    const found = medicamentos.find(
      (e) => e.nombre_comercial === nombre_comercial
    );

    setMedicamento(
      () =>
        found ?? {
          //Medicamento creado por el veterinario
          ...getEmptyMedicamento(),
          nombre_comercial: nombre_comercial,
        }
    );

    setValue("id", found?.id ?? "0");
  };

  useEffect(() => {
    medicamentoSetter();
  }, [nombre_comercial]);

  //Reset si no existe medicamentoField
  useEffect(() => {
    if (!nombre_comercial) {
      setValue("dosaje", undefined);
      setValue("frecuencia", undefined);
      setValue("duracion", undefined);
    }
  }, [nombre_comercial]);

  useEffect(() => {
    if (index !== undefined) {
      setOption({ label: indicaciones[index].medicamento!, value: "" });
      setFormValuesFromObject(indicaciones[index], setValue);
    }
  }, [index]);

  return (
    <div className="indicacion-med-form">
      <IonGrid className="ion-no-padding">
        <IonRow>
          <IonCol hidden>
            {/* Id medicamento*/}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "id",
                label: "",
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            <FormSelectOrCreate
              creatable={true}
              options={searchableMedicamentos}
              option={option}
              formMethods={formMethods}
              fieldProps={{
                name: "medicamento",
                label:
                  "Puede buscar por droga, nombre comercial o tipo de acción:",
                placeholder: "Seleccione una droga...",
              }}
              validationProps={{
                errorMessage: "Por favor indique una droga",
              }}
            />
          </IonCol>
        </IonRow>

        <div className="ion-padding-top" hidden={!nombre_comercial}>
          <IonRow>
            <IonCol>
              {/* Dosaje */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "dosaje",
                  label: "Dosaje del medicamento",
                  placeholder: "Ejemplo: 1 comprimido",
                  format: capitalizeFirstLetter,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Frecuencia */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "frecuencia",
                  label: "Frecuencia del tratamiento",
                  placeholder: "Ejemplo: 12 horas",
                  format: capitalizeFirstLetter,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Duración */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "duracion",
                  label: "Duración del tratamiento",
                  placeholder: "Ejemplo: 5 días",
                  format: capitalizeFirstLetter,
                }}
              />
            </IonCol>
          </IonRow>
        </div>

        <MedicamentoCard medicamento={medicamento} />
      </IonGrid>
    </div>
  );
};

export default IndicacionMedForm;
