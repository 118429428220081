import React from "react";
import { IonContent, IonHeader, IonPage } from "@ionic/react";
import { SintomasContextProvider } from "../../context/SintomasContext";
import "./TigrePage.scss";
import Logo from '../../assets/FielPetLogo.png';
import SintomaNorma from "../../components/SintomaNorma";

const TigrePage: React.FC = () => {
  return (
    <IonPage className="tigre-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent>
        <SintomasContextProvider>
          <SintomaNorma />
        </SintomasContextProvider>
      </IonContent>
    </IonPage>
  );
};

export default TigrePage;
