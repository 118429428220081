import React from "react";
import { SpinnerProps } from './types';
import './Spinner.scss';
import { IonSpinner } from "@ionic/react";

const Spinner: React.FC<SpinnerProps> = ({ color }) => {
  return (
    <div
      className="spinner"
    >
      <IonSpinner name="bubbles" color={color ? color : "dark"} />
    </div>
  )
}

export default Spinner;