import { AxiosResponse } from 'axios';

import { SintomaMascota } from '../interfaces/SintomaMascota';
import axios from './';

export const getSintomasMascota = async (url: string, siniestroId: string) => {
  try {
    const { data: a }: AxiosResponse<SintomaMascota[]> = await axios.get(url, {
      params: {
        siniestroId,
      },
    });
    return a;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
