import { useState, useEffect } from "react";
import { getEstudios as axiosGetEstudios } from "../axios/getEstudios";
import { Estudios } from "../interfaces/Estudios";

export const useEstudios = () => {
  const [estudios, setEstudios] = useState<Estudios[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getEstudios = async () => {
    setIsLoading(true);
    
    const estudios = await axiosGetEstudios();
    setEstudios(estudios);

    setIsLoading(false);
  };

  // IonSelect necesita array de strings
  const formattedEstudios = estudios.map((e) => e.tipo_estudio);

  useEffect(() => {
    getEstudios();
  }, []);

  return { formattedEstudios, isLoading };
};
