import React from "react";
import { IonButton, IonSpinner } from "@ionic/react";
import moment from "moment";
import { ResumenProps } from "./types";
import "./Resumen.scss";

import { removeProperty } from "../../utils/Form";
import Title from "../Title";
import Svfs_resumen from "../Svfs_resumen";

const Resumen: React.FC<ResumenProps> = ({
  submit,
  isSubmiting,
  datosUsuario,
  veterinario,
  sintomas,
  medicamentos,
  practicas,
  estudios,
  cirugias,
  internaciones,
  indicaciones,
  diagnostico,
  setFormStep,
  setEditFromResumen,
  setIndex
}) => {
  const { nombre: nombreVet, apellido: apellidoVet, email: emailVet } = veterinario;
  const { persona, mascota, denuncia_administrativa: denuncia } = datosUsuario;
  const { nombre: nombrePersona, apellido: apellidoPersona } = persona;
  const { nombre: nombreMascota, animal, raza, sexo, fecha_nacimiento } = mascota;
  const { diagnostico: diagnosticoData } = diagnostico;

  const SVFs = {
    sintomas,
    medicamentos: removeProperty(medicamentos, 'id'),
    practicas: removeProperty(practicas, 'practicaId'),
    estudios: removeProperty(estudios, 'estudioId'),
    cirugias,
    internaciones,
    indicaciones
  }

  const editFromSvfsResumenProps = {
    setFormStep,
    setIndex,
    setEditFromResumen
  }

  return (
    <div className="resumen ion-margin">

      <Title title="Resumen" />

      <div className="container">
        <div className="card">
          <div className="title">Veterinario</div>
          <div>{nombreVet} {apellidoVet}</div>
          <div>{emailVet}</div>
        </div>

        <div className="card">
          <div className="title">Datos paciente:{" "}<span className="petName">{nombreMascota}</span></div>
          <div>{animal === "Perro" ? "Canino" : "Felino"}, {raza}, {sexo === "h" || "H" ? "Macho" : "Hembra"}</div>
          <div>Nacimiento: {moment(fecha_nacimiento).format('DD/MM/YYYY')}</div>
          <div>Tutor: {nombrePersona} {apellidoPersona}</div>
        </div>

        <div className="card">
          <div className="title">Datos de la consulta</div>
          <div>Motivo: {denuncia}</div>
        </div>

        <Svfs_resumen SVFs={SVFs} {...editFromSvfsResumenProps} />

        <div className="card">
          <div className="title">Diagnóstico presuntivo</div>
          <div>{diagnosticoData}</div>
        </div>

        <IonButton
          disabled={isSubmiting}
          mode="ios"
          expand="block"
          onClick={submit}
        >
          {!isSubmiting ? "Enviar formulario" : <IonSpinner name="bubbles" color="light" />}
        </IonButton>
      </div>
    </div>
  );
};

export default Resumen;
