export type Medicamento = {
  id?: string;
  nombre_comercial: string;
  drogas: string;
  laboratorio: string;
  tipo_accion: string;
  presentacion: string;
  unidad_dosis: string;
  unidad_tiempo: string;
  via: string;
  permitido_consulta: boolean;
};

export const emptyMedicamento: Medicamento = {
  id: undefined,
  nombre_comercial: "",
  drogas: "",
  laboratorio: "",
  tipo_accion: "",
  presentacion: "",
  unidad_dosis: "",
  unidad_tiempo: "",
  via: "",
  permitido_consulta: false,
};

export const getEmptyMedicamentos: Medicamento[] = [];

export function getEmptyMedicamento(): Medicamento {
  return JSON.parse(JSON.stringify(emptyMedicamento));
}