import axiosInstance from ".";
import { SORTEO } from "./constants";
import { AxiosResponse } from "axios";

type queViene = {
  apellido: string | null;
  colegio: string | null;
  createdAt: string | null;
  domicilio: string | null;
  especialidad: string | null;
  horario_contacto: string | null;
  id: number;
  mail: string | null;
  matricula: string | null;
  nombre: string | null;
  telefono: string | null;
  tipo: string | null;
  updatedAt: string | null;
};

export const getGanadores = async () => {
  try {
    const { data: vetes }: AxiosResponse<queViene[]> = await axiosInstance.get(
      SORTEO
    );
    const datosSorteo = vetes.map((v, index) => ({
      id: index,
      nombre: v.nombre + " " + v.apellido,
      email: v.mail,
      telefono: v.telefono,
      identificador: v.matricula,
    }));
    return datosSorteo;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
