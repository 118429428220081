export type Diagnostico = {
  diagnostico: string;
  fechaEstimada?: string;
  fechaEstimadaId: string;
  congenito?: boolean;
  tratamientoPorVida?: boolean;
  enfermedadBase?: boolean;
  motivo?: string;
};

export const getEmptyDiagnostico = (): Diagnostico => ({
  diagnostico: "",
  fechaEstimada: "",
  fechaEstimadaId: "",
  congenito: false,
  tratamientoPorVida: false,
  enfermedadBase: false,
  motivo: undefined,
});
