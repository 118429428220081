import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow
} from "@ionic/react";

import { capitalizeFirstLetter } from "../../utils/General";
import { today } from "../../utils/DateUtilities";
import { getErrors } from "../../utils/Form";
import { validationMoney } from "../../assets/validations/validationRegex";

import { Consulta as ConsultaInterface } from "../../interfaces/forms/vetForms/Consulta";
import "./Consulta.scss";
import { ConsultaProps } from "./types";

import Title from "../Title";
import FormInput from "../FormInput";
import FormDate from "../FormDate";
import Continue from "../Continue";

const Consulta: React.FC<ConsultaProps> = ({
  consulta,
  setConsulta,
  goForward,
}) => {

  const { handleSubmit, control, errors } = useForm<ConsultaInterface>({
    defaultValues: consulta,
  });

  const formMethods = { control, errors }

  const localizacion = [
    { value: "consultorio", label: "En el consultorio" },
    { value: "domicilio", label: "A domicilio" },
    { value: "virtual", label: "Virtual (Telefónica / WhatsApp)" },
  ]

  const onSubmit = (values: ConsultaInterface) => {
    setConsulta(values);
    goForward();
  };

  return (
    <div className="consulta">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="ion-margin"
      >
        <IonGrid className="ion-no-padding">
          <Title title="Datos de la consulta" />

          <IonRow>
            <IonCol>
              {/* Motivo consulta*/}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'motivoConsulta', label: 'Motivo de la consulta / anamnesis',
                  placeholder: "Opcional", format: capitalizeFirstLetter
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Fecha Inicio */}
              <FormDate
                formMethods={formMethods}
                fieldProps={{
                  name: 'fechaInicio', label: 'Fecha de la atención',
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese una fecha',
                }}
                date={{ max: today }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Costo */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'costo', label: 'Costo total de la consulta',
                  typeNumber: true
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese un monto',
                  pattern: validationMoney,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Localización de la consulta */}
              <Controller
                name="localizacion"
                control={control}
                errors={errors}
                rules={{
                  required: {
                    value: true,
                    message: "Por favor, seleccione una opción",
                  },
                }}
                render={({ onChange, name, value }) => (
                  <>
                    <IonItem lines="none" className="ion-no-padding">
                      <IonRadioGroup
                        onIonChange={onChange}
                        name={name}
                        value={value}
                      >
                        <>
                          <IonLabel
                            className="consulta__checkbox-label ion-margin-top"
                          >
                            Lugar donde se realizo la consulta
                          </IonLabel>

                          {localizacion.map((e, index) => (
                            <IonItem key={index} lines="none" className="ion-no-padding">
                              <IonRadio value={e.value} mode="md" />
                              <IonLabel
                                color="medium"
                                className="ion-margin-start"
                              >
                                {e.label}
                              </IonLabel>
                            </IonItem>
                          )
                          )}
                        </>
                      </IonRadioGroup>
                    </IonItem>

                    <div
                      className="consulta__error-message"
                      style={getErrors(errors, name) ? { opacity: 1 } : undefined}
                    >
                      {getErrors(errors, name)?.message}
                    </div>
                  </>
                )}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className='ion-margin-top'>
          <Continue />
        </div>
      </form>
    </div>
  );
};

export default Consulta;
