import { getVetForm } from "../../axios/getVetForm";
import HistorialSintomas from "../../components/HistorialSintomas";
import { useMedia } from "../../context/MediaContext";
import { MedicamentoContextProvider } from "../../context/MedicamentosContext";
import { SintomasContextProvider } from "../../context/SintomasContext";
import { useSubVetForm } from "../../context/SubVetFormContext";
import { useEstudios } from "../../hooks/useEstudios";
import { usePracticas } from "../../hooks/usePracticas";
import { useVetForm } from "../../hooks/useVetForm";
import { useVeterinario } from "../../hooks/useVeterinarios";
import {
  findStepIndexByValue,
  indicacionesGoBack,
  resumenGoBack,
  stepsData,
} from "../../utils/Form";
import CirugiasForm from "../CirugiasForm";
import ConfirmStep from "../ConfirmStep";
import Consulta from "../Consulta";
import DatosUsuario from "../DatosUsuario";
import DiagnosticoForm from "../DiagnosticoForm";
import EstudiosForm from "../EstudiosForm";
import Gallery from "../Gallery";
import Header from "../Header";
import IndicacionesForm from "../IndicacionesForm";
import InternacionesForm from "../InternacionesForm";
import MedicamentosForm from "../MedicamentosForm";
import PracticasForm from "../PracticasForm";
import Resumen from "../Resumen";
import SintomasForm from "../SintomasForm";
import SubVetForm from "../SubVetForm";
import ToastMsg from "../ToastMsg";
import TutorialBasic from "../TutorialBasic";
import Veterinario from "../Veterinario";
import "./../../pages/vet-form";
import "./VetForm.scss";
import { VetFormProps } from "./types";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

const VetForm: React.FC<VetFormProps> = ({ vetFormLink }) => {
  const {
    datosUsuario,
    veterinario,
    setVeterinario,
    consulta,
    sintomas,
    setSintomas,
    medicamentos,
    setMedicamentos,
    practicas,
    setPracticas,
    estudios,
    setEstudios,
    cirugias,
    setCirugias,
    internaciones,
    setInternaciones,
    indicaciones,
    setIndicaciones,
    diagnostico,
  } = useSubVetForm();

  const history = useIonRouter();
  const reactHistory = useHistory();
  const { setCurrentMedia, clearPhotos, clearPDFs } = useMedia();
  const { getVeterinarioByVetform } = useVeterinario();
  const { addVetForm } = useVetForm();
  const { formattedPracticas } = usePracticas();
  const { formattedEstudios } = useEstudios();
  const [formStep, setFormStep] = useState<number>(0);
  const [prevSvf, setPrevSvf] = useState<string | undefined>();
  const [index, setIndex] = useState<number>();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  // Confirmacion de entrada a SVF
  const [confirmedSvf, setConfirmSvf] = useState<boolean>(false);
  // Popover para confirmar ingreso a SVF
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  // Cuando se esta agregando un nuevo elemento a un SVF especifico
  const [addingSvfElement, setAddingSvfElement] = useState<boolean>(false);
  // Opciones para agregar un elemento a SVF o añadirlo como indicación
  const [showSvfOptions, setShowSvfOptions] = useState<boolean>(false);
  // Editar elemento en SVF desde resumen pre submit
  const [editingFromResumen, setEditFromResumen] = useState<boolean>(false);
  // Indica si el SVF que se esta mostrando tiene elementos
  const [currentSvfHasLength, setCurrentSvfHasLength] =
    useState<boolean>(false);

  // Elemento ya existente dentro de un SVF especifico
  const [showToast, setShowToast] = useState<boolean>(false);
  // Elemento eliminado exitosamente de un SVF
  const [showToast1, setShowToast1] = useState<boolean>(false);
  // Indicacion agregada exitosamente desde otro SVF
  const [showToast2, setShowToast2] = useState<boolean>(false);
  // Submit error
  const [showToast3, setShowToast3] = useState<boolean>(false);

  // Encontrar index en stepDataArray para ser usado como FormStep
  const find_FS = (value: string) => {
    return findStepIndexByValue(value);
  };

  // Modificar formStep
  const modifyStep = async (move: number) => {
    setFormStep(Math.max(0, formStep + move));
  };

  // Header goBack switch
  const headerGoBack = () => {
    //Caso para retornar cuando se esta añadiendo elemento como indicación
    if (prevSvf !== undefined) {
      indicacionesGoBack(prevSvf, setFormStep, setAddingSvfElement);
    } else if (showSvfOptions && currentSvfHasLength) {
      setShowSvfOptions(false);
    } else if (addingSvfElement && currentSvfHasLength) {
      setShowSvfOptions(true);
      setAddingSvfElement(false);
    } else if (addingSvfElement) {
      setAddingSvfElement(false);
    } else if (formStep === find_FS("veterinario")) {
      modifyStep(sintomas.length === 0 ? -2 : -1);
    } else if (!addingSvfElement) {
      modifyStep(-1);
    }
  };

  const moveProps = {
    goBack: () => modifyStep(-1),
    goForward: () => modifyStep(1),
  };

  // Propiedades compartidas por SVFs
  const sharedProps = {
    svf: {
      index,
      setAddingSvfElement,
      setPrevSvf,
      showToast,
      setShowToast,
    },

    wrapper: {
      formStep,
      prevSvf,
      setPrevSvf,
      setIndex,
      setShowPopover,
      confirmedSvf,
      setConfirmSvf,
      addingSvfElement,
      setAddingSvfElement,
      editingFromResumen,
      showSvfOptions,
      setShowSvfOptions,
      setCurrentSvfHasLength,
      setShowToast1,
      goForward: () => modifyStep(1),
    },
  };

  const formSelectPopoverOptionsProps = {
    formattedPracticas,
    formattedEstudios,
  };

  const editFromResumenProps = {
    setFormStep,
    setIndex,
    setEditFromResumen,
  };

  // Submit exitoso es seguido de una redirección
  const okSubmitted = () => {
    const vetName = veterinario
      ? `${veterinario.nombre}_${veterinario.apellido}`
      : undefined;
    const ownerName = datosUsuario.persona
      ? `${datosUsuario.persona.nombre}_${datosUsuario.persona.apellido}`
      : undefined;
    const formattedVetName = vetName ? `vet=${vetName}` : "";
    const formattedOwner = ownerName ? `owner=${ownerName}` : "";
    const queryParams = [formattedVetName, formattedOwner]
      .filter(Boolean)
      .join("&");
    const redirectURL = `/confirm/?${queryParams}`;
    reactHistory.replace(redirectURL);
  };

  const submit = async () => {
    setIsSubmiting(true);
    const payload = {
      vetFormId: vetFormLink.vetForm,
      veterinario,
      consulta,
      sintomas,
      medicamentos,
      estudios,
      practicas,
      cirugias,
      internaciones,
      indicaciones,
      diagnostico,
    };

    const ok = await addVetForm(payload);

    if (ok) {
      setIsSubmiting(false);
      okSubmitted();
      clearPhotos();
      clearPDFs();
    } else {
      setIsSubmiting(false);
      setShowToast3(true);
    }
  };

  // Resumen de VetForm y posterior submit
  const submitProps = {
    submit,
    isSubmiting,
    datosUsuario,
    veterinario,
    sintomas,
    medicamentos,
    practicas,
    estudios,
    cirugias,
    internaciones,
    indicaciones,
    diagnostico,
  };

  // Determina si VetForm ya fue completado con anterioridad
  const getVetFormData = async (vetFormId: string) => {
    try {
      const vetForm = await getVetForm(vetFormId);

      if (vetForm.estado === "COMPLETADO") {
        const { id: vetFormId, siniestroId } = vetForm;
        history.push(`/error/?data=${vetFormId}-${siniestroId}`);
      }
    } catch (error) {
      console.error(
        "Error al obtener datos del formulario veterinario: ",
        error
      );
    }
  };

  // Establece el veterinario asignado
  const handleVetForm = async () => {
    try {
      if (vetFormLink.vetForm !== "") {
        const veterinario = await getVeterinarioByVetform(vetFormLink.vetForm);

        if (veterinario?.tipo === "VetContact") {
          veterinario.nombre = "";
          veterinario.telefono = "";
        }

        veterinario && setVeterinario(veterinario);
      }
    } catch (error) {
      console.error("Error al establecer el veterinario asignado: ", error);
    }
  };

  const dependenciesArray = [
    sintomas,
    medicamentos,
    practicas,
    estudios,
    cirugias,
    internaciones,
    indicaciones,
  ];

  // Cuando se escribe un SVF se cierra la vista/(state) de agregando nuevo elemento y se anula la vista de opciones
  useEffect(() => {
    setAddingSvfElement(false);
    setShowSvfOptions(false);
    // Cuando se escribe un state desde el resumen
    editingFromResumen && resumenGoBack(setEditFromResumen, setFormStep);
  }, dependenciesArray);

  // Permite lectura de SVF si se continua al siguiente paso mostrando opciones y se retorna
  useEffect(() => {
    setShowSvfOptions(false);
  }, [formStep]);

  // Si no se esta escribiendo un SVF el index para editar un elemento debe ser undefined asi como el array de currentMedia
  useEffect(() => {
    if (!addingSvfElement) {
      setIndex(undefined);
      setCurrentMedia([]);
    }
  }, [addingSvfElement]);

  // Edit elemento en SVF desde resumen
  useEffect(() => {
    editingFromResumen && setAddingSvfElement(true);
  }, [editingFromResumen]);

  // Handler del comportamiento UI de c/u de los componentes SVF
  useEffect(() => {
    !addingSvfElement &&
      editingFromResumen &&
      resumenGoBack(setEditFromResumen, setFormStep);
  }, [addingSvfElement]);

  // Go to <IndicacionesForm/> desde otros SVF cuando set prevSvf
  useEffect(() => {
    if (prevSvf !== undefined) {
      setFormStep(find_FS("indicaciones")!);
      setAddingSvfElement(true);
    }
  }, [prevSvf]);

  // Handler previo al llenado del VetForm
  useEffect(() => {
    getVetFormData(vetFormLink.vetForm);
    handleVetForm();
  }, [vetFormLink]);

  return (
    <>
      <MedicamentoContextProvider>
        <IonPage>
          <Header
            formStep={formStep}
            goBack={() => headerGoBack()}
            hideGoBack={editingFromResumen}
            disableGoBack={isSubmiting}
            confirmedSvf={confirmedSvf}
            addingSvfElement={addingSvfElement}
          />

          <IonContent>
            <div className="vet-form">
              {vetFormLink && (
                <>
                  {formStep === find_FS("usuario") && (
                    <DatosUsuario
                      siniestroId={vetFormLink.siniestro}
                      formStep={formStep}
                      goForward={() => modifyStep(sintomas?.length ? 1 : 2)}
                    />
                  )}

                  {formStep === find_FS("historialSintomas") &&
                    sintomas?.length !== 0 && (
                      <HistorialSintomas goForward={() => modifyStep(2)} />
                    )}
                </>
              )}

              {formStep === find_FS("veterinario") && (
                <Veterinario goForward={() => modifyStep(1)} />
              )}

              {formStep === find_FS("consulta") && (
                <Consulta goForward={() => modifyStep(1)} />
              )}

              {formStep === find_FS("sintomas") && (
                <SintomasContextProvider>
                  <SubVetForm
                    state={sintomas}
                    setState={setSintomas}
                    form={<SintomasForm {...moveProps} {...sharedProps.svf} />}
                    {...sharedProps.wrapper}
                  />
                </SintomasContextProvider>
              )}

              {formStep === find_FS("medicamentos") && (
                <SubVetForm
                  state={medicamentos}
                  setState={setMedicamentos}
                  form={<MedicamentosForm {...sharedProps.svf} />}
                  {...sharedProps.wrapper}
                />
              )}

              {formStep === find_FS("practicas") && (
                <SubVetForm
                  state={practicas}
                  setState={setPracticas}
                  form={
                    <PracticasForm
                      formattedPracticas={
                        formSelectPopoverOptionsProps.formattedPracticas
                      }
                      {...sharedProps.svf}
                    />
                  }
                  {...sharedProps.wrapper}
                />
              )}

              {formStep === find_FS("estudios") && (
                <SubVetForm
                  state={estudios}
                  setState={setEstudios}
                  form={
                    <EstudiosForm
                      formattedEstudios={
                        formSelectPopoverOptionsProps.formattedEstudios
                      }
                      {...sharedProps.svf}
                    />
                  }
                  {...sharedProps.wrapper}
                />
              )}

              {formStep === find_FS("cirugias") && (
                <SubVetForm
                  state={cirugias}
                  setState={setCirugias}
                  form={<CirugiasForm {...sharedProps.svf} />}
                  {...sharedProps.wrapper}
                />
              )}

              {formStep === find_FS("internaciones") && (
                <SubVetForm
                  state={internaciones}
                  setState={setInternaciones}
                  form={<InternacionesForm {...sharedProps.svf} />}
                  {...sharedProps.wrapper}
                />
              )}

              {formStep === find_FS("indicaciones") && (
                <SubVetForm
                  state={indicaciones}
                  setState={setIndicaciones}
                  form={
                    <IndicacionesForm
                      prevSvf={prevSvf}
                      setFormStep={setFormStep}
                      setAddedOk={setShowToast2}
                      fechaConsulta={consulta.fechaInicio}
                      veterinario={veterinario}
                      {...formSelectPopoverOptionsProps}
                      {...moveProps}
                      {...sharedProps.svf}
                    />
                  }
                  {...sharedProps.wrapper}
                />
              )}

              {formStep === find_FS("diagnostico") && (
                <DiagnosticoForm {...moveProps} />
              )}

              {formStep === find_FS("resumen") && (
                <Resumen {...submitProps} {...editFromResumenProps} />
              )}

              <ConfirmStep
                popoverState={popoverState}
                setShowPopover={setShowPopover}
                confirmedSvf={confirmedSvf}
                setConfirmSvf={setConfirmSvf}
                editingFromResumen={editingFromResumen}
                formStep={formStep}
                {...moveProps}
              />

              <Gallery />

              <ToastMsg
                isOpen={showToast}
                setIsOpen={setShowToast}
                message={stepsData[formStep].toastMsgEditing}
                color={"warning"}
              />

              <ToastMsg
                isOpen={showToast1}
                setIsOpen={setShowToast1}
                message={stepsData[formStep].toastMsgDeleted}
                color={"success"}
              />

              <ToastMsg
                isOpen={showToast2}
                setIsOpen={setShowToast2}
                message={stepsData[formStep]?.addedAsIndicacionMsg}
                color={"success"}
              />

              <ToastMsg
                isOpen={showToast3}
                setIsOpen={setShowToast3}
                message={
                  "Ups... ocurrio un error al enviar el formulario. Lo sentimos, por favor intententelo nuevamente."
                }
                color={"danger"}
              />

              <TutorialBasic formStep={formStep} setFormStep={setFormStep} />

              {/* TODO: 
              VER HISTORIAL DE SINTOMAS
              SEGUIR LIMPIANDO ARCHIVOS VIEJOS
              */}

              {/* <button
                onClick={() => {
                  clearPhotos();
                  clearPDFs();
                  setFormStep(find_FS("estudios")!);
                }}
              >
                Go Estudios
              </button> */}
            </div>
          </IonContent>
        </IonPage>
      </MedicamentoContextProvider>
    </>
  );
};

export default VetForm;
