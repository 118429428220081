import Vendaje from "../assets/SvfIcons/bandage.png";
import Jeringa from "../assets/SvfIcons/injection.png";
import Bisturi from "../assets/SvfIcons/scalpel.png";
import Suero from "../assets/SvfIcons/serum.png";
import Radiografia from "../assets/SvfIcons/xray.png";
import { PhotoItem } from "../interfaces/PhotoItem";
import { Indicacion } from "../interfaces/forms/Indicacion";
import { Sintoma } from "../interfaces/forms/Sintoma";
import { removeAccents } from "./General";
import moment from "moment";
import { FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";

//Interfaz para FormSelectOrCreate component
export interface Option {
  readonly label: string;
  readonly value: string;
}

export const findStepIndexByValue = (value: string): number | undefined => {
  return stepsData.map((step) => step?.value).indexOf(value);
};

//Errors in form
//Obtiene errores anidados de un objeto de errores
export const getErrors = (errors: DeepMap<any, FieldError>, name: string) => {
  // Verifica si hay errores
  if (!errors) {
    return undefined;
  }

  // Utiliza reduce para obtener el error anidado basado en el nombre de la propiedad
  const nestedError = name.split(".").reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined;
  }, errors);

  return nestedError;
};

//SVF auxiliary methods
export const setFormValuesFromObject = (data, setValue) => {
  Object.keys(data).forEach((fieldName) => {
    setValue(fieldName, data[fieldName]);
  });
};

export const existingElement = (Svf_Array, values) => {
  const isObject = (obj) => {
    return obj != null && typeof obj === "object";
  };

  // Función para comparación profunda de objetos
  const deepEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  };

  // Función para formatear una fecha al formato DD/MM/YYYY
  const formatDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD/MM/YYYY");
  };

  return Svf_Array.some((element) => {
    // Obtener los nombres de los campos de los valores
    const fields = Object.keys(values);

    // Verificar si todos los campos coinciden entre sí y si los objetos son iguales usando la función deepEqual
    return fields.every((field) => {
      if (
        typeof element[field] === "object" &&
        typeof values[field] === "object"
      ) {
        return deepEqual(element[field], values[field]);
      } else {
        // Si el campo es una fecha, formatearlo antes de comparar
        if (
          moment(element[field], "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid()
        ) {
          return formatDate(element[field]) === formatDate(values[field]);
        } else {
          return element[field] === values[field];
        }
      }
    });
  });
};

export const editElement = (prevElements, index: number, values) => {
  return prevElements.map((element, i) =>
    i === index ? { ...element, ...values } : element
  );
};

export const submitSvf = (index, state, setter, values, setShowToast) => {
  if (index !== undefined) {
    // Editar elemento existente
    setter((prevElement) => editElement(prevElement, index, values));
  } else {
    // Agregar nuevo elemento
    setter([...state, values]);
  }

  return true;
};

export const deleteElement = (
  setter: React.Dispatch<React.SetStateAction<any>>,
  indexToDelete: number
) => {
  setter((prevData) => prevData.filter((_, index) => index !== indexToDelete));
};

export const removeProperty = (data, propertyName) => {
  if (typeof data !== "object" || data === null) {
    throw new Error("El primer argumento debe ser un objeto o un array.");
  }

  const processItem = (item) => {
    const { [propertyName]: propToRemove, ...unchanged } = item;
    return unchanged;
  };

  if (Array.isArray(data)) {
    return data.map(processItem);
  } else {
    return processItem(data);
  }
};

export const formatSintomasToSend = (
  sintomas: Sintoma[],
  propertiesToKeep: string[]
) => {
  if (!Array.isArray(sintomas)) {
    throw new Error("El primer argumento debe ser un array.");
  }

  if (
    !Array.isArray(propertiesToKeep) ||
    propertiesToKeep.some((prop) => typeof prop !== "string")
  ) {
    throw new Error("El segundo argumento debe ser un array de strings.");
  }

  const processItem = (item: Sintoma) => {
    const formattedItem: any = {};
    propertiesToKeep.forEach((property) => {
      if (item.hasOwnProperty(property)) {
        formattedItem[property] = item[property];
      }
    });
    return formattedItem;
  };

  return sintomas.map(processItem);
};

export const formatIndicacionesToSend = (indicaciones: Indicacion[]) => {
  const formattedIndicaciones: Indicacion[] = indicaciones
    .map((indicacion) => {
      // Realizar una copia del objeto indicacion para no modificar el original ya que se usa en resumen
      const indicacionCopia = { ...indicacion };

      // Convertir a mayúsculas y eliminar acentos
      const tipoIndicacion = removeAccents(
        indicacionCopia.tipoIndicacion.toUpperCase()
      );

      switch (tipoIndicacion) {
        case "CONTROL":
        case "ESTUDIO":
        case "PRACTICA":
        case "CIRUGIA":
        case "INTERNACION":
        case "NO FARMACOLOGICA":
        case "OTROS":
          indicacionCopia.tipoIndicacion = tipoIndicacion;
          return indicacionCopia;
        case "FARMACOLOGICA":
          return {
            tipoIndicacion: tipoIndicacion,
            farmacologia: {
              medicamento: {
                id: indicacionCopia.id,
                nombre_comercial: indicacionCopia.medicamento?.toUpperCase(),
              },
              dosaje: indicacionCopia.dosaje,
              frecuencia: indicacionCopia.frecuencia,
              duracion: indicacionCopia.duracion,
            },
          };
        default:
          return [];
      }
    })
    // Filtrar elementos undefined
    .filter((indicacion) => indicacion !== undefined) as Indicacion[];

  return formattedIndicaciones;
};
//End SVF auxiliary methods

export const indicacionOptions = [
  "Farmacológica",
  "Control",
  "Estudio",
  "Práctica",
  "Cirugía",
  "Internación",
  "No farmacológica",
  "Otros",
];

export const noFarmacologicaOptions = [
  "Ayuno sólido",
  "Ayuno sólido y líquido ",
  "Reposo en jaula",
  "Toilette de herida cada 8 hs",
  "Toilette de herida cada 12 hs",
  "Toilette de herida cada 24 hs",
  "Tratamiento local de herida cada 6 hs",
  "Tratamiento local de herida cada 8 hs",
  "Tratamiento local de herida cada 12 hs",
  "Tratamiento local de herida cada 24 hs",
  "Colcar collar isabelino",
  "Cambiar gaza cada 24 hs",
  "Otros",
];

export const fechasEstimadas = [
  "Muy reciente: Última semana",
  "Reciente: Entre 1 y 2 semanas",
  "Intermedia: Entre 1 y 6 meses",
  "Antigua: Entre 6 meses y 1 año",
  "Muy antigua: más de un año",
];

export const getEmptyFieldProps = () => ({
  Svf_title: "",
  newLabel: "",
  cardTitleProp: "",
  cardSubtitleProp: "",
  deleteLabel: "",
  toastMsgDeleted: "",
});

export const getTypeLabelByType = (targetType: string) => {
  const step = stepsData.find((step) => step.type === targetType);

  if (step) {
    return step.typeLabel;
  } else {
    switch (targetType) {
      case "Control":
        return "este control";
      case "No farmacológica":
      case "Otros":
        return "esta indicación";
      default:
        console.log(`getTypeLabelByType default`);
    }
  }

  return null;
};

export const indicacionesGoBack = (
  prevSvf: string | undefined,
  setFormStep: React.Dispatch<React.SetStateAction<number>>,
  setAddingSvfElement: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (prevSvf) {
    const step = findStepIndexByValue(prevSvf);
    step && setFormStep(step);
  }
  setAddingSvfElement(false);
};

export const resumenGoBack = (
  setEditFromResumen: React.Dispatch<React.SetStateAction<boolean>>,
  setFormStep: React.Dispatch<React.SetStateAction<number>>
) => {
  setEditFromResumen(false);
  const step = findStepIndexByValue("resumen");
  step && setFormStep(step);
};

export const checkAndResetMedia = (
  type: string | undefined,
  currentMedia: PhotoItem[],
  setCurrentMedia: React.Dispatch<React.SetStateAction<PhotoItem[]>>,
  setShowToast1: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  if (type && currentMedia.length > 0) {
    const details: string[] = currentMedia.map((photo) => photo.tag.detail);
    const hasMismatch: boolean = details.some(
      (detail) => detail.replace(/[0-9]/g, "") !== type
    );
    if (hasMismatch) {
      setCurrentMedia([]);
      setShowToast1(true);
    }
  }
};

export const blobURLtoBase64 = async (blobUrl: string): Promise<string> => {
  const response = await fetch(blobUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch blob: ${response.statusText}`);
  }

  const blob = await response.blob();
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input blob."));
    };

    reader.onloadend = () => {
      const base64String = reader.result as string;
      // Extraer la parte relevante de la cadena base64
      const base64Result = base64String.split(",")[1];
      resolve(base64Result);
    };

    reader.readAsDataURL(blob);
  });
};

export const createBase64ArrayFromMedia = async (
  currentMedia: PhotoItem[]
): Promise<string[]> => {
  // Filtrar y convertir los elementos de currentMedia en base64
  const mediaBase64 = await Promise.all(
    currentMedia
      .filter((element) => element.webviewPath !== undefined)
      .map(async (element) => {
        const base64String = await blobURLtoBase64(element.webviewPath!);
        return base64String;
      })
  );

  return mediaBase64;
};

export const stepsData = [
  {
    value: "usuario",
    stepInfo: {
      title: "Bienvenido al formulario de FielPet",
      info: "Solicitamos que complete la información de la consulta para que su cliente pueda acceder al reintegro por los gastos veterinarios incurridos. Es muy sencillo, en unos pocos minutos habremos terminado!",
    },
  },
  {
    value: "historialSintomas",
  },
  {
    value: "veterinario",
    stepInfo: {
      title: "Datos del veterinario",
      info: "En este panel deberá completar sus datos, los cuales se le pediran por unica vez. La próxima vez que atienda a un cliente asegurado en FielPet, los mismos se completarán automáticamente.",
    },
  },
  {
    value: "consulta",
    stepInfo: {
      title: "Datos de la consulta",
      info: "El campo motivo de la consulta/anamnesis es opcional. Indique el día en que se atendió a la mascota y el importe total abonado por el cliente en la consulta ademas de donde realizo la atención.",
    },
  },
  {
    value: "sintomas",
    label: "Síntomas",
    confirmationPopover: true,
    popoverLabel: "¿La mascota presentaba síntomas?",
    hideOptions: true,
    toastMsgEditing: "Modifique el síntoma para guardar cambios",
    toastMsgDeleted: "Síntoma eliminado",
    fieldProps: {
      Svf_title: "Síntomas",
      newLabel: "Nuevo síntoma",
      cardTitleProp: "sintoma",
      cardSubtitleProp: "descripcion",
      deleteLabel: "¿Desea eliminar el síntoma?",
    },
    stepInfo: {
      title: "Síntomas",
      info: "Indicar los síntomas que presentaba la mascota al momento de la atención. Es posible cargar más de 1 síntoma si es necesario.",
    },
  },
  {
    value: "medicamentos",
    label: "Medicamentos",
    confirmationPopover: true,
    popoverLabel: "¿Se aplicaron y/o recetaron medicamentos en la consulta?",
    regularLabel: "Medicamento aplicado en la consulta",
    indicacionLabel:
      "¿Recetó algún medicamento para seguir el tratamiento? Indique cuál",
    toastMsgEditing: "Modifique el medicamento para guardar cambios",
    toastMsgDeleted: "Medicamento eliminado",
    fieldProps: {
      Svf_title: "Medicamentos",
      newLabel: "Nuevo medicamento",
      cardTitleProp: "nombre_comercial",
      deleteLabel: "¿Desea eliminar el medicamento?",
    },
    stepInfo: {
      title: "Medicamentos",
      info: "Seleccionar los medicamentos que se le aplicaron a la mascota en la consulta. En caso de haber recetado un medicamento para ser aplicado luego de la consulta se debe completar como medicamento recetado.",
    },
    addedAsIndicacionMsg: "Medicamento agregado con exito en indicaciones!",
    setValue: (setValue) => setValue("tipoIndicacion", "Farmacológica"),
    icon: Jeringa,
  },
  {
    value: "practicas",
    label: "Prácticas",
    type: "Práctica",
    typeLabel: "esta práctica",
    confirmationPopover: true,
    popoverLabel: "¿Se realizaron y/o indicaron prácticas en la consulta?",
    regularLabel: "Práctica realizada en la consulta",
    indicacionLabel:
      "¿Indicó alguna práctica que aún no fue realizada? Indique cuál",
    toastMsgEditing: "Modifique la práctica para guardar cambios",
    toastMsgDeleted: "Práctica eliminada",
    fieldProps: {
      Svf_title: "Prácticas",
      newLabel: "Nueva práctica",
      cardTitleProp: "practica",
      cardSubtitleProp: "descripcion",
      deleteLabel: "¿Desea eliminar la práctica?",
    },
    stepInfo: {
      title: "Prácticas",
      info: "Seleccionar las práctica realizadas en la consulta. En caso de haber indicado una práctica para ser realizada luego de la consulta se debe completar como práctica indicada.",
    },
    addedAsIndicacionMsg: "Practica agregada con exito en indicaciones!",
    setValue: (setValue) => setValue("tipoIndicacion", "Práctica"),
    icon: Vendaje,
  },
  {
    value: "estudios",
    label: "Estudios",
    type: "Estudio",
    typeLabel: "este estudio",
    confirmationPopover: true,
    popoverLabel: "¿Se realizaron y/o indicaron estudios en la consulta?",
    regularLabel: "Estudio realizado en la consulta",
    indicacionLabel:
      "¿Indicó algún estudio que aún no fue realizado? Indique cuál",
    toastMsgEditing: "Modifique el estudio para guardar cambios",
    toastMsgDeleted: "Estudio eliminado",
    fieldProps: {
      Svf_title: "Estudios",
      newLabel: "Nuevo estudio",
      cardTitleProp: "estudio",
      cardSubtitleProp: "descripcion",
      deleteLabel: "¿Desea eliminar el estudio?",
    },
    stepInfo: {
      title: "Estudios",
      info: "Completar con los estudios que se realizaron en la consulta. En caso de haber recetado un estudio para ser realizado luego de la consulta se debe completar como estudio indicado.",
    },
    addedAsIndicacionMsg: "Estudio agregado con exito en indicaciones!",
    setValue: (setValue) => setValue("tipoIndicacion", "Estudio"),
    icon: Radiografia,
  },
  {
    value: "cirugias",
    label: "Cirugías",
    type: "Cirugía",
    typeLabel: "esta cirugía",
    confirmationPopover: true,
    popoverLabel: "¿Se realizaron y/o indicaron cirugías en la consulta?",
    regularLabel: "Cirugía realizada en la consulta",
    indicacionLabel:
      "¿Indicó alguna cirugía que aún no fue realizada? Indique cuál",
    toastMsgEditing: "Modifique la cirugía para guardar cambios",
    toastMsgDeleted: "Cirugía eliminada",
    fieldProps: {
      Svf_title: "Cirugías",
      newLabel: "Nueva cirugía",
      cardTitleProp: "cirugia",
      cardSubtitleProp: "descripcion",
      deleteLabel: "¿Desea eliminar la cirugía?",
    },
    stepInfo: {
      title: "Cirugías",
      info: "Completar con las cirugías realizadas en la consulta. En caso de haber indicado una cirugía para ser realizada luego de la consulta se debe completar como cirugía indicada.",
    },
    addedAsIndicacionMsg: "Cirugía agregada con exito en indicaciones!",
    setValue: (setValue) => setValue("tipoIndicacion", "Cirugía"),
    icon: Bisturi,
  },
  {
    value: "internaciones",
    label: "Internaciones",
    type: "Internación",
    typeLabel: "esta internación",
    confirmationPopover: true,
    popoverLabel: "¿Se realizó y/o indicó una internación en la consulta?",
    regularLabel: "Internación realizada en la consulta",
    indicacionLabel: "¿Indicó una internación para continuar el tratamiento?",
    toastMsgEditing: "Modifique la internación para guardar cambios",
    toastMsgDeleted: "Internación eliminada",
    fieldProps: {
      Svf_title: "Internaciones",
      newLabel: "Nueva internación",
      cardTitleProp: "",
      // cardSubtitleProp: "descripcion",
      cardSubtitleProp: "",
      deleteLabel: "¿Desea eliminar la internación?",
    },
    stepInfo: {
      title: "Internaciones",
      info: "Completar con las internaciones realizadas en la consulta. En caso de haber indicado una internación para ser realizada luego de la consulta se debe completar como internación indicada.",
    },
    addedAsIndicacionMsg: "Internación agregada con exito en indicaciones!",
    setValue: (setValue) => setValue("tipoIndicacion", "Internación"),
    icon: Suero,
  },
  {
    value: "indicaciones",
    label: "Indicaciones",
    confirmationPopover: true,
    popoverLabel:
      "¿Prescribió otra indicación? (Controles, derivaciones, indicaciones no farmacológicas, etc.)",
    hideOptions: true,
    toastMsgEditing: "Modifique la indicación para guardar cambios",
    toastMsgDeleted: "Indicación eliminada",
    fieldProps: {
      Svf_title: "Indicaciones",
      newLabel: "Nueva indicación",
      cardTitleProp: "tipoIndicacion",
      cardSubtitleProp: "descripcion",
      deleteLabel: "¿Desea eliminar la indicación?",
    },
    stepInfo: {
      title: "Indicaciones",
      info: "Seleccione el tipo de indicación prescripta. Si desea recomendar a un colega para que realice dicha indicación puede completar sus datos de contacto en la sección de veterinarios sugeridos.",
    },
  },
  {
    value: "diagnostico",
    label: "Diagnóstico",
    stepInfo: {
      title: "Diagnóstico",
      info: "Complete el diagnóstico presuntivo del paciente y la fecha estimada en que la patología pudo haberse gestado. Detalle si cree que es congénita, si requiere tratamiento de por vida o si puede responder a otra enfermedad de base.",
    },
  },
  {
    value: "resumen",
    label: "Resumen",
    stepInfo: {
      title: "Resumen",
      info: "Este es el resumen de su consulta, para confirmar y finalizar el proceso presione el botón de Enviar formulario. Puede realizar un cambio con el lapiz correspondiente a cada indicacion de la consulta.",
    },
  },
];
