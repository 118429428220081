import React from "react";
import { IonContent, IonHeader, IonPage } from "@ionic/react";
import SintomaNorma from "../../components/sintomaNorma";
import { SintomasContextProvider } from "../../context/SintomasContext";
import Logo from '../../assets/FielPetLogo.png';
import "./TigrePage.scss";

const TigrePage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent>
        <SintomasContextProvider>
          <SintomaNorma />
        </SintomasContextProvider>
      </IonContent>
    </IonPage>
  );
};

export default TigrePage;
