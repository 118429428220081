import React from "react";
import { IonToast } from "@ionic/react";
import { ToastMsgProps } from './types';

import './ToastMsg.scss';

const ToastMsg: React.FC<ToastMsgProps> = ({ isOpen, setIsOpen, message, color }) => {
  return (
    <div
      className="toast-msg"
    >
      <IonToast
        mode="ios"
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        message={message}
        color={color}
        duration={4000}
        cssClass={'toast-msg__message'}
      />
    </div>
  )
}

export default ToastMsg;