export type VetContact = {
  nombre: string;
  apellido: string;
  email: string;
  telefono: string;
};

export const getEmptyVetContact: () => VetContact = () => ({
  nombre: "",
  apellido: "",
  email: "",
  telefono: "",
});

export const getEmptyVetContacts: VetContact[] = [];
