import React, { createContext, useState, useEffect } from 'react';
import { getMedicamentos as axiosGetMedicamentos } from '../axios/getMedicamentos';
import { Medicamento, getEmptyMedicamento } from '../interfaces/Medicamento';

class MedicamentoContextType {
    constructor(medicamentos?: Medicamento[], searchableMedicamentos?: { label: string, value: string }[], ready?: boolean) {
        this.medicamentos = medicamentos ?? [];
        this.searchableMedicamentos = searchableMedicamentos ?? [];
        this.ready = ready ?? false;
    }
    medicamentos: Medicamento[];
    searchableMedicamentos: { label: string, value: string }[];
    ready: boolean;
    medBynombre_comercial(nombre_comercial: string): Medicamento {
        return this.medicamentos.find((med) => med.nombre_comercial === nombre_comercial) ?? getEmptyMedicamento();
    }
};

export const MedicamentoContext = createContext<MedicamentoContextType>(
    new MedicamentoContextType()
);

//@TODO, por que estoy seteando 2 veces esta vaina inicial, seguramente una es al pedo, mas probablemente la de arriba

export const MedicamentoContextProvider: React.FC = ({ children }) => {
    const [medicamentoContextData, setMedicamentoContextData] = useState<MedicamentoContextType>(new MedicamentoContextType());

    const getMedicamentos = async () => {
        const medicamentos = await axiosGetMedicamentos()
        const newContextData = new MedicamentoContextType(medicamentos, medicamentoContextData.searchableMedicamentos, false);
        newContextData.medBynombre_comercial = newContextData.medBynombre_comercial.bind(newContextData);  //@TODO capaz se pueda ir, testear
        setMedicamentoContextData(newContextData);
    };


    useEffect(() => {
        if (medicamentoContextData.medicamentos.length === 0) {
            getMedicamentos();
        }
    }, []);

    useEffect(() => {
        if (medicamentoContextData.medicamentos.length > 0) {
            const newContextData = new MedicamentoContextType(
                medicamentoContextData.medicamentos,
                medicamentoContextData.medicamentos.map((m) => ({ value: m.nombre_comercial!, label: m.nombre_comercial!, ...m })),
                true
            )
            newContextData.medBynombre_comercial = newContextData.medBynombre_comercial.bind(newContextData); //@TODO capaz se pueda ir, testear
            setMedicamentoContextData(newContextData);
        }
    }, [medicamentoContextData.medicamentos]);

    return (
        <MedicamentoContext.Provider value={medicamentoContextData}>
            {children}
        </MedicamentoContext.Provider>
    );
}