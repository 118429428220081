import React from 'react';
import moment from 'moment';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { SintomaMascotaProps } from './types';
import './SintomaMascota.scss';

import { capitalizeFirstLetter } from '../../utils/General';

const SintomaMascota: React.FC<SintomaMascotaProps> = ({ sintoma }) => {
  function format(str: string) {
    const sintomaOk = str.toLowerCase();
    return capitalizeFirstLetter(sintomaOk);
  }
  return (
    <div className='sintoma-mascota'>
      <IonGrid className='ion-no-padding'>
        <IonRow className='row'>
          <IonCol size="6" className='title'>Síntoma</IonCol>
          <IonCol size="6" className="ion-text-end">
            {capitalizeFirstLetter(sintoma.sintoma.toLowerCase())}
          </IonCol>
        </IonRow>
        {sintoma.descripcion && <IonRow className='row'>
          <IonCol size="6" className='title'>Descripción</IonCol>
          <IonCol size="6" className="ion-text-end">
            {capitalizeFirstLetter(sintoma.descripcion.toLowerCase())}
          </IonCol>
        </IonRow>}
        {sintoma.detalle && (
          <IonRow className='row'>
            <IonCol size="6" className='title'>Detalles</IonCol>
            <IonCol size="6" className="ion-text-end">
              {capitalizeFirstLetter(sintoma.detalle.toLowerCase())}
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol size="6" className='title'>Inicio de síntoma</IonCol>
          <IonCol size="6" className="ion-text-end">
            {/* VER QUE FECHA USAR, HAY MUCHAS */}
            {moment(sintoma.primera_visita).format('DD/MM/YYYY')}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default SintomaMascota;
