import { AxiosResponse } from 'axios';

import { DatosUsuario } from '../interfaces/forms/DatosUsuario';
import axios from '.';

export const getDatosUsuario = async (url: string, siniestroId: string) => {
  try {
    const { data }: AxiosResponse<DatosUsuario> = await axios.get(url, {
      params: {
        siniestroId,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
