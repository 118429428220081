import FielPetLogo from "../../assets/FielPetLogo.png";
import { TutorialContext } from "../../context/TutorialContext";
import Step from "../Step";
import "./TutorialBasic.scss";
import { TutorialBasicProps } from "./types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Tour, { ReactourStep, ReactourStepPosition } from "reactour";

const TutorialBasic: React.FC<TutorialBasicProps> = ({
  formStep,
  setFormStep,
}) => {
  const history = useHistory();

  const { tutorialDoneStatus, setTutorialDoneStatus } =
    useContext(TutorialContext);

  const [steps, setSteps] = useState<ReactourStep[]>([]);
  const [startTour, setStartTour] = useState<boolean>(false);

  const basicSteps: ReactourStep[] = useMemo(
    () => [
      {
        content: (
          <>
            <h3 style={{ color: "#000" }}>Bienvenido/a a </h3>
            <img style={{ width: "200px" }} src={FielPetLogo} />
            <p style={{ color: "#000" }}>¡Comencemos con el tutorial!</p>
          </>
        ),
        position: "center" as ReactourStepPosition,
      },
      {
        selector: '[data-tut="reactour__go-forward-btn"]',
        content: (
          <Step
            title="Este es el botón para avanzar al siguiente paso."
            body={`En esta página podrás ver los datos del tutor, del paciente y el motivo de la consulta, para continuar selecciona el botón "Completar historia clínica".`}
          />
        ),

        stepInteraction: false,
      },
      {
        selector: '[data-tut="reactour__go-back-btn"]',
        content: (
          <Step
            title="Este es el botón para regresar al paso anterior."
            body="Si necesitás modificar o agregar información, siempre podés volver atrás."
            callback={() => setFormStep(2)}
          />
        ),
        stepInteraction: false,
      },
      {
        selector: '[data-tut="reactour__stepTutorial"]',
        content: (
          <Step
            title="Este es el botón para visualizar las intrucciones correspondientes a cada paso."
            body="Con este boton podra recibir asistencia a la hora de completar el formulario."
          />
        ),
        stepInteraction: false,
      },
      {
        selector: '[data-tut="reactour__tutorial"]',
        content: (
          <Step
            title="Este es el botón para vizualizar el tutorial nuevamente."
            body="Podrás volver a verlo cuando lo necesites."
            callback={() => setFormStep(0)}
          />
        ),

        stepInteraction: false,
      },
      {
        content: (
          <Step
            title="¡Felicidades, has completado el tour!"
            // body="Disfrute Fielpet"
          />
        ),
        position: "center" as ReactourStepPosition,
      },
    ],
    []
  );

  const explainFormSteps: ReactourStep[] = useMemo(
    () => [
      {
        selector: '[data-tut="reactour__disabled-goBack-btn"]',
        content: <Step title="explicar Form" body="Aquí hemos bloqueado..." />,
      },
      {
        selector: '[data-tut="reactour__disabled-goForward-btn"]',
        content: <Step body="Aquí hemos bloqueado..." />,
      },
      {
        selector: '[data-tut="reactour__new-svf"]',
        content: (
          <Step body="Aquí es necesario que resuelva el formulario..." />
        ),
      },
      {
        selector: '[data-tut="reactour__submit-btn"]',
        content: (
          <Step body="Puede completar y guardar los cambios para continuar..." />
        ),
      },
      {
        selector: '[data-tut="reactour__cancel-btn"]',
        content: (
          <Step body="Como tambien cancelar para continuar con el proceso..." />
        ),
      },
    ],
    []
  );

  useEffect(() => {
    // Comparing with false because tutorialDoneStatus is undefined until its value is getted from the storage
    if (tutorialDoneStatus === false) {
      setTimeout(() => {
        setStartTour(true);
      }, 500);
    }
  }, [tutorialDoneStatus]);

  useEffect(() => {
    if (tutorialDoneStatus === false) {
      if (formStep === 0) {
        setSteps(basicSteps);
      }

      if (formStep >= 4) {
        setSteps(explainFormSteps);
      }
    }
  }, [tutorialDoneStatus]);

  return (
    <>
      <Tour
        update={history.location.pathname}
        onBeforeClose={() => {
          if (formStep <= 2) {
            setFormStep(0);
          }
          setTutorialDoneStatus(true);
        }}
        closeWithMask={false}
        steps={steps}
        startAt={0}
        // goToStep={goToStep}
        isOpen={startTour}
        onRequestClose={() => setStartTour(false)}
        rounded={5}
        accentColor="#1ea473"
      />
    </>
  );
};

export default TutorialBasic;
