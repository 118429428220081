import { Preferences } from '@capacitor/preferences';

export const useStorage = () => {
  const getValue = async (key: string) => {
    const { value } = await Preferences.get({ key });
    return value;
  };

  const setValue = async (key: string, value: any) => {
    await Preferences.set({
      key,
      value,
    });
  };

  const clearValue = async (key: string) => {
    await Preferences.remove({ key });
  };

  return { getValue, setValue, clearValue };
};
