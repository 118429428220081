import React from 'react';
import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { Controller } from 'react-hook-form';
import { FormSelectPopoverProps } from './types';
import './FormSelectPopover.scss';
import { getErrors } from '../../utils/Form';

const FormSelectPopover: React.FC<FormSelectPopoverProps> = ({
  formMethods,
  options,
  fieldProps,
  validationProps,
  disabled
}) => {
  const { control, errors } = formMethods;
  const { name, label, placeholder } = fieldProps;
  const { errorMessage } = validationProps || {};

  return (
    <Controller
      name={name}
      control={control}
      errors={errors}
      rules={{
        required: {
          value: validationProps ? true : false,
          message: errorMessage ?? "",
        }
      }}
      render={(
        { onChange, name, value }
      ) => (
        <div className="form-select-popover">
          <IonItem lines="inset" className="ion-no-padding">
            <IonLabel position="floating">{label}</IonLabel>
            <IonSelect
              mode='ios'
              interface='alert'
              name={name}
              value={value}
              onIonChange={onChange}
              placeholder={placeholder}
              className={disabled ? "disabled" : undefined}
              okText='Confirmar'
              cancelText='Cancelar'
            >
              {options.map((value: string) => {
                return (
                  <IonSelectOption key={value} value={value}>
                    {value}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          <div
            className="form-select-popover__error-message"
            style={getErrors(errors, name) ? { opacity: 1 } : undefined}
          >
            {getErrors(errors, name)?.message}
          </div>
        </div>
      )}
    />
  );
};

export default FormSelectPopover;