import { SintomasContext } from "../../context/SintomasContext";
import { TempSintoma } from "../../hooks/useSintomas";
import { Sintoma } from "../../interfaces/forms/Sintoma";
import { Option } from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import FormSelectOrCreate from "../FormSelectOrCreate";
import "./SintomaNorma.scss";
import { IonButton } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";

interface Caracteristica {
  grupo: string;
  descripciones: {
    valor: string;
    selectores: string[];
  }[];
}

interface SelectedOptions {
  [grupo: string]: string[];
}

const SintomaNorma: React.FC = () => {
  const { searchableSintomas, isLoading } = useContext(SintomasContext);

  const { control, errors, setValue, watch } = useForm<Sintoma>();
  const formMethods = { control, errors, setValue };
  const sintoma: string = watch("sintoma");
  const [option, setOption] = useState<Option | null>();

  const [caracteristicasDisponibles, setCaracteristicasDisponibles] =
    useState<Caracteristica[]>();
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>([]);

  const handleChange = (options, grupo) => {
    if (options !== null) {
      const selectedValues = options.map((option) => option.value);
      // Almacenar las opciones seleccionadas junto con su grupo
      setSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [grupo]: selectedValues,
      }));
    } else {
      // Si no hay opciones seleccionadas, eliminar el grupo de las opciones almacenadas
      setSelectedOptions((prevOptions) => {
        const updatedOptions = { ...prevOptions };
        delete updatedOptions[grupo];
        return updatedOptions;
      });
    }
  };

  const handleCopy = () => {
    const json = {
      sintoma: sintoma,
      caracteristicas: selectedOptions,
    };

    const jsonString = JSON.stringify(json);

    // Intenta copiar el JSON al portapapeles
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        console.log("JSON copiado al portapapeles:", jsonString);
        window.alert("Copiado al portapapeles: \n\n" + jsonString);
      })
      .catch((error) => {
        console.error("Error al copiar al portapapeles:", error);
      });
  };

  useEffect(() => {
    setSelectedOptions([]);

    if (sintoma !== undefined) {
      const currentSintoma = (searchableSintomas as TempSintoma[]).find(
        (cs) =>
          cs.nombre.toLowerCase() === sintoma.toLowerCase() ||
          cs.label.toLowerCase() === sintoma.toLowerCase()
      );

      if (currentSintoma?.clasificaciones.length !== 0) {
        const caracteristicas = currentSintoma?.clasificaciones.map(
          (clasificacion) => clasificacion
        );
        setCaracteristicasDisponibles(caracteristicas);
      } else {
        setCaracteristicasDisponibles([]);
      }
    }
  }, [sintoma]);

  return (
    <div className="sintoma-norma ion-padding-horizontal ion-padding-bottom">
      <div className="ion-margin-vertical">
        <FormSelectOrCreate
          options={searchableSintomas as Option[]}
          option={option}
          isLoading={isLoading}
          formMethods={formMethods}
          fieldProps={{
            name: "sintoma",
            label: "Puede buscar por nombre del síntoma",
            placeholder: "Por favor seleccione un síntoma",
          }}
          validationProps={{
            errorMessage: "Por favor ingrese un síntoma",
          }}
        />
      </div>

      {sintoma !== undefined &&
        caracteristicasDisponibles?.map((e, index) => (
          <div key={index} className="ion-margin-bottom">
            <Select
              isMulti
              options={e.descripciones.map((option) => ({
                value: option.valor,
                label: option.valor,
              }))}
              onChange={(options) => handleChange(options, e.grupo)}
              key={sintoma + e.grupo}
              placeholder={capitalizeFirstLetter(e.grupo)}
            />
          </div>
        ))}

      <div className={sintoma ? "selected" : undefined}>
        <div hidden={!sintoma} className="selectedTitle">
          Selección:
        </div>
        <div className="sintoma">{sintoma}</div>
        {Object.keys(selectedOptions).map((grupo, index) => (
          <div key={index}>
            <div className="grupo">{capitalizeFirstLetter(grupo)}</div>
            {selectedOptions[grupo].map((opcion, index) => (
              <div key={index} className="caracteristica">
                {capitalizeFirstLetter(opcion)}
              </div>
            ))}
          </div>
        ))}
      </div>

      <IonButton
        onClick={handleCopy}
        disabled={!sintoma}
        mode="ios"
        expand="block"
      >
        Copiar información
      </IonButton>
    </div>
  );
};

export default SintomaNorma;
