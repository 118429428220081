import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { IonLabel } from "@ionic/react";
import { FormSelectOrCreateProps } from './types';

import './FormSelectOrCreate.scss';
import { Option, getErrors } from "../../utils/Form";

const createOption = (label: string) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const defaultOptions = [
  createOption('')
];

const FormSelectOrCreate: React.FC<FormSelectOrCreateProps> = ({ creatable, options: propsOptions, option, isLoading: loading, formMethods, fieldProps, validationProps }) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState<Option | null>();

  const { control, errors, setValue: RHF_setValue } = formMethods;
  const { name, label, placeholder } = fieldProps;
  const { errorMessage } = validationProps || {};

  const handleCreate = (inputValue: string) => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setValue(newOption);

    }, 500);
  };

  useEffect(() => {
    if (propsOptions) {
      const options = propsOptions.map((opt) => createOption(opt.label));
      setOptions(options);
    }
  }, [propsOptions]);

  useEffect(() => {
    RHF_setValue(name, value?.label);
  }, [value])

  //Edit
  useEffect(() => {
    setValue(option);
  }, [option])

  return (
    <Controller
      name={name}
      control={control}
      errors={errors}
      rules={{
        required: {
          value: validationProps ? true : false,
          message: errorMessage ?? "",
        },
      }}
      render={(
        { name }
      ) => (
        <div
          className="form-select-or-create"
        >
          {creatable ? <>
            {label &&
              <div className="form-select-or-create__label">
                <IonLabel>{label}</IonLabel>
              </div>
            }

            <CreatableSelect
              isClearable
              isDisabled={isLoading}
              isLoading={isLoading || loading}
              loadingMessage={() => 'Cargando...'}
              onChange={(newValue) => setValue(newValue)}
              options={options}
              value={value}
              placeholder={placeholder}
              onCreateOption={handleCreate}
              formatCreateLabel={(inputValue: string) => `Crear ${inputValue}`}
              noOptionsMessage={(obj: {
                inputValue: string
              }) => "No se encontraron opciones"}
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: '#1ea473',
                  primary: '#444444',
                },
              })}
              styles={{
                // Fixes the overlapping problem of the component
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
            />
          </> :
            <>
              {label &&
                <div className="form-select-or-create__label">
                  <IonLabel>{label}</IonLabel>
                </div>
              }

              <Select
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading || loading}
                loadingMessage={() => 'Cargando...'}
                onChange={(newValue) => setValue(newValue)}
                options={options}
                value={value}
                placeholder={placeholder}
                noOptionsMessage={(obj: {
                  inputValue: string
                }) => "No se encontraron opciones"}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: '#1ea473',
                    primary: '#444444',
                  },
                })}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
              />
            </>
          }

          <div
            className="form-input__error-message"
            style={getErrors(errors, name) ? { opacity: 1 } : undefined}
          >
            {getErrors(errors, name)?.message}
          </div>
        </div>
      )}
    />
  )
}

export default FormSelectOrCreate;