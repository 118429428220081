import Camera from "../../assets/cute-camera.png";
import Gallery from "../../assets/image-galery.png";
import Plus from "../../assets/plus.png";
import PDF from "../../assets/pdf.png";
import { useMedia } from "../../context/MediaContext";
import "./FileOptionsPopover.scss";
import { FileOptionsPopoverProps } from "./types";
import { IonCol, IonGrid, IonPopover, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";

const FileOptionsPopover: React.FC<FileOptionsPopoverProps> = ({
  popoverState,
  setShowPopover,
  tag,
  handleFileInput,
}) => {
  const { takePhoto, currentMedia, setShowMediaState } = useMedia();
  const [showWithMediaOptions, setShowWithMediaOptions] =
    useState<boolean>(false);

  const withMediaOptions = [
    {
      label: "Adjuntar un nuevo archivo",
      // description: "This is the description for card 1.",
      img: Plus,
      onClick: () => setShowWithMediaOptions(false),
    },
    {
      label: "Ver archivos adjuntos",
      // description: "This is the description for card 2.",
      img: Gallery,
      onClick: () => {
        setShowPopover({
          showPopover: false,
          event: undefined,
        });
        setShowMediaState("gallery");
      },
    },
  ];

  const fileOptions = [
    {
      label: "Camara o galería",
      // description: "This is the description for card 1.",
      img: Camera,
      onClick: () => takePhoto("PHOTOS", tag),
    },
    {
      label: "PDF",
      // description: "This is the description for card 1.",
      img: PDF,
      onClick: () => handleFileInput(),
    },
  ];

  useEffect(() => {
    setShowWithMediaOptions(currentMedia.length > 0);
  }, [currentMedia]);

  return (
    <div className="file-options">
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        cssClass="file-options-popover"
        onDidDismiss={() => {
          setShowPopover({
            showPopover: false,
            event: undefined,
          });
          setShowWithMediaOptions(currentMedia.length > 0);
        }}
      >
        {showWithMediaOptions ? (
          <>
            <IonGrid>
              {withMediaOptions.map((element, index) => (
                <IonRow
                  key={index}
                  onClick={() => element.onClick()}
                  className={
                    index !== withMediaOptions.length - 1
                      ? "ion-margin-bottom"
                      : undefined
                  }
                >
                  <IonCol size="3">
                    <img src={element.img} alt={element.label} />
                  </IonCol>
                  <IonCol offset="1">
                    <div>
                      <div className="title">{element.label}</div>
                      {/* <div className="subtitle">{element.description}</div> */}
                    </div>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </>
        ) : (
          <div
            onClick={() =>
              setShowPopover({
                showPopover: false,
                event: undefined,
              })
            }
          >
            <IonGrid>
              {fileOptions.map((element, index) => (
                <IonRow
                  key={index}
                  onClick={() => element.onClick()}
                  className={
                    index !== fileOptions.length - 1
                      ? "ion-margin-bottom"
                      : undefined
                  }
                >
                  <IonCol size="3">
                    <img src={element.img} alt={element.label} />
                  </IonCol>
                  <IonCol offset="1">
                    <div>
                      <div className="title">{element.label}</div>
                      {/* <div className="subtitle">{element.description}</div> */}
                    </div>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </div>
        )}
      </IonPopover>
    </div>
  );
};

export default FileOptionsPopover;
