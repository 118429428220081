import { AxiosResponse } from 'axios';

import axios from '.';
import { getEmptyDocumentacion } from '../interfaces/DocumentacionExistente';

export const getDocumentacionExistente = async (
  url: string,
  siniestroId: string
) => {
  try {
    const { data: documentacion }: AxiosResponse<any> = await axios.get(url, {
      params: {
        siniestroId,
      },
    });
    const newDocumentacion = getEmptyDocumentacion();
    Object.assign(newDocumentacion, JSON.parse(documentacion));
    return newDocumentacion;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
