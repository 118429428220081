import React, { useState, useRef, useContext, useEffect } from 'react'
import { IonButton } from '@ionic/react';
import { HideHeaderContext } from '../../context/HideHeaderContext';
import './NestedSelector.css';
import { capitalizeEveryWord, capitalizeFirstLetter } from '../../utils/General';
import Cancel from "../../assets/cancel.png";
import ArcList from './ArcList';
import ArcItem from './ArcItem';

export type DataNode = {
  label: string;
  value?: any; // por si hace falta asociar a un id o similar
  options: DataNode[];
}

type NestedSelectorProps = {
  data: DataNode[];
  submitCallback: (dn: DataNode[]) => any;
  cancelCallback: (dn: DataNode[]) => any;
  title?: string;
  partial?: boolean;
}

type _LocalActions = 'ADD' | 'REMOVE';

const NestedSelector: React.FC<NestedSelectorProps> = ({ data, submitCallback, cancelCallback, title, partial = false }) => {

  const { setHideHeader } = useContext(HideHeaderContext);
  const [selection, setSelection] = useState<number[]>([]);
  const [actionPerformed, setActionPerformed] = useState<_LocalActions>('ADD');
  const savedBodyOverflow = useRef<string>(document.body.style.overflowY);
  // const LIST_ITEM_CLASS = "current-options-item"

  const ionContentMain = document.querySelector('ion-content')?.shadowRoot?.querySelector('main');
  const savedIonContentOverflow = useRef<string>(ionContentMain && ionContentMain.style ? ionContentMain.style.overflowY : 'auto');

  let currentOptions: DataNode[] = data;
  const selectionPath: DataNode[] = [];

  const addItem = (s: number) => {
    setActionPerformed('ADD');
    setSelection([...selection, s]);
  }

  const removeItem = (index: number) => {
    setActionPerformed('REMOVE');
    setSelection([...selection.slice(0, index)]);
  };

  selection.forEach((s) => {
    selectionPath.push(currentOptions[s]);
    currentOptions = currentOptions[s].options;
  });

  // const getColor: (index: number) => string = (index) => `rgba(${100 - (index * 30)},${index * 40},${200 - index / 2},1)`;
  const getColor: (index: number) => string = () => '#2dd36f';

  const disableGeneralScroll = () => {
    document.body.style.overflowY = 'hidden';
    if (ionContentMain) {
      ionContentMain.style.overflowY = 'hidden';
    }
  }

  const restoreGeneralScroll = () => {
    document.body.style.overflowY = savedBodyOverflow.current;
    if (ionContentMain) {
      ionContentMain.style.overflowY = savedIonContentOverflow.current;
    }
  }

  useEffect(() => {
    setHideHeader(true)
  }, []);

  const cleanUp = () => {
    restoreGeneralScroll();
    setHideHeader(false);
  }

  return (
    <div
      className='nestedSelector ion-padding'
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={disableGeneralScroll}
      onMouseLeave={restoreGeneralScroll}
    >
      {title &&
        <div className="categoria_title">
          {capitalizeEveryWord(title)}
        </div>
      }

      {currentOptions.length === 0 &&
        <div>
          <div className='confirmCaracteristica_title'>{capitalizeFirstLetter(selectionPath[selectionPath.length - 1].value)}</div>
          <div className='confirmCaracteristica_subtitle'> Presione un elemento para removerlo o bien aceptar para completar el proceso.</div>
        </div>
      }

      <div
        // className={actionPerformed === 'ADD' ? "selected-list arcItem_container" : "arcItem_container "}ç
        className={actionPerformed === 'ADD' ? " arcItem_container" : undefined}
      >
        {selectionPath.map((s, index) =>
          <ArcItem
            key={index}
            label={s.label}
            onClick={() => removeItem(index)}
            detailColor={getColor(index)}
            detailIcon={Cancel}
          />
        )}
      </div>

      {currentOptions.length > 0 &&
        <ArcList items={currentOptions} selectFunction={addItem} />
      }

      <div className='ion-padding-top'>
      {(partial || currentOptions.length === 0) &&
          <IonButton
            mode='ios'
            expand='block'
            onClick={() => {
              cleanUp();
              submitCallback(selectionPath);
            }}>
            Aceptar
          </IonButton>
        }

        <IonButton
          mode="ios"
          color='danger'
          fill='outline'
          expand='block'
          onClick={() => {
            cleanUp();
            cancelCallback(selectionPath);
          }}>
          Cancelar
        </IonButton>
      </div>
    </div>
  )
}

export default NestedSelector;
