import axiosInstance from '.';
import { REFRESH } from './constants';
import { AxiosResponse } from 'axios';
import { RefreshReq, RefreshRes } from '../interfaces/Refresh';

export const refresh = async ({
  refreshToken,
}: RefreshReq): Promise<{ ok: boolean; data?: RefreshRes }> => {
  try {
    const { data }: AxiosResponse<RefreshRes> = await axiosInstance.post(
      REFRESH,
      {
        refresh_token: refreshToken,
      }
    );
    return { ok: true, data };
  } catch (e) {
    console.error(e);
    return { ok: false };
  }
};
