import { useSubVetForm } from "../../context/SubVetFormContext";
import { VetContact, getEmptyVetContacts } from "../../interfaces/VetContact";
import { Indicacion } from "../../interfaces/forms/Indicacion";
import {
  submitSvf,
  findStepIndexByValue,
  stepsData,
  indicacionesGoBack,
  indicacionOptions,
} from "../../utils/Form";
import AddingSvfBtns from "../AddingSvfBtns";
import FormSelectPopover from "../FormSelectPopover";
import IndicacionGenericaForm from "../IndicacionGenericaForm";
import IndicacionMedForm from "../IndicacionMedForm";
import Title from "../Title";
import ToastMsg from "../ToastMsg";
import VetContactWrapper from "../VetContactWrapper";
import "./IndicacionesForm.scss";
import { IndicacionesFormProps } from "./types";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const IndicacionesForm: React.FC<IndicacionesFormProps> = ({
  formattedPracticas,
  formattedEstudios,
  prevSvf,
  setFormStep,
  setAddedOk,
  fechaConsulta,
  veterinario,
  index,
  showToast,
  setShowToast,
  setAddingSvfElement,
  goBack,
}) => {
  const { indicaciones, setIndicaciones } = useSubVetForm();
  const { handleSubmit, control, errors, watch, setValue } =
    useForm<Indicacion>();
  const formMethods = { control, errors, watch, setValue };
  const tipoIndicacion = watch("tipoIndicacion");

  const [suggestedVets, setSuggestedVets] =
    useState<VetContact[]>(getEmptyVetContacts);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const formattedFechaConsulta = moment(fechaConsulta).format("YYYY-MM-DD");

  const sharedProps = {
    indicaciones,
    prevSvf,
    tipoIndicacion,
    index,
    formMethods,
    formattedPracticas,
    formattedEstudios,
    formattedFechaConsulta,
  };

  const vetContact_wrapperProps = {
    indicaciones,
    index,
    tipoIndicacion,
    suggestedVets,
    setSuggestedVets,
    veterinario,
    popoverState,
    setShowPopover,
  };

  const switchForm = (str: string) => {
    switch (str) {
      case "Farmacológica":
        return <IndicacionMedForm {...sharedProps} />;
      case "Control":
      case "Estudio":
      case "Práctica":
      case "Cirugía":
      case "Internación":
      case "No farmacológica":
      case "Otros":
        return <IndicacionGenericaForm {...sharedProps} />;
      default:
        console.error(`switchFormIndicacion error`);
        return null;
    }
  };

  const onSubmit = (values: Indicacion) => {
    values.suggestedVets = suggestedVets;
    const res = submitSvf(
      index,
      indicaciones,
      setIndicaciones,
      values,
      setShowToast
    );

    if (prevSvf && res) {
      //Redirección cuando se agrega una indicación desde otro SVF
      indicacionesGoBack(prevSvf, setFormStep, setAddingSvfElement);
      //Toast de indicación agregada exitosamente desde otro SVF
      setAddedOk(true);
    }
  };

  useEffect(() => {
    const index = prevSvf && findStepIndexByValue(prevSvf);
    const step = stepsData[index!];
    step?.setValue && step.setValue(setValue);
  }, [prevSvf]);

  useEffect(() => {
    if (index !== undefined) {
      setValue("tipoIndicacion", indicaciones[index].tipoIndicacion);
    }
  }, [index]);

  return (
    <form
      onSubmit={!popoverState.showPopover ? handleSubmit(onSubmit) : undefined}
      className="indicaciones-form"
    >
      <IonGrid className="ion-no-padding">
        <Title
          title={index !== undefined ? "Editar indicación" : "Nueva indicación"}
        />

        <IonRow>
          {/* Tipo de indicación */}
          <IonCol>
            <FormSelectPopover
              disabled={prevSvf !== undefined}
              formMethods={formMethods}
              options={indicacionOptions}
              fieldProps={{
                name: "tipoIndicacion",
                label: "Tipo de indicación",
              }}
              validationProps={{
                errorMessage: "Por favor seleccione una indicación",
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow className="switchedForm">
          <IonCol>
            {tipoIndicacion && switchForm(tipoIndicacion)}
            <VetContactWrapper {...vetContact_wrapperProps} />
          </IonCol>
        </IonRow>

        <AddingSvfBtns
          label={index !== undefined ? "cambios" : "indicación"}
          action={() => {
            prevSvf === undefined && !indicaciones.length
              ? goBack()
              : indicacionesGoBack(prevSvf, setFormStep, setAddingSvfElement);
          }}
        />
      </IonGrid>

      <ToastMsg
        isOpen={showToast}
        setIsOpen={setShowToast}
        message={"Modifique la indicación para guardar cambios"}
        color={"warning"}
      />
    </form>
  );
};

export default IndicacionesForm;
