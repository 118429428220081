import {
  validationData,
  validationMoney,
} from "../../assets/validations/validationRegex";
import { useSubVetForm } from "../../context/SubVetFormContext";
import { Internacion } from "../../interfaces/forms/Internacion";
import {
  indicacionesMaxDate,
  indicacionesMinDate,
  today,
} from "../../utils/DateUtilities";
import { setFormValuesFromObject, submitSvf } from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import AddingSvfBtns from "../AddingSvfBtns";
import FormDate from "../FormDate";
import FormInput from "../FormInput";
import Title from "../Title";
import { InternacionesFormProps } from "./types";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const InternacionesForm: React.FC<InternacionesFormProps> = ({
  index,
  setAddingSvfElement,
  setShowToast,
}) => {
  const { internaciones, setInternaciones } = useSubVetForm();
  const { handleSubmit, control, errors, setValue, watch } =
    useForm<Internacion>();
  const formMethods = { control, errors };
  const fechaInicio = watch("fechaInicio");

  const onSubmit = (values: Internacion) => {
    submitSvf(index, internaciones, setInternaciones, values, setShowToast);
  };

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(internaciones[index], setValue);
    }
  }, [index]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonGrid className="ion-no-padding">
        <Title
          title={
            index !== undefined ? "Editar internación" : "Nueva internación"
          }
        />

        <IonRow>
          <IonCol>
            {/* Fecha Inicio */}
            <FormDate
              formMethods={formMethods}
              fieldProps={{
                name: "fechaInicio",
                label: "Fecha de inicio de la atención",
              }}
              validationProps={{
                errorMessage: "Por favor ingrese una fecha",
              }}
              date={{ max: today, min: indicacionesMinDate() }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {/* Fecha Fin */}
            <FormDate
              formMethods={formMethods}
              fieldProps={{
                name: "fechaFin",
                label: "Fecha de finalización la atención",
              }}
              validationProps={{
                errorMessage: "Por favor ingrese una fecha",
              }}
              date={{
                min: moment(fechaInicio).format("YYYY-MM-DD"),
                max: indicacionesMaxDate(),
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {/* Descripción */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "descripcion",
                label: "Descripción de la internación",
                format: capitalizeFirstLetter,
              }}
              validationProps={{
                errorMessage: "Por favor ingrese una descripción",
                pattern: validationData,
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {/* Costo */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "costo",
                label: "Costo de la internación",
                typeNumber: true,
              }}
              validationProps={{
                errorMessage: "Por favor ingrese un monto",
                pattern: validationMoney,
              }}
            />
          </IonCol>
        </IonRow>

        <AddingSvfBtns
          label={index !== undefined ? "cambios" : "internación"}
          action={() => {
            setAddingSvfElement(false);
          }}
        />
      </IonGrid>
    </form>
  );
};

export default InternacionesForm;
