import React from 'react';
import { Controller } from 'react-hook-form';

import { FormItemProps } from './types';

import './FormItem.scss';

const FormItem: React.FC<FormItemProps> = (props) => {
  const { errors, ...p } = props;
    const getErrors = () =>{
        /** si el error es en un nombre tipo 'foo.bar' el error viene en un objeto anidado con {foo:{bar: (ERROR) }}
         * queda pendiente meterle un poco mas de elegancia pero funciona
         */
        if (!errors) { return undefined;} 
        else {
            let nestedError = errors;  
            const namePath = p.name.split('.');
            for(let i=0;i < namePath.length; i++){
                let np = namePath[i];
                if (nestedError[np] !== undefined) {nestedError = nestedError[np]}
                else { return undefined; }
            };
            return nestedError;
        }
    }
  return (
    <div className={'form-item ' + (getErrors() ? 'has-error' : '')}>
      <Controller {...p} />
      <div
        className="form-item__error-message "
        style={getErrors() ? { opacity: 1 } : undefined}
      >
        {getErrors()?.message}
      </div>
    </div>
  );
};

export default FormItem;
