import Bin from "../../assets/bin.png";
import Pen from "../../assets/pen.png";
import Plus from "../../assets/plus.png";
import { VetContact } from "../../interfaces/VetContact";
import { deleteElement, getTypeLabelByType } from "../../utils/Form";
import Alert from "../Alert";
import ToastMsg from "../ToastMsg";
import VetContactForm from "../VetContactForm";
import "./VetContactWrapper.scss";
import { VetContactWrapperProps } from "./types";
import { IonIcon } from "@ionic/react";
import { call, mail, person } from "ionicons/icons";
import React, { useEffect, useState } from "react";

const VetContactWrapper: React.FC<VetContactWrapperProps> = ({
  indicaciones,
  index,
  tipoIndicacion,
  suggestedVets,
  setSuggestedVets,
  veterinario,
  popoverState,
  setShowPopover,
}) => {
  const [vetIndex, setVetIndex] = useState<number>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAlert1, setShowAlert1] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);

  const vetContactFormProps = {
    indicaciones,
    index,
    suggestedVets,
    setSuggestedVets,
    popoverState,
    setShowPopover,
    vetIndex,
    setVetIndex,
  };

  const addCurrentVet = () => {
    const { nombre, apellido, email, telefono } = veterinario;
    const currentVet: VetContact = {
      nombre: nombre,
      apellido: apellido,
      email: email,
      telefono: telefono,
    };
    setSuggestedVets([...suggestedVets, currentVet]);
  };

  useEffect(() => {
    if (index !== undefined) {
      setSuggestedVets(indicaciones[index].suggestedVets!);
    }
  }, [index]);

  return (
    <div className="vet-contact-wrapper">
      {/* //suggestedVets.length */}
      {suggestedVets.length !== 0 && (
        <div className="vet-contact-wrapper__newVet_box ion-margin-top">
          <div className="title">Veterinarios sugeridos</div>
          <div
            hidden={!tipoIndicacion || tipoIndicacion === "Farmacológica"}
            onClick={() =>
              setShowPopover({ showPopover: true, event: undefined })
            }
          >
            <img src={Plus} />
          </div>
        </div>
      )}

      <div>
        {tipoIndicacion &&
        tipoIndicacion !== "Farmacológica" &&
        !suggestedVets.length ? (
          // NO VET CARD
          <div
            className="vet-contact-wrapper__no-vet-added"
            onClick={() => setShowAlert1(true)}
          >
            <div>
              <div className="no-vet-added__title">
                ¿Desea indicar o sugerir un profesional para realizar{" "}
                {getTypeLabelByType(tipoIndicacion)}?
              </div>
              <div className="no-vet-added__subtitle">
                No hay profesionales sugeridos
              </div>
            </div>
            <div hidden={!tipoIndicacion || tipoIndicacion === "Farmacológica"}>
              <img src={Plus} />
            </div>
          </div>
        ) : (
          //ADDED VETS
          suggestedVets.map((vet, index) => (
            <div
              key={index}
              className="vet-contact-wrapper__suggestedVetCard ion-margin-top"
            >
              <div className="options-icons">
                <div
                  onClick={() => {
                    setVetIndex(index);
                    setShowAlert(true);
                  }}
                >
                  <img src={Bin} />
                </div>

                <div
                  onClick={() => {
                    setVetIndex(index);
                    setShowPopover({ showPopover: true, event: undefined });
                  }}
                >
                  <img src={Pen} />
                </div>
              </div>

              <div className="vetData_items">
                <div className="name">
                  <IonIcon icon={person} />{" "}
                  <span>
                    {vet.nombre} {vet.apellido}
                  </span>
                </div>
                <div>
                  <IonIcon icon={mail} /> <span>{vet.email}</span>
                </div>
                <div>
                  <IonIcon icon={call} /> <span>{vet.telefono}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <VetContactForm {...vetContactFormProps} />

      {/* Current vet u otro profesional */}
      <Alert
        isOpen={showAlert1}
        setIsOpen={setShowAlert1}
        header={"¿Que profesional sugiere?"}
        buttonsLabel={{
          primary: `${veterinario.nombre} ${veterinario.apellido}`,
          secondary: "Otro profesional",
        }}
        actions={{
          primary: () => {
            setShowAlert(false);
            addCurrentVet();
          },
          secondary: () => {
            setShowPopover({ showPopover: true, event: undefined });
          },
        }}
        didDismissActions={() => setVetIndex(undefined)}
      />

      {/* Delete */}
      <Alert
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        subHeader={"¿Desea eliminar el contacto de este veterinario?"}
        buttonsLabel={{
          primary: "Conservar",
          secondary: "Eliminar",
        }}
        actions={{
          primary: () => setShowAlert(false),
          secondary: () => {
            deleteElement(setSuggestedVets, vetIndex!);
            setShowToast(true);
          },
        }}
        didDismissActions={() => setVetIndex(undefined)}
        deleteStyle
      />

      <ToastMsg
        isOpen={showToast}
        setIsOpen={setShowToast}
        message={"Contacto eliminado"}
        color={"success"}
      />
    </div>
  );
};

export default VetContactWrapper;
