import { useState, useEffect } from "react";
import { getPracticas as axiosGetPracticas } from "../axios/getPracticas";
import { Practicas } from "../interfaces/Practicas";

export const usePracticas = () => {
  const [practicas, setPracticas] = useState<Practicas[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPracticas = async () => {
    setIsLoading(true);

    const practicas = await axiosGetPracticas();
    setPracticas(practicas);

    setIsLoading(false);
  };

  // IonSelect necesita array de strings
  const formattedPracticas = practicas.map((e) => e.tipo_practica);

  useEffect(() => {
    getPracticas();
  }, []);

  return { formattedPracticas, isLoading };
};
