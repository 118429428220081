import Checked from "../../assets/checked.png";
import Warning from "../../assets/warning2.png";
import { useMedia } from "../../context/MediaContext";
import { SintomasContext } from "../../context/SintomasContext";
import { useSubVetForm } from "../../context/SubVetFormContext";
import { TempSintoma } from "../../hooks/useSintomas";
import { Sintoma } from "../../interfaces/forms/Sintoma";
import {
  Option,
  checkAndResetMedia,
  createBase64ArrayFromMedia,
  fechasEstimadas,
  setFormValuesFromObject,
  submitSvf,
} from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import ProcessDataForSelector from "../../utils/SintomasToDataNodes";
import AddingSvfBtns from "../AddingSvfBtns";
import FileUploader from "../FileUploader";
import FormInput from "../FormInput";
import FormSelectOrCreate from "../FormSelectOrCreate";
import FormSelectPopover from "../FormSelectPopover";
import NestedSelector from "../NestedSelector/NestedSelector";
import Title from "../Title";
import "./Sintomas.scss";
import { SintomasFormProps } from "./types";
import { IonGrid, IonRow, IonCol, IonModal } from "@ionic/react";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useForm } from "react-hook-form";

const SintomasForm: React.FC<SintomasFormProps> = ({
  setAddingSvfElement,
  index,
  setShowToast,
  goBack,
}) => {
  const { sintomas, setSintomas } = useSubVetForm();
  const { searchableSintomas, isLoading } = useContext(SintomasContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
    register,
    unregister,
    getValues,
  } = useForm<Sintoma>();
  const { currentMedia, setCurrentMedia, setShowNoFileInCurrentMediaToast } =
    useMedia();
  const formMethods = { control, errors, setValue };
  const sintoma: string = watch("sintoma");
  const caracteristicaInput = useRef<HTMLInputElement>(null);

  const [selectedSintoma, setSelectedSintoma] = useState<
    TempSintoma | undefined
  >();
  const [showSubSelector, setShowSubSelector] = useState<number | undefined>(
    undefined
  );
  const [option, setOption] = useState<Option | null>();
  const dynamicFieldsIds = useRef<string[]>([]);

  const onSubmit = async (values: Sintoma) => {
    const caracteristicasSeleccionadas = {};
    let descripcion = "";

    dynamicFieldsIds.current.forEach((dfi, i) => {
      caracteristicasSeleccionadas[dfi] = values[dfi];
      descripcion += `${values[dfi]}${
        i < dynamicFieldsIds.current.length - 1 ? ", " : ""
      }`;
    });

    if (currentMedia) {
      const informe = await createBase64ArrayFromMedia(currentMedia);
      values.media = currentMedia;
      values.sintomaInforme = informe;
    }

    submitSvf(
      index,
      sintomas,
      setSintomas,
      { ...values, descripcion, caracteristicasSeleccionadas },
      setShowToast
    );
  };

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(sintomas[index], setValue);
      setOption({ label: sintomas[index].sintoma, value: "" });
      setCurrentMedia(sintomas[index].media!);
    }
  }, [index]);

  useEffect(() => {
    /* Si existen clasificaciones registradas en el form, son de un sintoma anterior, desregistrarlas. */
    if (dynamicFieldsIds.current.length > 0) {
      dynamicFieldsIds.current.map(unregister);
    }

    if (sintoma) {
      // @TODO innecesario y lento => cambiar las options que se arman en el FormSelectOrCreate para evitarlo
      const actualSintoma = (searchableSintomas as TempSintoma[]).find(
        (ss) =>
          ss.nombre.toLowerCase() === sintoma.toLowerCase() ||
          ss.label.toLowerCase() === sintoma.toLocaleLowerCase()
      );
      /* Guardado de los nombres de los campos para desregistrarlos del form si cambia el sintoma  */
      dynamicFieldsIds.current =
        actualSintoma?.clasificaciones.map((c) => c.grupo) ?? [];
      setSelectedSintoma(actualSintoma);
    } else {
      setSelectedSintoma(undefined);
      setShowSubSelector(undefined);
    }
  }, [sintoma]);

  useEffect(() => {
    if (
      typeof index === "number" &&
      sintomas[index].caracteristicasSeleccionadas !== undefined
    ) {
      Object.entries(sintomas[index].caracteristicasSeleccionadas!).map(
        ([k, v]) => {
          console.log("setting", k, v);
          setValue(k, v);
        }
      );
    }
  }, [selectedSintoma]);

  const _getCsValue = (key) =>
    typeof index === "number" ? sintomas[index][key] : undefined;

  // const onClick = () => {
  //   if (caracteristicaInput.current) {
  //     caracteristicaInput.current.click();
  //   }
  // };

  //Reset currentMedia cuando hay media cargada con un tipo de estudio especifico
  useEffect(() => {
    checkAndResetMedia(
      sintoma,
      currentMedia,
      setCurrentMedia,
      setShowNoFileInCurrentMediaToast
    );
  }, [sintoma, currentMedia]);

  return (
    <div className="sintomas">
      {sintoma &&
        typeof showSubSelector === "number" &&
        selectedSintoma &&
        selectedSintoma.clasificaciones &&
        selectedSintoma.clasificaciones.length && (
          <IonModal isOpen={showModal} cssClass="my-modal-custom-class">
            <NestedSelector
              data={ProcessDataForSelector(selectedSintoma, showSubSelector)}
              title={selectedSintoma.clasificaciones[showSubSelector].grupo}
              submitCallback={(val) => {
                // @TODO seria bueno agregar un prefijo a este id
                const element = document.getElementById(
                  selectedSintoma.clasificaciones[showSubSelector].grupo
                );
                if (element) {
                  (element as HTMLInputElement).value =
                    val[val.length - 1].value;
                }
                setValue(
                  selectedSintoma.clasificaciones[showSubSelector].grupo,
                  val[val.length - 1].value
                );
                setShowSubSelector(undefined);
              }}
              cancelCallback={(_) => {
                setShowSubSelector(undefined);
              }}
            />
          </IonModal>
        )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ion-no-padding">
          <Title
            title={index !== undefined ? "Editar síntoma" : "Nuevo síntoma"}
          />

          <IonRow>
            <IonCol>
              <FormSelectOrCreate
                creatable
                options={searchableSintomas as Option[]}
                option={option}
                isLoading={isLoading}
                formMethods={formMethods}
                fieldProps={{
                  name: "sintoma",
                  label: "Puede buscar por nombre del síntoma",
                  placeholder: "Seleccione un síntoma...",
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese un síntoma",
                }}
              />
            </IonCol>
          </IonRow>

          <div className="ion-margin-top">
            {selectedSintoma && (
              <>
                {selectedSintoma.clasificaciones.map((c, index) => (
                  <div key={c.grupo}>
                    <div
                      ref={caracteristicaInput}
                      id={c.grupo}
                      className={
                        getValues(c.grupo) ? "completedInput" : "pendingInput"
                      }
                      onClick={() => {
                        setShowModal(true);
                        setShowSubSelector(index);
                      }}
                    >
                      <input
                        type="hidden"
                        name={c.grupo}
                        defaultValue={_getCsValue(c.grupo)}
                        // @ts-ignore
                        {...register(c.grupo, { required: false })}
                      />
                      <span>
                        {getValues(c.grupo)
                          ? capitalizeFirstLetter(getValues(c.grupo))
                          : `Completar ${c.grupo}`}
                      </span>
                      <div>
                        {getValues(c.grupo) ? (
                          <img src={Checked} />
                        ) : (
                          <img src={Warning} />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <IonRow>
            {/* <IonCol hidden> */}
            {/* Fecha estimada Id*/}
            {/* <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "fechaEstimadaId",
                  label: "",
                }}
              />
            </IonCol> */}

            <IonCol className="fix-negativeMargin">
              {/* Fecha estimada */}
              <FormSelectPopover
                formMethods={formMethods}
                options={fechasEstimadas}
                fieldProps={{
                  name: "fechaEstimada",
                  label: "Fecha estimada de origen de síntoma",
                }}
                validationProps={{
                  errorMessage: "Por favor seleccione una fecha estimada",
                }}
                // noBackground
              />
            </IonCol>
          </IonRow>

          <FileUploader
            tag={{ type: "sintoma", detail: sintoma! }}
            disabled={!sintoma && index === undefined}
          />

          <AddingSvfBtns
            label={index !== undefined ? "cambios" : "síntomas"}
            action={() => {
              setOption(null);
              !sintomas.length ? goBack() : setAddingSvfElement(false);
            }}
          />
        </IonGrid>
      </form>
    </div>
  );
};

export default SintomasForm;
