interface Mascota {
    id: number;
    codigo: number;
    nombre: string;
    raza: string; 
    animal: string; 
    sexo: string; 
    color: string; 
    fecha_nacimiento: Date; 
    descripcion: string; 
    datosPersonaId: number;
    createdAt: Date; 
    updatedAt: Date; 
}

interface Persona {
    id: number; 
    dni: number; 
    nombre: string; 
    apellido: string; 
    fecha_nacimiento: Date;
    genero: string; 
    tel_fijo: string; 
    tel_celular: string; 
    createdAt: Date; 
    updatedAt: Date; 
}

interface Domicilio{
    id: number;
    direccion: string;
    numero: string;
    piso: string | null;
    departamento: string | null;
    createdAt: Date;
    updatedAt: Date;
    localidadId: number;
    datosPersonaId: number;
    localidad: string;
}

export interface DatosUsuario {
    mascota: Mascota;
    persona: Persona;
    domicilio: Domicilio;
    denuncia_administrativa: string;
}

export const getEmptyDatosUsuario = () => ({
    mascota: {
        id: 0,
        codigo: 0,
        nombre: "",
        raza: "",
        animal: "",
        sexo: "",
        color: "",
        fecha_nacimiento: new Date(),
        descripcion: "",
        datosPersonaId: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    persona: {
        id: 0,
        dni: 0,
        nombre: "",
        apellido: "",
        fecha_nacimiento: new Date(),
        genero: "",
        tel_fijo: "",
        tel_celular: "",
        createdAt: new Date(),
        updatedAt: new Date(),
    },
    domicilio: {
        id: 0,
        direccion: "",
        numero: "",
        piso: null,
        departamento: null,
        createdAt: new Date(),
        updatedAt: new Date(),
        localidadId: 0,
        datosPersonaId: 0,
        localidad: "",
    },
    denuncia_administrativa: ""
});
