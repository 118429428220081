import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
} from "@ionic/react";
import "./HomePage.scss";
import Logo from "../../assets/FielPetLogo.png";
import InfoLayout from "../../components/InfoLayout";

const HomePage: React.FC = () => {
  return (
    <IonPage className="home-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent className="ion-padding">
        <InfoLayout title={"FielVet, software para gestion veterinaria"} />
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
