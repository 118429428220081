import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { Medicamento } from '../interfaces/forms/Medicamento';
import { GET_MEDICAMENTOS } from './constants';

export const getMedicamentos = async () => {
  try {
    const { data: medicamento }: AxiosResponse<Medicamento[]> = await axiosInstance.get(
      GET_MEDICAMENTOS
    );
    return medicamento;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
