import moment from "moment";

/** Creates a new date with the years/month/days of the first and the hours of the second */
export const concatDateAndHours = (fecha: string, hour: string): string => {
  return fecha.slice(0, 11) + hour.slice(11);
};

/** Gets the current date in format ISO String */
export const today = moment().toISOString();

/** Returns the max date available for the IonDateTime.
 *  If the event is done, returns today, in other case today plus 2 years*/
export const maxDateEvent = (r: boolean) => {
  if (r) {
    return today;
  } else {
    return moment().add(2, "years").toISOString();
  }
};

/** Returns the min date available for the IonDateTime.
 *  If the event is done, returns today minus 15 years, in other case returns today */
export const minDateEvent = (r: boolean) => {
  if (r) {
    return moment().subtract(15, "years").toISOString();
  } else {
    return today;
  }
};

/** Returns the minimum age of birth that a pet must have to get a assurance, in this case 3 months*/
export const maxDateNacPet = moment().subtract(3, "months").toISOString();

/** Returns the maximum age that a pet must have to get a assurance, in this case 9 years*/
export const minDateNacPet = moment().subtract(9, "years").toISOString();

/** Returns the minimum age that a person must have to get an assurance for his pet, in this case 18 years */
export const mustBeAdult = moment().subtract(18, "years").toISOString();

/** Returns the min date available to use a credit card, in this case 1 month from now  */
export const minDateCreditCard = moment().add(1, "month").toISOString();

/** Returns the max date available to use a credit card, in this case 20 years from now  */
export const maxDateCreditCard = moment().add(20, "years").toISOString();

/** From a date in string format, returns day-month-year*/
export const getDate = (d: string | undefined) => {
  return moment.utc(d).format("DD-MM-YYYY");
};

/** From a date in string format, returns hour:minutes*/
export const getHour = (d: string | undefined) => {
  return moment.utc(d).format("HH:mm");
};

/** Gets two strings that are dates and returns true if there is a diference between their months or years */
export const differenceBetw = (date1: string, date2: string) => {
  const diffMonth = +moment(date1).format("MM") - +moment(date2).format("MM");
  const diffYear =
    +moment(date1).format("YYYY") - +moment(date2).format("YYYY");
  return diffMonth != 0 || diffYear != 0;
};

/** Gets two strings that represents dates (F1, F2) and returns true if F1 > F2 */
export const sortDates = (date1: string, date2: string) => {
  const dateA = moment(date1);
  const dateB = moment(date2);
  if (dateA > dateB) {
    return true;
  } else {
    return false;
  }
};

export const getPetAge = (fechaNacimiento) => {
  const okDate = moment(fechaNacimiento, "YYYY-MM-DD");
  const today = moment();

  const años = today.diff(okDate, "years");
  okDate.add(años, "years");

  const meses = today.diff(okDate, "months");

  if (años === 0) {
    if (meses === 1) {
      return `1 mes`;
    } else {
      return `${meses} meses`;
    }
  } else {
    if (meses === 1) {
      return `${años} años y 1 mes`;
    } else {
      return `${años} años y ${meses} meses`;
    }
  }
};

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const indicacionesMaxDate = () => {
  const hoy = new Date();
  const fechaMaxima = new Date(
    hoy.getFullYear() + 2,
    hoy.getMonth(),
    hoy.getDate()
  );
  const maxDate = fechaMaxima.toISOString().split("T")[0];
  return maxDate;
};

export const indicacionesMinDate = () => {
  const hoy = new Date();
  const fechaMinima = new Date(
    hoy.getFullYear() - 2,
    hoy.getMonth(),
    hoy.getDate()
  );
  const minDate = fechaMinima.toISOString().split("T")[0];
  return minDate;
};
