export type Documentacion = {
  dniFrente: object | null;
  dniDorso: object | null;
  libretaVacunacion: object | null;
  cbu: object | null;
  terceroCbu: object | null;
  constancia: object | null;
};

const emptyDocumentacion = {
  dniFrente: null,
  dniDorso: null,
  libretaVacunacion: null,
  cbu: null,
  terceroCbu: null,
  constancia: null,
};

export const getEmptyDocumentacion: () => Documentacion = () => {
  return JSON.parse(JSON.stringify(emptyDocumentacion));
};
