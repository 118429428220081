import { useMedia } from "../../context/MediaContext";
import FileOptionsPopover from "../FileOptionsPopover";
import ToastMsg from "../ToastMsg";
import "./FileUploader.scss";
import { FileUploaderProps } from "./types";
import { IonCol, IonIcon, IonItem, IonRow } from "@ionic/react";
import { attachSharp, camera } from "ionicons/icons";
import React, { useRef, useState } from "react";

const FileUploader: React.FC<FileUploaderProps> = ({ tag, disabled }) => {
  const {
    handlePDF,
    currentMedia,
    setShowMediaState,
    showDuplicateInCurrentMediaToast,
    setShowDuplicateInCurrentMediaToast,
    showNoFileInCurrentMediaToast,
    setShowNoFileInCurrentMediaToast,
  } = useMedia();
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showToast1, setShowToast1] = useState<boolean>(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    if (file) {
      handlePDF(event, tag, setShowToast1);
      // Reset the input value to allow the same file to be uploaded again
      event.target.value = null;
    }
  };

  const onClick = () => {
    disabled
      ? setShowToast(true)
      : setShowPopover((prevState) => ({
          ...prevState,
          showPopover: true,
          event: undefined,
        }));
  };

  return (
    <div className="file-uploader">
      <IonItem className="ion-no-padding">
        <IonRow className="file-uploader__container">
          <div className="file-uploader__information">
            <div
              onClick={onClick}
              className={currentMedia.length ? "small" : undefined}
            >
              Imagen o PDF (Opcional)
            </div>
            <div
              hidden={!currentMedia.length}
              onClick={() => setShowMediaState("gallery")}
              className="file-uploader__currentMedia"
            >
              <IonIcon icon={attachSharp} />
              {currentMedia.length === 1
                ? "1 elemento adjunto"
                : `${currentMedia.length} elementos adjuntos`}
            </div>
          </div>
          <IonCol onClick={onClick} className="file-uploader__iconBox">
            <IonIcon
              icon={camera}
              color="light"
              className={disabled ? "file-uploader__iconDisabled" : undefined}
            />
          </IonCol>
        </IonRow>
      </IonItem>

      <input
        hidden
        type="file"
        ref={fileInputRef}
        onChange={handleFileInput}
        accept=".pdf, application/pdf"
      />

      <FileOptionsPopover
        popoverState={popoverState}
        setShowPopover={setShowPopover}
        tag={tag}
        handleFileInput={() => fileInputRef.current?.click()}
      />

      <ToastMsg
        isOpen={showToast}
        setIsOpen={setShowToast}
        message={`Ups... por favor seleccione un tipo de ${tag.type}.`}
        color={"warning"}
      />

      <ToastMsg
        isOpen={showToast1}
        setIsOpen={setShowToast1}
        message={`Ups... El archivo debe ser un pdf de hasta 4 mb.`}
        color={"warning"}
      />

      <ToastMsg
        isOpen={showNoFileInCurrentMediaToast}
        setIsOpen={setShowNoFileInCurrentMediaToast}
        message={`No hay archivos adjuntos`}
        color={"warning"}
      />

      <ToastMsg
        isOpen={showDuplicateInCurrentMediaToast}
        setIsOpen={setShowDuplicateInCurrentMediaToast}
        message={`Usted ya adjunto este archivo previamente.`}
        color={"warning"}
      />
    </div>
  );
};

export default FileUploader;
