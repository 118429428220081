import { usePDF } from "../hooks/usePDF";
import { usePhoto } from "../hooks/usePhoto";
import { PDFItem } from "../interfaces/PDFItem";
import { PhotoItem } from "../interfaces/PhotoItem";
import { useSubVetForm } from "./SubVetFormContext";
import { Directory, Filesystem } from "@capacitor/filesystem";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface MediaContextType {
  takePhoto: (
    source: string,
    tag: {
      type: string;
      detail: string;
    }
  ) => Promise<void>;
  deletePhoto: (photoId: string) => void;
  clearPhotos: () => void;
  photos: PhotoItem[];
  setPhotos: React.Dispatch<React.SetStateAction<PhotoItem[]>>;
  handlePDF: (
    event: React.ChangeEvent<HTMLInputElement>,
    tag: {
      type: string;
      detail: string;
    },
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  deletePDF: (fileName: string) => Promise<void>;
  clearPDFs: () => Promise<void>;
  tempFile: PhotoItem | PDFItem | undefined;
  setTempFile: React.Dispatch<
    React.SetStateAction<PhotoItem | PDFItem | undefined>
  >;
  confirmFile: boolean;
  setConfirmFile: React.Dispatch<React.SetStateAction<boolean>>;
  currentMedia: PhotoItem[];
  setCurrentMedia: React.Dispatch<React.SetStateAction<PhotoItem[]>>;
  deleteCurrentMediaFile: (fileName: string) => Promise<void>;
  showMediaState: ShowMediaState | undefined;
  setShowMediaState: React.Dispatch<
    React.SetStateAction<ShowMediaState | undefined>
  >;
  showDuplicateInCurrentMediaToast: boolean;
  setShowDuplicateInCurrentMediaToast: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  showNoFileInCurrentMediaToast: boolean;
  setShowNoFileInCurrentMediaToast: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}
const MediaContext = createContext<MediaContextType | undefined>(undefined);

type MediaProviderProps = {
  children: ReactNode;
};

type ShowMediaState = "tempFile" | "gallery";

const MediaProvider: React.FC<MediaProviderProps> = ({ children }) => {
  const { svfsWithMedia } = useSubVetForm();
  const [photos, setPhotos] = useState<PhotoItem[]>([]);
  const [PDFs, setPDFs] = useState<PDFItem[]>([]);
  const [tempFile, setTempFile] = useState<PhotoItem | PDFItem>();
  const [confirmFile, setConfirmFile] = useState<boolean>(false);
  const [currentMedia, setCurrentMedia] = useState<PhotoItem[]>([]);
  const [showMediaState, setShowMediaState] = useState<
    ShowMediaState | undefined
  >();
  const [showNoFileInCurrentMediaToast, setShowNoFileInCurrentMediaToast] =
    useState<boolean>(false);
  const [
    showDuplicateInCurrentMediaToast,
    setShowDuplicateInCurrentMediaToast,
  ] = useState<boolean>(false);

  const { takePhoto, deletePhoto, clearPhotos } = usePhoto({
    photos,
    setPhotos,
    setTempFile,
    currentMedia,
    setShowDuplicateInCurrentMediaToast,
  });

  const { handlePDF, deletePDF, clearPDFs } = usePDF({
    PDFs,
    setPDFs,
    setTempFile,
    currentMedia,
    setShowDuplicateInCurrentMediaToast,
  });

  const deleteCurrentMediaFile = async (fileName: string) => {
    setCurrentMedia(
      currentMedia.filter((element) => element.fileName !== fileName)
    );

    const hasJpegFile: boolean = fileName.endsWith(".jpeg");

    hasJpegFile ? deletePhoto(fileName) : deletePDF(fileName);

    await Filesystem.deleteFile({
      path: fileName,
      directory: Directory.Data,
    });
  };

  // Add file to currentMedia
  useEffect(() => {
    if (tempFile && confirmFile) {
      setCurrentMedia((prevAddedMedia) => [...prevAddedMedia, tempFile]);
    }
    setTempFile(undefined);
    setConfirmFile(false);
  }, [confirmFile]);

  // Add file to device
  useEffect(() => {
    currentMedia.forEach((element) => {
      if (element.fileName.endsWith(".jpeg")) {
        setPhotos((photos) => {
          // Verifica si el elemento ya existe en el array
          if (!photos.some((photo) => photo.fileName === element.fileName)) {
            return [...photos, element];
          }
          // No añadir si ya existe
          return photos;
        });
      } else if (element.fileName.endsWith(".pdf")) {
        setPDFs((PDFs) => {
          if (!PDFs.some((pdf) => pdf.fileName === element.fileName)) {
            return [...PDFs, element];
          }
          return PDFs;
        });
      }
    });

    // Limpia currentMedia después de procesar los elementos
    setCurrentMedia([]);
  }, [svfsWithMedia]);

  return (
    <MediaContext.Provider
      value={{
        takePhoto,
        deletePhoto,
        clearPhotos,
        photos,
        setPhotos,
        handlePDF,
        deletePDF,
        clearPDFs,
        tempFile,
        setTempFile,
        confirmFile,
        setConfirmFile,
        currentMedia,
        setCurrentMedia,
        deleteCurrentMediaFile,
        showMediaState,
        setShowMediaState,
        showDuplicateInCurrentMediaToast,
        setShowDuplicateInCurrentMediaToast,
        showNoFileInCurrentMediaToast,
        setShowNoFileInCurrentMediaToast,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};

const useMedia = (): MediaContextType => {
  const context = useContext(MediaContext);
  if (context === undefined) {
    throw new Error("useMedia must be used within a MediaProvider");
  }
  return context;
};

export { MediaProvider, useMedia };
