import {TempSintoma} from '../hooks/useSintomas';
import {DataNode} from '../components/NestedSelector/NestedSelector';

const processDataForSubselectors =(sintoma:TempSintoma,subSelector:number|undefined): DataNode[]  => {
  console.log(sintoma);
  if (! sintoma ||  typeof subSelector !== 'number') return [];

  const result = new Map();
  let currentlyPointingAt = result;
  sintoma.clasificaciones[subSelector].descripciones.forEach((e)=>{
    if (e.selectores.length === 0) { // @TODO caso sin selectores, se puede evitar mejorando la logica 
      currentlyPointingAt.set(e.valor, {label:e.valor,value:e.valor, options: new Map()});
    }
    e.selectores.forEach((s,i)=>{
      if (!currentlyPointingAt.get(s)) {
        currentlyPointingAt.set(s,{label: s, value:(i === (e.selectores.length - 1) ? e.valor :  s), options: new Map()});
      }
      currentlyPointingAt = currentlyPointingAt.get(s).options;
    });
    currentlyPointingAt = result;
  });

  type recursiveMapType = Map<string, {label:string, value:string, options:recursiveMapType}>;

  const mapToDataNode = (map: recursiveMapType) : DataNode[]=> {
    if ( map.size === 0 ) { return [] }
    const dataNode : DataNode[] = [];
    map.forEach((e)=>{
      dataNode.push({label: e.label, value: e.value, options: mapToDataNode(e.options)});
    });
    return dataNode;
  };
  return mapToDataNode(result);
}
export default processDataForSubselectors;
