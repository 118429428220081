import { PDFItem } from "../interfaces/PDFItem";
import { removeAccents, removeSpaces } from "../utils/General";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Preferences } from "@capacitor/preferences";
import { isPlatform } from "@ionic/react";
import { useEffect } from "react";

const FILES_PREF_KEY = "pdfs";

interface UsePDF_Props {
  PDFs: PDFItem[];
  setPDFs: React.Dispatch<React.SetStateAction<PDFItem[]>>;
  setTempFile: React.Dispatch<React.SetStateAction<PDFItem | undefined>>;
  currentMedia: PDFItem[];
  setShowDuplicateInCurrentMediaToast: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const usePDF = ({
  PDFs,
  setPDFs,
  setTempFile,
  currentMedia,
  setShowDuplicateInCurrentMediaToast,
}: UsePDF_Props) => {
  useEffect(() => {
    const loadSaved = async () => {
      const { value } = await Preferences.get({ key: FILES_PREF_KEY });
      const PDFsInPref: PDFItem[] = value ? JSON.parse(value) : [];

      if (!isPlatform("hybrid")) {
        for (let pdf of PDFsInPref) {
          try {
            // Verificar si el archivo existe
            const fileExists = await Filesystem.stat({
              path: pdf.fileName,
              directory: Directory.Data,
            });

            if (fileExists && fileExists.type === "file") {
              // Si el archivo existe, leerlo
              const file = await Filesystem.readFile({
                path: pdf.fileName,
                directory: Directory.Data,
              });
              pdf.webviewPath = `data:application/pdf;base64,${file.data}`;
            } else {
              console.error(`El archivo ${pdf.fileName} no existe.`);
            }
          } catch (error) {
            console.error(
              `Error al verificar el archivo ${pdf.fileName}:`,
              error
            );
          }
        }
      }

      setPDFs(PDFsInPref);
    };

    loadSaved();
  }, []);

  useEffect(() => {
    if (PDFs.length > 0) {
      Preferences.set({ key: FILES_PREF_KEY, value: JSON.stringify(PDFs) });
    }
  }, [PDFs]);

  const handlePDF = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tag: {
      type: string;
      detail: string;
    },
    setShowToast: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    try {;
      const file = event.target.files && event.target.files[0];

      //File size validation
      if (
        !file ||
        file.size > 4 * 1024 * 1024 ||
        file.type !== "application/pdf"
      ) {
        setShowToast(true);
        return;
      }

      // Generar nombre de archivo único
      const generateUniqueFileName = () => {
        let formattedFileName = removeSpaces(
          removeAccents(tag.detail)
        ).toLowerCase();
        let baseFileName = formattedFileName;
        let fileName = `${formattedFileName}_pdf.pdf`;
        let fileNumber = 1;

        // Verificar si el nombre de archivo ya existe en currentMedia
        while (currentMedia.some((media) => media.fileName === fileName)) {
          formattedFileName = `${baseFileName}${fileNumber}`;
          fileName = `${formattedFileName}_pdf.pdf`;
          fileNumber++;
        }

        return fileName;
      };

      const fileName = generateUniqueFileName();

      const savedPDF: PDFItem | undefined = await savePDF(file, fileName, tag);

      savedPDF && setTempFile(savedPDF);
    } catch (error) {
      console.error("Error al seleccionar el PDF: ", error);
    }
  };

  const savePDF = async (
    file: File,
    fileName: string,
    tag: {
      type: string;
      detail: string;
    }
  ): Promise<PDFItem | undefined> => {
    const base64data = await base64FromPath(file);

    // Verificar duplicado
    const id: string = idFromBase64(base64data);
    // const foundedId: boolean = currentMedia.some(
    //   (element) => element.id === id
    // );

    // if (foundedId) {
    //   setShowDuplicateInCurrentMediaToast(true);
    //   return;
    // }

    const formattedTag = {
      ...tag,
      type: removeAccents(tag.type),
    };

    const savedPDF = await Filesystem.writeFile({
      path: fileName,
      data: base64data,
      directory: Directory.Data,
      // Se crea el directorio si no existe
      recursive: true,
    });

    if (isPlatform("hybrid")) {
      return {
        id: id,
        fileName: savedPDF.uri,
        webviewPath: Capacitor.convertFileSrc(savedPDF.uri),
        tag: formattedTag,
      };
    }

    return {
      id: id,
      fileName: fileName,
      webviewPath: base64ToWebViewPath(base64data),
      tag: formattedTag,
    };
  };

  const base64FromPath = async (file: File): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error reading file."));
      };

      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          reject(new Error("Expected file result to be a string."));
        } else {
          resolve(reader.result as string);
        }
      };
    });
  };

  const base64ToWebViewPath = (base64: string): string => {
    // Remover el prefijo 'data:application/pdf;base64,' de la cadena base64.
    const base64Data = base64.split(",")[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      // Asignar el valor del carácter binario en cada posición del array.
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    // Crear un Blob a partir del array de bytes. El tipo MIME especifica que es un archivo PDF.
    const blob = new Blob([byteArray], { type: "application/pdf" });
    // Crear una URL que represente el Blob. Esta URL puede ser utilizada para visualizar el PDF en un <iframe> o una <object>.
    return URL.createObjectURL(blob);
  };

  // DEV
  // Genera id único segun contenido de imagen para evitar duplicados en un mismo elemento de un SVF
  const crypto = require("crypto");
  const idFromBase64 = (base64String: string): string => {
    // Decodificar el string base64 a un buffer
    const buffer = Buffer.from(base64String, "base64");

    // Generar un hash usando SHA-256
    const hash = crypto.createHash("sha256").update(buffer).digest("base64");

    // Convertir el hash a base64url-safe
    const base64url = hash
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");

    return base64url.substring(0, 10);
  };
  // END DEV

  const deletePDF = async (fileName: string) => {
    setPDFs(PDFs.filter((pdf) => pdf.fileName !== fileName));

    await Filesystem.deleteFile({
      path: fileName,
      directory: Directory.Data,
    });
  };

  const clearPDFs = async () => {
    setPDFs([]);
    await Preferences.remove({ key: FILES_PREF_KEY });
  };

  return {
    handlePDF,
    deletePDF,
    clearPDFs,
  };
};
