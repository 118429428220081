export type Sintoma = {
  sintomaId: string;
  sintoma: string;
  descripcion: string;
  caracteristicasSeleccionadas?: string[];
  fechaEstimada?: string;
  fechaEstimadaId: string;
};

export const emptySintoma: Sintoma = {
  sintomaId: "",
  sintoma: "",
  descripcion: "",
  caracteristicasSeleccionadas: [],
  fechaEstimada: "",
  fechaEstimadaId: "",
};

export const getEmptySintomas: Sintoma[] = [];

export function getEmptySintoma(): Sintoma {
  return JSON.parse(JSON.stringify(emptySintoma));
}
