import React, { createContext } from 'react';
import { Sintoma } from '../interfaces/Sintoma';
import { useSintomas, TempSintoma } from '../hooks/useSintomas';


type SintomasContextType = { searchableSintomas: TempSintoma[], sintomas: Sintoma[], isLoading: boolean };

export const SintomasContext: React.Context<SintomasContextType> = createContext<SintomasContextType>({ searchableSintomas: [], sintomas: [], isLoading: false });

export const SintomasContextProvider: React.FC = ({ children }) => {

  return (
    <SintomasContext.Provider value={useSintomas()}>
      {children}
    </SintomasContext.Provider>
  );
}
