import { useCallback, useEffect, useState } from 'react';
import { useStorage } from './useStorage';

export const useTutorialStorage = () => {
  const [tutorialValue, setTutorialValue] = useState<boolean | undefined>();

  const { setValue, getValue } = useStorage();

  const setTutorialStatus = useCallback(
    (value: boolean | undefined) => {
      setValue('TOUR_GENERAL_DONE', value ? value.toString() : 'false');
    },
    [setValue]
  );

  useEffect(() => {
    getValue('TOUR_GENERAL_DONE').then((value) => {
      setTutorialValue(value ? JSON.parse(value) : false);
    });
  }, [getValue, setTutorialValue]);
  return { tutorialStatus: tutorialValue, setTutorialStatus };
};
