import React, { useState } from "react";
import { useHistory } from "react-router";
import { IonButton, IonContent, IonHeader, IonPage } from "@ionic/react";
import Logo from '../../assets/FielPetLogo.png';
import { useVetFormLinkData } from "../../hooks/useVetFormLinkData";
import { Veterinario } from "../../interfaces/Veterinario";
import { VetFormLinkDataAddFormType } from "../../interfaces/forms/VetFormLinkData";
import "./FiestaPage.scss";

const FiestaPage: React.FC = () => {
  const history = useHistory();

  const [failed, setFailed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { vetFormLinkDataToSend } = useVetFormLinkData();
  const vetFormLinkData_toSend: Veterinario = {
    id: "1",
    nombre: "Vete",
    apellido: "Prueba",
    colegio: "CABA",
    matricula: "123",
    mail: "vetePrueba@dawer.com",
    telefono: "3416123456",
    domicilio: "",
    tipo: "",
  };
  const vetFormLinkDataObj: VetFormLinkDataAddFormType = {
    veterinario: vetFormLinkData_toSend,
    derivaciones: [],
    siniestroId: "1",
    fechaAtencion: "2023-11-21T01:32:08.662-03:00",
  };

  const goToForm = async () => {
    console.log("entre aca");
    const ok = await vetFormLinkDataToSend(vetFormLinkDataObj);
    console.log(ok["data"]);
    if (ok) {
      setFailed(false);
      setLoading(true);
      //SET TIME OUT DE 3 SEGUNDOS SINO LEVANTA ID DE FORM
      history.push(`/form?ref=${ok["data"]}`);
    } else {
      setFailed(true);
    }
  };

  return (
    <IonPage className="error-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent className="fiestaDrovet">
        <div className="ion-padding">
          <p className="title">Esperamos que estés disfrutando del evento.</p>

          <div className="description-box">
            <p className="description">
              Completá esta historia clínica con un posible caso para participar
              de los sorteos.
            </p>
          </div>
          <IonButton
            mode="ios"
            expand="block"
            onClick={goToForm}
            disabled={loading}
          >
            Completar el formulario
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FiestaPage;
