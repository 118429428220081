import { PhotoItem } from "../PhotoItem";

export type Estudio = {
  estudio?: string;
  estudioId: string;
  descripcion: string;
  costo: string
  media?: PhotoItem[];
  estudioInforme?: string[];
};

export const getEmptyEstudios: Estudio[] = [];