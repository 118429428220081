import Add from "../../assets/add-button.png";
import Vet from "../../assets/veterinary.png";
import { findStepIndexByValue, stepsData } from "../../utils/Form";
import Continue from "../Continue";
import "./RegularOrIndicacion.scss";
import { RegularOrIndicacionProps } from "./types";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";

const RegularOrIndicacion: React.FC<RegularOrIndicacionProps> = ({
  addingSvfElement,
  setAddingSvfElement,
  setPrevSvf,
  setShowSvfOptions,
  currentSVF,
  regularLabel,
  indicacionLabel,
  goForward,
}) => {
  return (
    <div className="regular-or-indicacion">
      <IonGrid>
        <div className="ion-padding-bottom">
          <IonRow
            onClick={() => {
              setShowSvfOptions(false);
              setAddingSvfElement(true);
            }}
          >
            <IonCol size="4">
              <img
                src={stepsData[findStepIndexByValue(currentSVF)!].icon ?? Vet}
              />
            </IonCol>
            <IonCol>
              <div className="label">{regularLabel}</div>
            </IonCol>
            <IonIcon icon={add} />
          </IonRow>

          <IonRow
            onClick={() => {
              setPrevSvf(currentSVF);
            }}
          >
            <IonCol size="4">
              <img src={Add} />
            </IonCol>
            <IonCol>
              <div className="label">{indicacionLabel}</div>
            </IonCol>
            <IonIcon icon={add} />
          </IonRow>
        </div>

        <Continue hidden={addingSvfElement} modifyStep={goForward} />
      </IonGrid>
    </div>
  );
};

export default RegularOrIndicacion;
