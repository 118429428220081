import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { Estudios } from '../interfaces/Estudios';
import { GET_ESTUDIOS } from './constants';

export const getEstudios = async () => {
  try {
    const { data: estudios }: AxiosResponse<Estudios[]> = await axiosInstance.get(
      GET_ESTUDIOS
    );
    return estudios;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
