import useSWR from 'swr';

import { GET_DOCUMENTACION_EXISTENTE } from '../axios/constants';
import { getDocumentacionExistente } from '../axios/getDocumentacionExistente';

export const useGetDocumentacionExistente = (id: string) => {
  const { data, isValidating, error,mutate } = useSWR(
    [GET_DOCUMENTACION_EXISTENTE,id],
    (url) => getDocumentacionExistente(url,id),
    {
      refreshInterval:0,
      refreshWhenHidden:false,
      refreshWhenOffline:false,
      revalidateOnFocus:false,
      revalidateOnMount:false,
      revalidateOnReconnect:false
    }
    
  );
  return { data, isValidating, error, mutate };
};
