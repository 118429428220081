import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { VetContactFormProps } from './types';
import './VetContactForm.scss';
import { VetContact } from '../../interfaces/VetContact';
import { IonGrid, IonRow, IonCol, IonPopover } from '@ionic/react';
import { ValidationMail, validationData, validationPhone } from '../../assets/validations/validationRegex';
import { capitalizeFirstLetter } from '../../utils/General';
import { setFormValuesFromObject, submitSvf } from '../../utils/Form';
import FormInput from '../FormInput';
import AddingSvfBtns from '../AddingSvfBtns';
import ToastMsg from '../ToastMsg';

const VetContactForm: React.FC<VetContactFormProps> = ({
  popoverState,
  setShowPopover,
  suggestedVets,
  setSuggestedVets,
  vetIndex: index,
  setVetIndex,
}) => {

  const { control, handleSubmit, errors, setValue } = useForm<VetContact>();
  const formMethods = { control, errors }

  const [showToast, setShowToast] = useState<boolean>(false);

  const onSubmit = (values: VetContact) => {
    const res = submitSvf(index, suggestedVets, setSuggestedVets, values, setShowToast)
    if (res) {
      setShowPopover({ showPopover: false, event: undefined });
    }
  };

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(suggestedVets[index], setValue);
    }
  }, [index]);

  return (
    <IonPopover
      cssClass="vet-contact-form-popover"
      event={popoverState.event}
      isOpen={popoverState.showPopover}
      onDidDismiss={() => {
        setShowPopover({ showPopover: false, event: undefined });
        setVetIndex(undefined);
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
      >
        <IonGrid>
          <div className='title'>Datos de su veterinario</div>
          <IonRow>
            <IonCol>
              {/* Nombre */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'nombre', label: 'Nombre de su veterinario',
                  format: capitalizeFirstLetter,
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese el nombre de su veterinario',
                  pattern: validationData,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Apellido */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'apellido', label: 'Apellido de su veterinario',
                  format: capitalizeFirstLetter,
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese el apellido de su veterinario',
                  pattern: validationData,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Email */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'email', label: 'Email de su veterinario'
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese el email de su veterinario',
                  pattern: ValidationMail,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow className='marginNegative_fix'>
            <IonCol>
              {/* Teléfono */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'telefono', label: 'Teléfono de su veterinario', typeNumber: true
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese el teléfono de su veterinario',
                  pattern: validationPhone,
                }}
              />
            </IonCol>
          </IonRow>

          <AddingSvfBtns
            label="veterinario"
            hideBackBtn={true}
          />
        </IonGrid>
      </form>

      <ToastMsg
        isOpen={showToast}
        setIsOpen={setShowToast}
        message={"Ups...este profesional ya fue añadido"}
        color={"warning"}
      />
    </IonPopover>
  );
};

export default VetContactForm;
