import { useState } from "react";
import { getVetForm as axiosGetVetForm } from "../axios/getVetForm";
import { postVetForm as axiosPostVetForm } from "../axios/vetForm";
import { Veterinario } from "../interfaces/Veterinario";
import { Consulta } from "../interfaces/forms/vetForms/Consulta";
import { Sintoma } from "../interfaces/forms/vetForms/Sintoma";
import { Medicamento } from "../interfaces/forms/vetForms/Medicamento";
import { Practica } from "../interfaces/forms/vetForms/Practica";
import { Estudio } from "../interfaces/forms/vetForms/Estudio";
import { Cirugia } from "../interfaces/forms/vetForms/Cirugia";
import { Internacion } from "../interfaces/forms/vetForms/Internacion";
import { Indicacion } from "../interfaces/Indicacion";
import { Diagnostico } from "../interfaces/forms/vetForms/Diagnostico";
import { formatIndicacionesToSend, formatSintomasToSend, removeProperty } from "../utils/Form";

type PostVetFormType = {
  vetFormId: string;
  veterinario: Veterinario;
  consulta: Consulta;
  sintomas: Sintoma[];
  medicamentos: Medicamento[];
  estudios: Estudio[];
  practicas: Practica[];
  cirugias: Cirugia[];
  internaciones: Internacion[];
  indicaciones: Indicacion[];
  diagnostico: Diagnostico;
};

export const useVetForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getVetForm = async (id: string) => {
    try {
      const vetForm = await axiosGetVetForm(id);
      return vetForm;
    } catch (error) {
      console.error(error);
    }
  };

  const addVetForm: (values: PostVetFormType) => any = async ({
    vetFormId,
    veterinario,
    consulta,
    sintomas,
    medicamentos,
    estudios,
    practicas,
    cirugias,
    internaciones,
    indicaciones,
    diagnostico,
  }) => {
    setIsLoading(true);

    const data = {
      vetFormId,
      veterinario,
      consulta,
      sintomas : formatSintomasToSend(sintomas, ["sintoma", "descripcion", "caracteristicasSeleccionadas", "fechaEstimadaId"]),
      medicamentos,
      estudios,
      practicas,
      cirugias,
      internaciones,
      indicaciones: formatIndicacionesToSend(indicaciones),
      diagnostico: removeProperty(diagnostico, "fechaEstimada"),
    };

    console.log(data);
    const res = await axiosPostVetForm(data);

    setIsLoading(false);
    return res;
  };

  return { getVetForm, addVetForm, isLoading };
};
