import { SintomasContext } from "../../context/SintomasContext";
import { TempSintoma, useSintomas } from "../../hooks/useSintomas";
import {
  Sintoma,
  getEmptySintoma,
} from "../../interfaces/forms/vetForms/Sintoma";
import FormItem from "../FormItem";
import Searchbar from "../Searchbar";
import { IonButton, IonItem, IonLabel } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Select, { ValueType } from "react-select";

const SintomaNorma: React.FC = () => {
  function capitalize(string: string) {
    //Preguntar si hay que usarla o no
    if (string) {
      const lowercasedString = string.toLowerCase();
      const capitalizedString =
        lowercasedString.charAt(0).toUpperCase() + lowercasedString.slice(1);
      return capitalizedString;
    }
  }
  const { searchableSintomas, sintomas} = useContext(SintomasContext);
  const [selectedSintoma, setSelectedSintoma] = useState<TempSintoma | undefined>();


  // const { searchableSintomas, sintomas, isLoading } = useSintomas();
  const buscaSintomas = searchableSintomas;
  const dynamicFieldsIds = useRef<string[]>([]);


  const { control, errors, reset, setValue } = useForm<Sintoma>({
    defaultValues: getEmptySintoma(),
  });
  const [sintomaId, setSintomaId] = useState<number|undefined>(undefined);
  const [sintomaName, setSintomaName] = useState<string>("");
  const [caracteristicasDisponibles, setCaracteristicasDisponibles] =
    useState<any>([]);
  const [seleccionados, setSeleccionados] = useState<{
    [grupo: string]: string[];
  }>({});

  const [jsonGenerado, setJsonGenerado] = useState<string | null>(null);

  useEffect(() => {
    console.log("ENtre",selectedSintoma,sintomaId)
    if (sintomaId) {
      // @TODO innecesario y lento => cambiar las options que se arman en el FormSelectOrCreate para evitarlo
      const actualSintoma = (searchableSintomas as TempSintoma[]).find((ss) => ss.nombre.toLowerCase() === sintomaName.toLowerCase() || ss.label.toLowerCase() === sintomaName.toLocaleLowerCase())
      /* Guardado de los nombres de los campos para desregistrarlos del form si cambia el sintoma  */
      dynamicFieldsIds.current = actualSintoma?.clasificaciones.map(c => c.grupo) ?? [];
      setSelectedSintoma(actualSintoma);
    } else {
      setSelectedSintoma(undefined);
    }
    if (selectedSintoma) {
      console.log("ahora aca")
      setJsonGenerado(null);
      setSeleccionados({});
      setCaracteristicasDisponibles([]);
      if (selectedSintoma.clasificaciones) {
        console.log("Y ahora aca")
        const caract = Object.entries(selectedSintoma.clasificaciones).map(
          (v) => v[1]
        );
        setCaracteristicasDisponibles(caract);
      } else {
        setCaracteristicasDisponibles([]);
      }
    }
  }, [selectedSintoma]);

  useEffect(() => {
    const seleccionadosJSON: { [grupo: string]: string[] } = {};

    const grupos = Object.keys(seleccionados);
    grupos.forEach((grupo) => {
      seleccionadosJSON[grupo] = seleccionados[grupo];
    });
    console.log(
      JSON.stringify(
        { sintoma: sintomaName, caracteristicas: seleccionadosJSON },
        null,
        2
      )
    );
    setJsonGenerado(
      JSON.stringify(
        { sintoma: sintomaName, caracteristicas: seleccionadosJSON },
        null,
        2
      )
    );
  }, [seleccionados]);

  const Generar = () => {
    var copyText = jsonGenerado;

    navigator.clipboard
      .writeText(copyText!)
      .then(() => {
        alert("Copiado correctamente");
      })
      .catch(() => {
        alert("Error al copiar");
      });
  };
  const handleSelec = (grupo: string, valores: string[] | null) => {
      setSeleccionados((prevSeleccionados) => {
        const nuevosSeleccionados = {
          ...prevSeleccionados,
          [grupo]: valores ? [...valores] : [],
        };
        
        const jsonGenerado = Object.keys(nuevosSeleccionados)
        .filter((grupo) => nuevosSeleccionados[grupo].length > 0)
        .reduce((json, grupo) => {
          json[grupo] = nuevosSeleccionados[grupo];
          return json;
        }, {} as { [grupo: string]: string[] });
        
        return nuevosSeleccionados;
      });
  };

  return (
    <div className="sintomas-form ion-padding-horizontal ion-padding-bottom">
      <div className="ion-margin-bottom">
        <Searchbar
          placeholder={sintomaName !== "" ?sintomaName :"Seleccione un sìntoma"}
          value={sintomaName}
          options={buscaSintomas}
          onChange={(e) => {
            if (!e || (!e.value && e.value !== 0)) {
              reset();
              return;
            } else {
              setSelectedSintoma(e.label)
              setSintomaName(e.label);
              setSintomaId(e.value);
            }
          }}
        />
        {caracteristicasDisponibles.map((e) => (
          <Select
          key={sintomaName+e.grupo}
            placeholder={e.grupo}
            closeMenuOnSelect={false}
            isMulti
            options={e.descripciones.map((opcion) => ({
              value: opcion.valor,
              label: opcion.valor,
            }))}
            onChange={(v: any) =>
              handleSelec(
                e.grupo,
                v !== null ? v.map((objeto) => Object.values(objeto)[0]) : []
              )
            }
          />
        ))}
        <IonButton onClick={Generar}>Copiar texto</IonButton>
      </div>
      {jsonGenerado && <pre>{jsonGenerado}</pre>}
    </div>
  );
};

export default SintomaNorma;
