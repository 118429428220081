import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  IonItem,
  IonLabel,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
} from "@ionic/react";
import "./DiagnosticoForm.scss"
import { DiagnosticoFormProps } from "./types";
import { Diagnostico, getEmptyDiagnostico } from "../../interfaces/forms/vetForms/Diagnostico";
import { useEnfermedad } from "../../hooks/useEnfermedades";
import { Option } from "../../utils/Form";
import Title from "../Title";
import FormSelectOrCreate from "../FormSelectOrCreate";
import FormSelectPopover from "../FormSelectPopover";
import FormInput from "../FormInput";
import FormItem from "../FormItem";
import Continue from "../Continue";

const DiagnosticoForm: React.FC<DiagnosticoFormProps> = ({
  diagnostico,
  setDiagnostico,
  goBack,
  goForward,
}) => {
  const { searchableEnfermedades, isLoading } = useEnfermedad();
  const { handleSubmit, control, errors, setValue, watch } = useForm<Diagnostico>({
    defaultValues: diagnostico ?? getEmptyDiagnostico(),
  });
  const formMethods = { control, errors, setValue }

  const diagnosticoField = watch("diagnostico");
  const fechaEstimada = watch("fechaEstimada");
  const congenito = watch("congenito");
  const tratamientoPorVida = watch("tratamientoPorVida");
  const enfermedadBase = watch("enfermedadBase");
  const motivo = watch("motivo");

  const [option, setOption] = useState<Option | null>();
  const [option1, setOption1] = useState<Option | null>();

  const fechasEstimadas = [
    "Muy reciente: Última semana",
    "Reciente: Entre 1 y 2 semanas",
    "Intermedia: Entre 1 y 6 meses",
    "Antigua: Entre 6 meses y 1 año",
    "Muy antigua: más de un año"
  ];

  const onSubmit = (values: Diagnostico) => {
    values.fechaEstimadaId = String(fechasEstimadas.indexOf(values.fechaEstimada!));
    setDiagnostico(values);
    goForward();
  };

  useEffect(() => {
    diagnosticoField && setOption({ label: diagnosticoField, value: "" })
    motivo && setOption1({ label: motivo, value: "" })
  }, [diagnosticoField, motivo]);

  //Reset campos si no existe diagnosticoField
  useEffect(() => {
    if (!diagnosticoField) {
      const emptyDiagnostico = getEmptyDiagnostico();
      Object.keys(emptyDiagnostico).forEach(key => {
        setValue(key, emptyDiagnostico[key]);
      });
    }
  }, [diagnosticoField]);

  return (
    <div className="diagnostico-form ion-margin">
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ion-no-padding">
          <Title title="Diagnóstico presuntivo" />

          <IonRow>
            <IonCol>
              {/* Diagnostico */}
              <FormSelectOrCreate
                creatable={true}
                options={searchableEnfermedades}
                option={option}
                isLoading={isLoading}
                formMethods={formMethods}
                fieldProps={{
                  name: 'diagnostico',
                  placeholder: "Seleccione una enfermedad"
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese una enfermedad',
                }}
              />
            </IonCol>
          </IonRow>

          {diagnosticoField && <>
            <IonRow>
              <IonCol hidden>
                {/* Fecha estimada Id*/}
                <FormInput
                  formMethods={formMethods}
                  fieldProps={{
                    name: 'fechaEstimadaId', label: '',
                  }}
                />
              </IonCol>

              <IonCol>
                {/* Fecha estimada */}
                <FormSelectPopover
                  formMethods={formMethods}
                  options={fechasEstimadas}
                  fieldProps={{
                    name: 'fechaEstimada', label: 'Fecha estimada de origen de enfermedad'
                  }}
                  validationProps={{
                    errorMessage: 'Por favor seleccione una fecha estimada',
                  }}
                />
              </IonCol>
            </IonRow>
          </>
          }

          {fechaEstimada && <>
            <IonRow className="ion-margin-top">
              <IonCol className="subtitle">
                Marque si corresponde alguna de las siguientes opciones
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {/* Congenito */}
                <IonItem lines="inset" className="ion-no-padding">
                  <IonLabel>¿Es congénito?</IonLabel>
                  <FormItem
                    render={({ name, value }) => (
                      <IonCheckbox
                        mode="md"
                        checked={value}
                        name={name}
                        value={value}
                        onIonChange={(e) =>
                          setValue("congenito", !congenito)
                        }
                      />
                    )}
                    name="congenito"
                    control={control}
                  />
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                {/* Tratamiento de por vida */}
                <IonItem lines="inset" className="ion-no-padding">
                  <IonLabel>¿Requiere tratamiento de por vida?</IonLabel>
                  <FormItem
                    render={({ name, value }) => (
                      <IonCheckbox
                        mode="md"
                        checked={value}
                        name={name}
                        value={value}
                        onIonChange={(e) =>
                          setValue("tratamientoPorVida", !tratamientoPorVida)
                        }
                      />
                    )}
                    name="tratamientoPorVida"
                    control={control}
                  />
                </IonItem>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                {/* Enfermedad de base */}
                <IonItem lines="inset" className="ion-no-padding">
                  <IonLabel>¿Responde a una enfermedad de base?</IonLabel>
                  <FormItem
                    render={({ name, value }) => (
                      <IonCheckbox
                        mode="md"
                        checked={value}
                        value={value}
                        name={name}
                        onIonChange={(e) =>
                          setValue("enfermedadBase", !enfermedadBase)
                        }
                      />
                    )}
                    name="enfermedadBase"
                    control={control}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          </>}

          {enfermedadBase && <>
            <IonRow className="ion-margin-top">
              <IonCol>
                {/* Motivo */}
                <FormSelectOrCreate
                  creatable={true}
                  options={searchableEnfermedades}
                  option={option1}
                  formMethods={formMethods}
                  fieldProps={{
                    name: 'motivo',
                    placeholder: "Seleccione enfermedad de base"
                  }}
                  validationProps={{
                    errorMessage: 'Por favor seleccione una enfermedad de base o motivo ',
                  }}
                />
              </IonCol>
            </IonRow>
          </>}

          <div className="ion-margin-top">
            <IonButton onClick={goBack} mode="ios" expand="block" fill="outline" color="dark">Regresar</IonButton>
            <Continue />
          </div>
        </IonGrid>
      </form>
    </div>
  );
};

export default DiagnosticoForm;
