import { useMedia } from "../../context/MediaContext";
import Alert from "../Alert";
import "./Gallery.scss";
import { GalleryProps } from "./types";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBackOutline, close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

const Gallery: React.FC<GalleryProps> = () => {
  const [elementToBeDeleted, setElementToBeDeleted] = useState<string>();
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

  const {
    tempFile,
    setTempFile,
    setConfirmFile,
    currentMedia,
    deleteCurrentMediaFile,
    showMediaState,
    setShowMediaState,
  } = useMedia();

  //ShowMediaState Handler
  useEffect(() => {
    if (tempFile) {
      setShowMediaState("tempFile");
    }
  }, [tempFile]);

  useEffect(() => {
    !currentMedia.length && setShowMediaState(undefined);
  }, [currentMedia]);

  return (
    <IonModal
      cssClass={"photo-gallery"}
      isOpen={showMediaState !== undefined}
      onDidDismiss={() => setShowMediaState(undefined)}
    >
      <IonToolbar>
        <IonButtons slot="start">
          <IonIcon
            icon={arrowBackOutline}
            onClick={() => {
              setShowMediaState(undefined);
              if (showMediaState === "tempFile") {
                setTempFile(undefined);
              }
            }}
          />
        </IonButtons>
        <IonTitle>
          {showMediaState === "tempFile"
            ? "Nuevo archivo"
            : showMediaState === "gallery"
            ? "Archivos adjuntos"
            : ""}
        </IonTitle>
      </IonToolbar>

      <IonGrid>
        {showMediaState === "tempFile" && (
          <div className="tempFile">
            <IonRow>
              <IonCol>
                {tempFile?.fileName.endsWith(".jpeg") ? (
                  <img src={tempFile.webviewPath} alt="Image preview" />
                ) : (
                  <div className="pdf-container">
                    <div className="pdf-wrapper">
                      <Document
                        file={tempFile?.webviewPath}
                        onLoadError={(error) =>
                          console.log("Error loading PDF:", error)
                        }
                        className="pdf-document"
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </div>
                  </div>
                )}
              </IonCol>
            </IonRow>

            <div className="ion-margin-top">
              <IonButton
                mode="ios"
                expand="block"
                onClick={() => {
                  setShowMediaState(undefined);
                  setConfirmFile(true);
                }}
              >
                Añadir archivo
              </IonButton>
            </div>
          </div>
        )}

        {showMediaState === "gallery" && (
          <div className="gallery">
            <IonRow>
              {currentMedia.map((element, index) => (
                <IonCol size="6" key={index}>
                  {element.fileName.endsWith(".jpeg") ? (
                    <>
                      <img src={element.webviewPath} alt={`Photo ${index}`} />
                      <IonIcon
                        onClick={() => {
                          setElementToBeDeleted(element.fileName);
                          setShowDeleteAlert(true);
                        }}
                        icon={close}
                        color="danger"
                      />
                    </>
                  ) : (
                    <>
                      <div className="pdf-container">
                        <div className="pdf-wrapper">
                          <Document
                            file={element?.webviewPath}
                            onLoadError={(error) =>
                              console.log("Error loading PDF:", error)
                            }
                            className="pdf-document"
                          >
                            <Page pageNumber={1} />
                          </Document>
                        </div>
                      </div>

                      <IonIcon
                        onClick={() => {
                          setElementToBeDeleted(element.fileName);
                          setShowDeleteAlert(true);
                        }}
                        icon={close}
                        color="danger"
                      />
                    </>
                  )}
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
      </IonGrid>

      {/* Delete element */}
      <Alert
        isOpen={showDeleteAlert}
        setIsOpen={setShowDeleteAlert}
        subHeader={"¿Desea eliminar el archivo?"}
        buttonsLabel={{
          primary: "Conservar",
          secondary: "Eliminar",
        }}
        actions={{
          primary: () => setShowDeleteAlert(false),
          secondary: () =>
            elementToBeDeleted && deleteCurrentMediaFile(elementToBeDeleted),
        }}
        deleteStyle
      />
    </IonModal>
  );
};

export default Gallery;
