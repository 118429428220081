import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { Practicas } from '../interfaces/Practicas';
import { GET_PRACTICAS } from './constants';

export const getPracticas = async () => {
  try {
    const { data: practicas }: AxiosResponse<Practicas[]> = await axiosInstance.get(
      GET_PRACTICAS
    );
    return practicas;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
