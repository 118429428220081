import { useState, useEffect, useContext } from "react";
import { getSintomas as axiosGetSintomas } from "../axios/getSintomas";
import { Sintoma } from "../interfaces/Sintoma";
import { StorageContext } from "../context/StorageContext";
import { Sintoma as SintomaOption } from '../interfaces/Sintoma';

export type TempSintoma = SintomaOption & {label:string, value:string}; //@TODO esto es un parche temporal porque los tipos no coinciden

export const useSintomas = () => {

  const storageContext = useContext(StorageContext);
  // @TODO Ver Tipado
  const [searchableSintomas, setSearchableSintomas] = useState<TempSintoma[]>([]);
  const [sintomas, setSintomas] = useState<Sintoma[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const getSintomas = async () => {
    try {
      setIsLoading(true);
      let savedSintomas : Sintoma[] | undefined = undefined;
      let savedSearchableSintomas : TempSintoma[]|undefined;
 
      if (storageContext.ready) {
        savedSintomas = await storageContext.storage.get('sintomas');
        savedSearchableSintomas = await storageContext.storage.get('searchableSintomas');
      }
      
      if (!savedSintomas || !savedSearchableSintomas) {
        const sintomasFromServer = await axiosGetSintomas();
        const search_sintomas = sintomasFromServer.map(({ sistemas, nombre, clasificaciones,evidencia }, id) => ({
          sistemas,
          nombre,
          evidencia,
          clasificaciones,
          value: id.toString(),
          label: nombre,
        } as TempSintoma));
        await storageContext.storage.set('sintomas',sintomasFromServer);
        await storageContext.storage.set('searchableSintomas',search_sintomas);
        savedSintomas = sintomas;
        savedSearchableSintomas = search_sintomas;
      }
      setSintomas(savedSintomas);
      setSearchableSintomas(savedSearchableSintomas);
    } catch (error) {
      console.error("Error al obtener los síntomas:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSintomas();
  }, []);

  return { searchableSintomas, sintomas, isLoading };
};
