import { DetalleIndicacionGenerica } from "./DetalleIndicacionGenerica";
import { MedicamentoMascota } from "./MedicamentoMascota";
import { VetContact } from "./VetContact";

export type Indicacion = {
  id: number | undefined;
  tipoIndicacion: string;

  //Detalle medicamento
  medicamento?: string;
  dosaje?: string;
  frecuencia?: string;
  duracion?: string;
  //

  //Detalle genérico
  descripcion?: string;
  fecha?: string;
  //

  suggestedVets?: VetContact[];
};

export type Indicacion_toBeSubmitted = {
  id: number | undefined;
  tipoIndicacion: string;
  detalleIndicacion?: MedicamentoMascota | DetalleIndicacionGenerica;
  suggestedVets?: VetContact[];
};

const emptyIndicacion: Indicacion = {
  id: undefined,
  tipoIndicacion: "",

  //Detalle medicamento
  medicamento: "",
  dosaje: "",
  frecuencia: "",
  duracion: "",

  //Detalle generico
  descripcion: "",
  fecha: "",

  suggestedVets: undefined,
};

export const getEmptyIndicaciones: Indicacion[] = [];

export function getEmptyIndicacion(): Indicacion {
  return JSON.parse(JSON.stringify(emptyIndicacion));
}
