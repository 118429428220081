import React, { useState, useEffect } from "react";
import { IonContent, IonPage, useIonRouter } from "@ionic/react";

import { MedicamentoContextProvider } from "../../context/MedicamentosContext";
import { SintomasContextProvider } from "../../context/SintomasContext";
import { useVetForm } from "../../hooks/useVetForm";
import { getVetForm } from "../../axios/getVetForm";
import { useVeterinario } from "../../hooks/useVeterinarios";
import { DatosUsuario as DatosUsuarioInterface, getEmptyDatosUsuario } from "../../interfaces/DatosUsuario";
import { Consulta as ConsultaInterface, getEmptyConsulta } from "../../interfaces/forms/vetForms/Consulta";
import { Veterinario, getEmptyVeterinario } from "../../interfaces/Veterinario";
import { Sintoma, getEmptySintomas } from "../../interfaces/forms/vetForms/Sintoma";
import { Medicamento, getEmptyMedicamentos } from "../../interfaces/Medicamento";
import { Practica, getEmptyPracticas } from "../../interfaces/forms/vetForms/Practica";
import { Estudio, getEmptyEstudios } from "../../interfaces/forms/vetForms/Estudio";
import { Cirugia, getEmptyCirugias } from "../../interfaces/forms/vetForms/Cirugia";
import { Internacion, getEmptyInternaciones } from "../../interfaces/forms/vetForms/Internacion";
import { Indicacion, getEmptyIndicaciones } from "../../interfaces/Indicacion";
import { Diagnostico, getEmptyDiagnostico } from "../../interfaces/forms/vetForms/Diagnostico";
import "./../../pages/vet-form";
import { VetFormProps } from "./types";
import "./VetForm.scss";

import { SVF_threshold, findStepIndexByValue, indicacionesGoBack, resumenGoBack, stepsData } from "../../utils/Form";

import Header from "../Header";
import DatosUsuario from "../DatosUsuario";
import HistorialSintomas from "../../components/HistorialSintomas";
import VeterinarioForm from "../VeterinarioForm";
import Consulta from "../Consulta";
import SubVetForm from "../SubVetForm";
import SintomasForm from "../SintomasForm";
import MedicamentosForm from "../MedicamentosForm";
import PracticasForm from "../PracticasForm";
import EstudiosForm from "../EstudiosForm";
import CirugiasForm from "../CirugiasForm";
import InternacionesForm from "../InternacionesForm";
import IndicacionesForm from "../IndicacionesForm";
import DiagnosticoForm from "../DiagnosticoForm";
import Resumen from "../Resumen";
import ConfirmStep from "../ConfirmStep";
import ToastMsg from "../ToastMsg";
import TutorialBasic from "../TutorialBasic";

const VetForm: React.FC<VetFormProps> = ({ vetFormLink }) => {
  const history = useIonRouter();
  const { getVeterinarioByVetform } = useVeterinario();
  const { addVetForm } = useVetForm();
  const [formStep, setFormStep] = useState<number>(0);
  const [prevSvf, setPrevSvf] = useState<string | undefined>();
  const [index, setIndex] = useState<number>();
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const [datosUsuario, setDatosUsuario] = useState<DatosUsuarioInterface>(getEmptyDatosUsuario);
  const [veterinario, setVeterinario] = useState<Veterinario>(getEmptyVeterinario);
  const [consulta, setConsulta] = useState<ConsultaInterface>(getEmptyConsulta);
  const [sintomas, setSintomas] = useState<Sintoma[]>(getEmptySintomas);
  const [medicamentos, setMedicamentos] = useState<Medicamento[]>(getEmptyMedicamentos);
  const [practicas, setPracticas] = useState<Practica[]>(getEmptyPracticas);
  const [estudios, setEstudios] = useState<Estudio[]>(getEmptyEstudios);
  const [cirugias, setCirugias] = useState<Cirugia[]>(getEmptyCirugias);
  const [internaciones, setInternaciones] = useState<Internacion[]>(getEmptyInternaciones);
  const [indicaciones, setIndicaciones] = useState<Indicacion[]>(getEmptyIndicaciones);
  const [diagnostico, setDiagnostico] = useState<Diagnostico>(getEmptyDiagnostico);

  // Popover para confirmar ingreso a SVF
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  // Confirmacion de entrada a SVF
  const [confirmedSvf, setConfirmSvf] = useState<boolean>(false);
  // Cuando se esta agregando un nuevo elemento a un SVF especifico
  const [addingSvfElement, setAddingSvfElement] = useState<boolean>(false);
  // Opciones para agregar un elemento a SVF o añadirlo como indicación
  const [showSvfOptions, setShowSvfOptions] = useState<boolean>(false);
  // Editar elemento en SVF desde resumen pre submit
  const [editingFromResumen, setEditFromResumen] = useState<boolean>(false);
  // Indica si el SVF que se esta mostrando tiene elementos
  const [currentSvfHasLength, setCurrentSvfHasLength] = useState<boolean>(false);

  // Elemento ya existente dentro de un SVF especifico
  const [showToast, setShowToast] = useState<boolean>(false);
  // Elemento eliminado exitosamente de un SVF
  const [showToast1, setShowToast1] = useState<boolean>(false);
  // Indicacion agregada exitosamente desde otro SVF
  const [showToast2, setShowToast2] = useState<boolean>(false);
  // Submit error
  const [showToast3, setShowToast3] = useState<boolean>(false);

  // Encontrar index de componente en stepDataArray para ser usado como FormStep
  const find_FS = (value: string) => {
    return findStepIndexByValue(value);
  }

  // Modificar formStep
  const modifyStep = async (move: number) => {
    setFormStep(Math.max(0, formStep + move));
  };

  // Header goBack switch
  const headerGoBack = () => {
    //Caso para retornar cuando se esta añadiendo elemento como indicación
    if (prevSvf !== undefined) {
      indicacionesGoBack(prevSvf, setFormStep, setAddingSvfElement);
    } else if (showSvfOptions && currentSvfHasLength) {
      setShowSvfOptions(false);
    } else if (addingSvfElement && currentSvfHasLength) {
      setShowSvfOptions(true);
      setAddingSvfElement(false);
    } else if (addingSvfElement) {
      setAddingSvfElement(false);
    } else if (formStep === find_FS("datosVeterinario")) {
      modifyStep(!sintomas.length ? -2 : -1);
    } else if (!addingSvfElement) {
      modifyStep(-1)
    }
  }

  const avoided_SVFs = ["sintomas", "indicaciones"];
  const hideSvfOptions = avoided_SVFs.some(str => formStep === find_FS(str));

  const moveProps = {
    goBack: () => modifyStep(-1),
    goForward: () => modifyStep(1)
  }

  // Propiedades compartidas por SVFs
  const sharedProps = {
    svf: {
      index,
      setAddingSvfElement,
      setPrevSvf,
      showToast,
      setShowToast
    },

    wrapper: {
      formStep,
      prevSvf,
      setPrevSvf,
      setIndex,
      setShowPopover,
      confirmedSvf,
      setConfirmSvf,
      addingSvfElement,
      setAddingSvfElement,
      showSvfOptions,
      setShowSvfOptions,
      hideSvfOptions,
      setCurrentSvfHasLength,
      setShowToast1,
      veterinario,
      goForward: () => modifyStep(1)
    }
  };

  const editFromResumenProps = {
    setFormStep,
    setIndex,
    setEditFromResumen
  }

  // Submit exitoso es seguido de una redirección
  const okSubmitted = () => {
    const vetName = veterinario ? `${veterinario.nombre}_${veterinario.apellido}` : undefined;
    const ownerName = datosUsuario.persona ? `${datosUsuario.persona.nombre}_${datosUsuario.persona.apellido}` : undefined;
    const formattedVetName = vetName ? `vet=${vetName}` : '';
    const formattedOwner = ownerName ? `owner=${ownerName}` : '';
    const queryParams = [formattedVetName, formattedOwner].filter(Boolean).join('&');
    const redirectURL = `/confirm/?${queryParams}`;
    history.push(redirectURL);
  };

  const submit = async () => {
    setIsSubmiting(true);
    const payload = {
      vetFormId: vetFormLink.vetForm,
      veterinario,
      consulta,
      sintomas,
      medicamentos,
      estudios,
      practicas,
      cirugias,
      internaciones,
      indicaciones,
      diagnostico
    };

    const ok = await addVetForm(payload);
    window.removeEventListener("beforeunload", handleBeforeUnload);

    if (ok) {
      setIsSubmiting(false);
      okSubmitted();
    } else {
      setShowToast3(true);
    }
  }

  // Resumen de VetForm y posterior submit
  const submitProps = {
    submit,
    isSubmiting,
    datosUsuario,
    veterinario,
    sintomas,
    medicamentos,
    practicas,
    estudios,
    cirugias,
    internaciones,
    indicaciones,
    diagnostico
  }

  // Previene salir del form antes de submit
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!isSubmiting) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  // Determina si VetForm ya fue completado con anterioridad
  const getVetFormData = async (vetFormId: string) => {
    try {
      const vetForm = await getVetForm(vetFormId);

      if (vetForm.estado === "COMPLETADO") {
        const { id: vetFormId, siniestroId } = vetForm;
        history.push(`/error/?data=${vetFormId}-${siniestroId}`);
      }
    } catch (error) {
      console.error("Error al obtener datos del formulario veterinario: ", error);
    }
  };

  // Establece el veterinario asignado
  const handleVetForm = async () => {
    try {
      if (vetFormLink.vetForm !== "") {
        const veterinario = await getVeterinarioByVetform(vetFormLink.vetForm);

        if (veterinario?.tipo === "VetContact") {
          veterinario.nombre = "";
          veterinario.telefono = "";
        }

        veterinario && setVeterinario(veterinario);
      }
    } catch (error) {
      console.error("Error al establecer el veterinario asignado: ", error);
    }
  };

  const dependenciesArray = [
    sintomas,
    medicamentos,
    practicas,
    estudios,
    cirugias,
    internaciones,
    indicaciones
  ];

  // Cuando se escribe un SVF se cierra la vista/(state) de agregando nuevo elemento y se anula la vista de opciones 
  useEffect(() => {
    setAddingSvfElement(false);
    setShowSvfOptions(false);
    // Cuando se escribe un state desde el resumen
    editingFromResumen && resumenGoBack(setEditFromResumen, setFormStep);
  }, dependenciesArray);

  // Permite lectura de SVF si se continua al siguiente paso mostrando opciones y se retorna
  useEffect(() => {
    setShowSvfOptions(false);
  }, [formStep]);

  // Si no se esta escribiendo un SVF el index para editar un elemento debe ser undefined
  useEffect(() => {
    !addingSvfElement && setIndex(undefined);
  }, [addingSvfElement]);

  // Edit elemento en SVF desde resumen
  useEffect(() => {
    editingFromResumen && setAddingSvfElement(true);
  }, [editingFromResumen]);

  // Handler del comportamiento UI de c/u de los componentes SVF
  useEffect(() => {
    !addingSvfElement && editingFromResumen && resumenGoBack(setEditFromResumen, setFormStep);
  }, [addingSvfElement])

  // Go to <IndicacionesForm/> desde otros SVF cuando set prevSvf
  useEffect(() => {
    if (prevSvf !== undefined) {
      setFormStep(SVF_threshold.max);
      setAddingSvfElement(true);
    }
  }, [prevSvf]);

  // Handler que previene salir del formulario antes de submit
  useEffect(() => {
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => handleBeforeUnload(event);
    window.addEventListener("beforeunload", beforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [isSubmiting]);

  // Handler previo al llenado del VetForm
  useEffect(() => {
    getVetFormData(vetFormLink.vetForm);
    handleVetForm();
  }, [vetFormLink])

  return (
    <div className="vet-form">
      <MedicamentoContextProvider>
        <IonPage>
          <Header
            formStep={formStep}
            goBack={() => headerGoBack()}
            hideGoBack={editingFromResumen}
            confirmedSvf={confirmedSvf}
            addingSvfElement={addingSvfElement}
          />

          <IonContent>
            {vetFormLink && (
              <>
                {formStep === find_FS("datosUsuario") && (
                  <DatosUsuario
                    siniestroId={vetFormLink.siniestro}
                    setDatosUsuario={setDatosUsuario}
                    goForward={() => modifyStep(sintomas?.length ? 1 : 2)}
                  />
                )}

                {formStep === find_FS("historialSintomas") && (
                  <HistorialSintomas
                    goForward={() => modifyStep(2)}
                  />
                )}
              </>
            )}

            {formStep === find_FS("datosVeterinario") && (
              <VeterinarioForm
                veterinario={veterinario}
                setVeterinario={setVeterinario}
                goForward={() => modifyStep(1)}
              />
            )}

            {formStep === find_FS("consulta") && (
              <Consulta
                consulta={consulta}
                setConsulta={setConsulta}
                goForward={() => modifyStep(1)}
              />
            )}

            {formStep === find_FS("sintomas") &&
              <SintomasContextProvider>
                <SubVetForm
                  state={sintomas}
                  setState={setSintomas}
                  form={
                    <SintomasForm
                      sintomas={sintomas}
                      setSintomas={setSintomas}
                      {...moveProps}
                      {...sharedProps.svf}
                    />
                  }
                  {...sharedProps.wrapper}
                />
              </SintomasContextProvider>
            }

            {formStep === find_FS("medicamentos") &&
              <SubVetForm
                state={medicamentos}
                setState={setMedicamentos}
                form={
                  <MedicamentosForm
                    medicamentos={medicamentos}
                    setMedicamentos={setMedicamentos}
                    {...sharedProps.svf}
                  />
                }
                {...sharedProps.wrapper}
              />
            }

            {formStep === find_FS("practicas") &&
              <SubVetForm
                state={practicas}
                setState={setPracticas}
                form={
                  <PracticasForm
                    practicas={practicas}
                    setPracticas={setPracticas}
                    {...sharedProps.svf}
                  />
                }
                {...sharedProps.wrapper}
              />
            }

            {formStep === find_FS("estudios") &&
              <SubVetForm
                state={estudios}
                setState={setEstudios}
                form={
                  <EstudiosForm
                    estudios={estudios}
                    setEstudios={setEstudios}
                    {...sharedProps.svf}
                  />
                }
                {...sharedProps.wrapper}
              />
            }

            {formStep === find_FS("cirugias") &&
              <SubVetForm
                state={cirugias}
                setState={setCirugias}
                form={
                  <CirugiasForm
                    cirugias={cirugias}
                    setCirugias={setCirugias}
                    {...sharedProps.svf}
                  />
                }
                {...sharedProps.wrapper}
              />
            }

            {formStep === find_FS("internaciones") &&
              <SubVetForm
                state={internaciones}
                setState={setInternaciones}
                form={
                  <InternacionesForm
                    internaciones={internaciones}
                    setInternaciones={setInternaciones}
                    {...sharedProps.svf}
                  />
                }
                {...sharedProps.wrapper}
              />
            }

            {formStep === find_FS("indicaciones") &&
              <SubVetForm
                state={indicaciones}
                setState={setIndicaciones}
                form={
                  <IndicacionesForm
                    indicaciones={indicaciones}
                    setIndicaciones={setIndicaciones}
                    prevSvf={prevSvf}
                    setFormStep={setFormStep}
                    setAddedOk={setShowToast2}
                    fechaConsulta={consulta.fechaInicio}
                    {...moveProps}
                    {...sharedProps.svf}
                  />
                }
                {...sharedProps.wrapper}
              />
            }

            {formStep === find_FS("diagnostico") &&
              <DiagnosticoForm
                diagnostico={diagnostico}
                setDiagnostico={setDiagnostico}
                {...moveProps}
              />
            }

            {formStep === find_FS("resumen") &&
              <Resumen
                {...submitProps}
                {...editFromResumenProps}
              />
            }

            <ConfirmStep
              popoverState={popoverState}
              setShowPopover={setShowPopover}
              confirmedSvf={confirmedSvf}
              setConfirmSvf={setConfirmSvf}
              editingFromResumen={editingFromResumen}
              formStep={formStep}
              {...moveProps}
            />

            <ToastMsg
              isOpen={showToast}
              setIsOpen={setShowToast}
              message={stepsData[formStep].toastMsgEditing}
              color={"warning"}
            />

            <ToastMsg
              isOpen={showToast1}
              setIsOpen={setShowToast1}
              message={stepsData[formStep].toastMsgDeleted}
              color={"success"}
            />

            <ToastMsg
              isOpen={showToast2}
              setIsOpen={setShowToast2}
              message={stepsData[formStep]?.addedAsIndicacionMsg}
              color={"success"}
            />

            <ToastMsg
              isOpen={showToast3}
              setIsOpen={setShowToast3}
              message={"Ups... ocurrio un error al enviar el formulario. Lo sentimos, por favor intententelo nuevamente."}
              color={"danger"}
            />

            {/* TODO: 
            VER HISTORIAL DE SINTOMAS
            SEGUIR LIMPIANDO ARCHIVOS VIEJOS
            */}

            <TutorialBasic formStep={formStep} setFormStep={setFormStep} />
          </IonContent>
        </IonPage>
      </MedicamentoContextProvider>
    </div>
  );
};

export default VetForm;
