import { validationAlphanumeric } from "../../assets/validations/validationRegex";
import { useSubVetForm } from "../../context/SubVetFormContext";
import { useEnfermedad } from "../../hooks/useEnfermedades";
import {
  Diagnostico,
  getEmptyDiagnostico,
} from "../../interfaces/forms/Diagnostico";
import { today } from "../../utils/DateUtilities";
import { Option, fechasEstimadas } from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import Continue from "../Continue";
import FormInput from "../FormInput";
import FormItem from "../FormItem";
import FormSelectOrCreate from "../FormSelectOrCreate";
import FormSelectPopover from "../FormSelectPopover";
import Title from "../Title";
import "./DiagnosticoForm.scss";
import { DiagnosticoFormProps } from "./types";
import {
  IonItem,
  IonLabel,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonTextarea,
  isPlatform,
} from "@ionic/react";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

const DiagnosticoForm: React.FC<DiagnosticoFormProps> = ({
  goBack,
  goForward,
}) => {
  const { diagnostico, setDiagnostico } = useSubVetForm();
  const { searchableEnfermedades, isLoading } = useEnfermedad();
  const { handleSubmit, control, errors, setValue, watch } =
    useForm<Diagnostico>({
      defaultValues: diagnostico ?? getEmptyDiagnostico(),
    });
  const formMethods = { control, errors, setValue };

  const diagnosticoField = watch("diagnostico");
  const fechaEstimada = watch("fechaEstimada");
  const congenito = watch("congenito");
  const tratamientoPorVida = watch("tratamientoPorVida");
  const enfermedadBase = watch("enfermedadBase");
  const motivo = watch("motivo");

  const [option, setOption] = useState<Option | null>();
  const [option1, setOption1] = useState<Option | null>();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const textareaRef = useRef<HTMLIonTextareaElement>(null);

  const onSubmit = (values: Diagnostico) => {
    setDiagnostico(values);
    goForward();
  };

  useEffect(() => {
    diagnosticoField && setOption({ label: diagnosticoField, value: "" });
    motivo && setOption1({ label: motivo, value: "" });
  }, [diagnosticoField, motivo]);

  //Reset campos si no existe diagnosticoField
  useEffect(() => {
    if (!diagnosticoField) {
      const emptyDiagnostico = getEmptyDiagnostico();
      Object.keys(emptyDiagnostico).forEach((key) => {
        setValue(key, emptyDiagnostico[key]);
      });
    }
  }, [diagnosticoField]);

  //Hide checkboxes en movil cuando se da focus a textarea de motivoConsulta
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (textareaRef.current && !textareaRef.current.contains(event.target)) {
        setIsCollapsed(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="diagnostico-form ion-margin">
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ion-no-padding">
          <Title title="Diagnóstico presuntivo" />

          <IonRow>
            <IonCol>
              {/* Diagnostico */}
              <FormSelectOrCreate
                creatable={true}
                options={searchableEnfermedades}
                option={option}
                isLoading={isLoading}
                formMethods={formMethods}
                fieldProps={{
                  name: "diagnostico",
                  placeholder: "Seleccione una enfermedad",
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese una enfermedad",
                }}
              />
            </IonCol>
          </IonRow>

          {diagnosticoField && (
            <>
              <IonRow>
                {/* <IonCol hidden> */}
                {/* Fecha estimada Id*/}
                {/* <FormInput
                    formMethods={formMethods}
                    fieldProps={{
                      name: "fechaEstimadaId",
                      label: "",
                    }}
                  />
                </IonCol> */}

                <IonCol>
                  {/* Fecha estimada */}
                  <FormSelectPopover
                    formMethods={formMethods}
                    options={fechasEstimadas}
                    fieldProps={{
                      name: "fechaEstimada",
                      label: "Fecha estimada de origen de enfermedad",
                    }}
                    validationProps={{
                      errorMessage: "Por favor seleccione una fecha estimada",
                    }}
                  />
                </IonCol>
              </IonRow>
            </>
          )}
          <div hidden={isCollapsed && isPlatform("hybrid")}>
            {fechaEstimada && (
              <>
                <IonRow className="ion-margin-top">
                  <IonCol className="diagnostico-form__subtitle">
                    Marque si corresponde alguna de las siguientes opciones
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    {/* Congenito */}
                    <IonItem lines="inset" className="ion-no-padding">
                      <IonLabel>¿Es congénito?</IonLabel>
                      <FormItem
                        render={({ name, value }) => (
                          <IonCheckbox
                            mode="md"
                            checked={value}
                            name={name}
                            value={value}
                            onIonChange={(e) =>
                              setValue("congenito", !congenito)
                            }
                          />
                        )}
                        name="congenito"
                        control={control}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    {/* Tratamiento de por vida */}
                    <IonItem lines="inset" className="ion-no-padding">
                      <IonLabel>¿Requiere tratamiento de por vida?</IonLabel>
                      <FormItem
                        render={({ name, value }) => (
                          <IonCheckbox
                            mode="md"
                            checked={value}
                            name={name}
                            value={value}
                            onIonChange={(e) =>
                              setValue(
                                "tratamientoPorVida",
                                !tratamientoPorVida
                              )
                            }
                          />
                        )}
                        name="tratamientoPorVida"
                        control={control}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    {/* Enfermedad de base */}
                    <IonItem lines="inset" className="ion-no-padding">
                      <IonLabel>¿Responde a una enfermedad de base?</IonLabel>
                      <FormItem
                        render={({ name, value }) => (
                          <IonCheckbox
                            mode="md"
                            checked={value}
                            value={value}
                            name={name}
                            onIonChange={(e) =>
                              setValue("enfermedadBase", !enfermedadBase)
                            }
                          />
                        )}
                        name="enfermedadBase"
                        control={control}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              </>
            )}

            {enfermedadBase && (
              <>
                <IonRow className="ion-margin-top">
                  <IonCol>
                    {/* Motivo */}
                    <FormSelectOrCreate
                      creatable={true}
                      options={searchableEnfermedades}
                      option={option1}
                      formMethods={formMethods}
                      fieldProps={{
                        name: "motivo",
                        placeholder: "Seleccione enfermedad de base",
                      }}
                      validationProps={{
                        errorMessage:
                          "Por favor seleccione una enfermedad de base o motivo",
                      }}
                    />
                  </IonCol>
                </IonRow>
              </>
            )}
          </div>

          {fechaEstimada && (
            <div className="ion-margin-vertical">
              {/* Motivo consulta*/}
              <IonRow>
                <IonCol className="subtitle">
                  ¿Desea agregar más información sobre la consulta?
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <FormItem
                    render={({ onChange, value }) => (
                      <IonItem mode="ios" className="ion-no-padding">
                        <IonLabel position="floating">
                          Breve descripción
                        </IonLabel>
                        <IonTextarea
                          onIonChange={onChange}
                          value={capitalizeFirstLetter(value)}
                          ref={textareaRef}
                          onFocus={() =>
                            setIsCollapsed((prevState) => !prevState)
                          }
                          rows={6}
                          placeholder={`Ejemplo: la mascota toma anticonvulsivos desde el año ${moment(
                            today
                          ).format("YYYY")} porque es epiléptica.`}
                        />
                      </IonItem>
                    )}
                    name="motivoConsulta"
                    control={control}
                    // errors={errors}
                    rules={{
                      pattern: {
                        value: validationAlphanumeric,
                        message: "Por favor, ingrese una descripción valida",
                      },
                    }}
                  />
                </IonCol>
              </IonRow>
            </div>
          )}

          <div className="ion-margin-top">
            <IonButton
              onClick={goBack}
              mode="ios"
              expand="block"
              fill="outline"
              color="dark"
            >
              Regresar
            </IonButton>
            <Continue />
          </div>
        </IonGrid>
      </form>
    </div>
  );
};

export default DiagnosticoForm;
