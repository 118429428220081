export const isOdd = (n: number) => {
  return n % 2 === 1;
};

//https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(",");
  const match = arr[0].match(/:(.*?);/);
  const mime = match === null ? undefined : match[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function capitalizeEveryWord(str?: string) {
  return str
    ? str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
}

export function capitalizeFirstLetter(str?: string) {
  if (!str) return "";

  return str
    .split(/([.]\s+)/)
    .map((part, index) => {
      // Partes que no son los delimitadores (.)
      if (index % 2 === 0) {
        return part.charAt(0).toUpperCase() + part.slice(1);
      }
      // Los delimitadores (.) permanecen igual
      return part;
    })
    .join("");
}

export const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const removeSpecialCharacters = (str: string) => {
  return str.split("_").join("");
};

export const removeSpaces = (str: string): string => {
  return str.replace(/\s+/g, "");
};

export const cleanUndefined = (str: string) => {
  return str
    .split(",")
    .map((part) => part.trim())
    .filter((part) => part !== "undefined")
    .join(", ");
};
