import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
} from '@ionic/react';
import { useLocation } from 'react-router';

import './ErrorPage.scss';
import Logo from '../../assets/FielPetLogo.png';
import InfoLayout from '../../components/InfoLayout';

const ErrorPage: React.FC = () => {

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const data = searchParams.get("data") ?? "-";
  const link = <a href={`https://wa.me/5493412828296?text=Hola%2C%20quise%20completar%20el%20formulario%20número%20${data.split("-")[0]}%20del%20siniestro%20${data.split("-")[1]}%20y%20figura%20como%20completado`} target="_blank">WhatsApp</a>;
  const title = <div>El formulario {data.split("-")[0]} correspondiente al siniestro {data.split("-")[1]} ya fue completado. Si cree que se trata de un error o tiene alguna alguna duda comuníquese con nosotros mediante este link de {link}.</div>

  return (
    <IonPage className="error-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent className="ion-padding">
        <InfoLayout title={title} hideSubtitle={true} />
      </IonContent>
    </IonPage>
  );
};

export default ErrorPage;
