import BBVA from "../../assets/BBVA.png";
import Logo from "../../assets/FielPetLogo.png";
import Spinner from "../../components/Spinner";
import { useVetFormLinkData } from "../../hooks/useVetFormLinkData";
import { Veterinario } from "../../interfaces/forms/Veterinario";
import { VetFormLinkDataAddFormType } from "../../interfaces/forms/VetFormLinkData";
import "./FiestaPage.scss";
import { IonButton, IonContent, IonHeader, IonPage } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";

const FiestaPage: React.FC = () => {
  const history = useHistory();
  const { vetFormLinkDataToSend } = useVetFormLinkData();

  const [loading, setLoading] = useState<boolean>(false);

  const handleFormSubmission = async () => {
    try {
      setLoading(true);
      const vetFormLinkData_toSend: Veterinario = {
        id: "1",
        nombre: "Vete",
        apellido: "Prueba",
        colegio: "CABA",
        dni: "37123234",
        matricula: "123",
        email: "vetePrueba@dawer.com",
        telefono: "3416123456",
        domicilio: "",
        tipo: "",
      };
      const vetFormLinkDataObj: VetFormLinkDataAddFormType = {
        veterinario: vetFormLinkData_toSend,
        derivaciones: [],
        siniestroId: "1",
        fechaAtencion: "2023-11-21T01:32:08.662-03:00",
      };

      const response = await vetFormLinkDataToSend(vetFormLinkDataObj);
      const formId = response["data"];

      if (formId) {
        history.push(`/form?ref=${formId}`);
      } else {
        console.error("Failed to get form ID from response.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IonPage className="fiesta-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} alt="Fielpet logo" />
        <img src={BBVA} alt="BBVA logo" className="BBVA" />
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="title">
          Completa la historia clínica con un presunto caso de
          <b> conjuntivitis</b> y participá de nuestros sorteos.
        </div>

        <div className="description ion-margin-vertical">
          ¡Ganá 50.000 puntos BBVA y volá a donde quieras!
        </div>

        <div className="ion-padding-top">
          <IonButton
            mode="ios"
            expand="block"
            onClick={handleFormSubmission}
            disabled={loading}
          >
            {loading ? <Spinner color="light" /> : "Completar el formulario"}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FiestaPage;
