import useSWR from 'swr';

import { GET_SINTOMAS_MASCOTA } from '../axios/constants';
import { getSintomasMascota } from '../axios/getSintomasMascota';

export const useGetSintomasMascota = (siniestroId: string) => {
  const { data, error, mutate } = useSWR(
    [GET_SINTOMAS_MASCOTA, siniestroId],
    (url) => getSintomasMascota(url, siniestroId),
    {
      refreshInterval:0,
      refreshWhenHidden:false,
      refreshWhenOffline:false,
      revalidateOnFocus:false,
      revalidateOnMount:false,
      revalidateOnReconnect:false
    }
  );

  return { data, mutate, error };
};
