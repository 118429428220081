import { Cirugia, getEmptyCirugias } from "../interfaces/forms/Cirugia";
import { Consulta, getEmptyConsulta } from "../interfaces/forms/Consulta";
import {
  DatosUsuario,
  getEmptyDatosUsuario,
} from "../interfaces/forms/DatosUsuario";
import {
  Diagnostico,
  getEmptyDiagnostico,
} from "../interfaces/forms/Diagnostico";
import { Estudio, getEmptyEstudios } from "../interfaces/forms/Estudio";
import {
  Indicacion,
  getEmptyIndicaciones,
} from "../interfaces/forms/Indicacion";
import {
  Internacion,
  getEmptyInternaciones,
} from "../interfaces/forms/Internacion";
import {
  Medicamento,
  getEmptyMedicamentos,
} from "../interfaces/forms/Medicamento";
import { Practica, getEmptyPracticas } from "../interfaces/forms/Practica";
import { Sintoma, getEmptySintomas } from "../interfaces/forms/Sintoma";
import {
  Veterinario,
  getEmptyVeterinario,
} from "../interfaces/forms/Veterinario";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface SubVetFormContextType {
  datosUsuario: DatosUsuario;
  setDatosUsuario: React.Dispatch<React.SetStateAction<DatosUsuario>>;
  veterinario: Veterinario;
  setVeterinario: React.Dispatch<React.SetStateAction<Veterinario>>;
  consulta: Consulta;
  setConsulta: React.Dispatch<React.SetStateAction<Consulta>>;
  sintomas: Sintoma[];
  setSintomas: React.Dispatch<React.SetStateAction<Sintoma[]>>;
  medicamentos: Medicamento[];
  setMedicamentos: React.Dispatch<React.SetStateAction<Medicamento[]>>;
  practicas: Practica[];
  setPracticas: React.Dispatch<React.SetStateAction<Practica[]>>;
  estudios: Estudio[];
  setEstudios: React.Dispatch<React.SetStateAction<Estudio[]>>;
  cirugias: Cirugia[];
  setCirugias: React.Dispatch<React.SetStateAction<Cirugia[]>>;
  internaciones: Internacion[];
  setInternaciones: React.Dispatch<React.SetStateAction<Internacion[]>>;
  indicaciones: Indicacion[];
  setIndicaciones: React.Dispatch<React.SetStateAction<Indicacion[]>>;
  diagnostico: Diagnostico;
  setDiagnostico: React.Dispatch<React.SetStateAction<Diagnostico>>;
  svfsWithMedia: SvfsWithMediaType;
}

const SubVetFormContext = createContext<SubVetFormContextType | undefined>(
  undefined
);

interface SubVetFormProviderProps {
  children: ReactNode;
}

type SvfsWithMediaType = {
  sintomas: Sintoma[];
  practicas: Practica[];
  estudios: Estudio[];
};

const SubVetFormProvider: React.FC<SubVetFormProviderProps> = ({
  children,
}) => {
  const [datosUsuario, setDatosUsuario] =
    useState<DatosUsuario>(getEmptyDatosUsuario);
  const [veterinario, setVeterinario] =
    useState<Veterinario>(getEmptyVeterinario);
  const [consulta, setConsulta] = useState<Consulta>(getEmptyConsulta);
  const [sintomas, setSintomas] = useState<Sintoma[]>(getEmptySintomas);
  const [medicamentos, setMedicamentos] =
    useState<Medicamento[]>(getEmptyMedicamentos);
  const [practicas, setPracticas] = useState<Practica[]>(getEmptyPracticas);
  const [estudios, setEstudios] = useState<Estudio[]>(getEmptyEstudios);
  const [cirugias, setCirugias] = useState<Cirugia[]>(getEmptyCirugias);
  const [internaciones, setInternaciones] = useState<Internacion[]>(
    getEmptyInternaciones
  );
  const [indicaciones, setIndicaciones] =
    useState<Indicacion[]>(getEmptyIndicaciones);
  const [diagnostico, setDiagnostico] =
    useState<Diagnostico>(getEmptyDiagnostico);

  const [svfsWithMedia, setSvfsWithMedia] = useState<SvfsWithMediaType>({
    sintomas: [],
    practicas: [],
    estudios: [],
  });

  useEffect(() => {
    setSvfsWithMedia({
      sintomas,
      practicas,
      estudios,
    });
  }, [estudios, practicas, sintomas]);

  return (
    <SubVetFormContext.Provider
      value={{
        datosUsuario,
        setDatosUsuario,
        veterinario,
        setVeterinario,
        consulta,
        setConsulta,
        sintomas,
        setSintomas,
        medicamentos,
        setMedicamentos,
        practicas,
        setPracticas,
        estudios,
        setEstudios,
        cirugias,
        setCirugias,
        internaciones,
        setInternaciones,
        indicaciones,
        setIndicaciones,
        diagnostico,
        setDiagnostico,
        svfsWithMedia,
      }}
    >
      {children}
    </SubVetFormContext.Provider>
  );
};

const useSubVetForm = (): SubVetFormContextType => {
  const context = useContext(SubVetFormContext);
  if (context === undefined) {
    throw new Error("useSubVetForm must be used within a SubVetFormProvider");
  }
  return context;
};

export { SubVetFormProvider, useSubVetForm };
