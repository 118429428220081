
import { AxiosResponse } from 'axios';

import axiosInstance from './';
import { GET_VETFORMLINK } from './constants';

export const getVetFormLink = async (ref: string) => {
  try {
    const {data : a}: AxiosResponse<any> = await axiosInstance.get(GET_VETFORMLINK, {params: {
        ref,
      },});
    return a;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
