import React from 'react';
import { IonItem, IonLabel, IonDatetime } from '@ionic/react';
import { Controller } from 'react-hook-form';
import { FormDateProps } from './types';
import './FormDate.scss';
import { getErrors } from '../../utils/Form';

const FormDate: React.FC<FormDateProps> = ({
  formMethods,
  fieldProps,
  validationProps,
  date
}) => {
  const { control, errors } = formMethods;
  const { name, label, placeholder } = fieldProps;
  const { errorMessage } = validationProps || {};
  const { min, max } = date || {};

  return (
    <Controller
      name={name}
      control={control}
      errors={errors}
      rules={{
        required: {
          value: validationProps ? true : false,
          message: errorMessage ?? "",
        }
      }}
      render={({ onChange, name, value }) => (
        <div className="form-date">
          <IonItem lines="inset" className="ion-no-padding">
            <IonLabel position="floating">
              {label}
            </IonLabel>
            <IonDatetime
              onIonChange={onChange}
              name={name}
              value={value}
              displayFormat="DD/MM/YYYY"
              doneText="Confirmar"
              cancelText="Cancelar"
              placeholder={placeholder ?? undefined}
              max={max ?? undefined}
              min={min ?? undefined}
            ></IonDatetime>
          </IonItem>
          <div
            className="form-date__error-message"
            style={getErrors(errors, name) ? { opacity: 1 } : undefined}
          >
            {getErrors(errors, name)?.message}
          </div>
        </div>
      )
      }
    />
  );
};

export default FormDate;