import React from "react";
import { IonApp, setupConfig, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import { HideHeaderContextProvider } from "./context/HideHeaderContext";
import { StorageContextProvider } from "./context/StorageContext";
import { TutorialProvider } from "./context/TutorialContext";
import VetForm from "./pages/vet-form/index";
import ConfirmPage from "./pages/confirm-page";
import ErrorPage from "./pages/error-page";
import Fiesta from "./pages/fiesta-page";
import Tigre from "./pages/tigre";
import "./styles/global.scss";

// Theme variables
import "./theme/variables.scss";

// Core CSS required for Ionic components to work properly
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";

// Basic CSS for apps built with Ionic
import "@ionic/react/css/normalize.css";

// Optional CSS utils that can be commented out
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import HomePage from "./pages/home-page";
import PolicyPage from "./pages/policy-page";

setupConfig({ swipeBackEnabled: false });

const App: React.FC = () => (
  <IonApp>
    <HideHeaderContextProvider>
      <StorageContextProvider>
        <TutorialProvider>
          <IonReactRouter>
            <IonRouterOutlet ionPage id='appoutlet' key='appoutlet'>
              <Route path="/" component={HomePage} />
              <Route path="/policy" component={PolicyPage} />
              <Route path="/form" component={VetForm} />
              <Route path="/confirm" component={ConfirmPage} />
              <Route path="/error" component={ErrorPage} />
              <Route path="/fiesta" component={Fiesta} />
              <Route path="/tigre" component={Tigre} />
            </IonRouterOutlet>
          </IonReactRouter>
        </TutorialProvider>
      </StorageContextProvider>
    </HideHeaderContextProvider>
  </IonApp>
);

export default App;
