import { useState } from 'react';
import { postVetFormLinkData as axiosPostVetFormLinkData } from '../axios/addVetFormLinkData';
import { VetFormLinkData } from '../interfaces/AddVetFormLinkData';

export const useVetFormLinkData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const vetFormLinkDataToSend = async (
    vetFormLinkData : VetFormLinkData
  ) => {
    setIsLoading(true);
    const rta = await axiosPostVetFormLinkData({
      vetFormLinkData,
    });
    setIsLoading(false);
    return rta;
  };

  return { isLoading, vetFormLinkDataToSend };
};
