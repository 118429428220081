import { AxiosResponse } from 'axios';

import axios from '.';
import { SubVetForm } from '../interfaces/SubVetForm';

export const getIndicaciones = async (url: string, id: string) => {
  try {
    const { data: indicaciones }: AxiosResponse<SubVetForm[]> = await axios.get(
      url,
      {
        params: {
          id,
        },
      }
    );
    return indicaciones;
  } catch (error) {
    console.log(error);
  }
};
