import { getVetForm as axiosGetVetForm } from "../axios/getVetForm";
import { postVetForm as axiosPostVetForm } from "../axios/vetForm";
import { Cirugia } from "../interfaces/forms/Cirugia";
import { Consulta } from "../interfaces/forms/Consulta";
import { Diagnostico } from "../interfaces/forms/Diagnostico";
import { Estudio } from "../interfaces/forms/Estudio";
import { Indicacion } from "../interfaces/forms/Indicacion";
import { Internacion } from "../interfaces/forms/Internacion";
import { Medicamento } from "../interfaces/forms/Medicamento";
import { Practica } from "../interfaces/forms/Practica";
import { Sintoma } from "../interfaces/forms/Sintoma";
import { Veterinario } from "../interfaces/forms/Veterinario";
import {
  formatIndicacionesToSend,
  formatSintomasToSend,
  removeProperty,
} from "../utils/Form";
import { useState } from "react";

type PostVetFormType = {
  vetFormId: string;
  veterinario: Veterinario;
  consulta: Consulta;
  sintomas: Sintoma[];
  medicamentos: Medicamento[];
  estudios: Estudio[];
  practicas: Practica[];
  cirugias: Cirugia[];
  internaciones: Internacion[];
  indicaciones: Indicacion[];
  diagnostico: Diagnostico;
};

export const useVetForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getVetForm = async (id: string) => {
    try {
      const vetForm = await axiosGetVetForm(id);
      return vetForm;
    } catch (error) {
      console.error(error);
    }
  };

  const addVetForm: (values: PostVetFormType) => any = async ({
    vetFormId,
    veterinario,
    consulta,
    sintomas,
    medicamentos,
    estudios,
    practicas,
    cirugias,
    internaciones,
    indicaciones,
    diagnostico,
  }) => {
    setIsLoading(true);

    const data = {
      vetFormId,
      veterinario,
      consulta,
      sintomas: formatSintomasToSend(sintomas, [
        "sintoma",
        "descripcion",
        "caracteristicasSeleccionadas",
        "fechaEstimada",
        "sintomaInforme",
      ]),
      medicamentos,
      estudios: removeProperty(estudios, "media"),
      practicas: removeProperty(practicas, "media"),
      cirugias,
      internaciones,
      indicaciones: formatIndicacionesToSend(indicaciones),
      diagnostico,
    };

    console.log("data: ", data);
    const response = await axiosPostVetForm(data);
    console.log("response: ", response);

    setIsLoading(false);
    return response;
  };

  return { getVetForm, addVetForm, isLoading };
};
