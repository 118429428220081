import "./Title.scss";
import { TitleProps } from "./types";
import React from "react";

const Title: React.FC<TitleProps> = ({ title, hidden }) => {
  return (
    <div className="title ion-margin-bottom">{!hidden && <>{title}</>}</div>
  );
};

export default Title;
