import { validationMoney } from "../../assets/validations/validationRegex";
import { useMedia } from "../../context/MediaContext";
import { useSubVetForm } from "../../context/SubVetFormContext";
import { Practica } from "../../interfaces/forms/Practica";
import {
  checkAndResetMedia,
  createBase64ArrayFromMedia,
  setFormValuesFromObject,
  submitSvf,
} from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import AddingSvfBtns from "../AddingSvfBtns";
import FileUploader from "../FileUploader";
import FormInput from "../FormInput";
import FormSelectPopover from "../FormSelectPopover";
import Title from "../Title";
import { PracticasFormProps } from "./types";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const PracticasForm: React.FC<PracticasFormProps> = ({
  formattedPracticas,
  index,
  setAddingSvfElement,
  setShowToast,
}) => {
  const { practicas, setPracticas } = useSubVetForm();
  const { currentMedia, setCurrentMedia, setShowNoFileInCurrentMediaToast } =
    useMedia();
  const { handleSubmit, control, errors, setValue, watch } =
    useForm<Practica>();
  const formMethods = { control, errors };
  const practica: string | undefined = watch("practica");

  const onSubmit = async (values: Practica) => {
    values.practicaId = String(
      formattedPracticas.indexOf(values.practica!) + 1
    );

    if (currentMedia) {
      const informe  = await createBase64ArrayFromMedia(currentMedia);
      values.media = currentMedia;
      values.practicaInforme = informe;
    }
    
    submitSvf(index, practicas, setPracticas, values, setShowToast);
  };

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(practicas[index], setValue);
      setCurrentMedia(practicas[index].media!);
    }
  }, [index]);

  //Reset currentMedia cuando hay media cargada con un tipo de estudio especifico
  useEffect(() => {
    checkAndResetMedia(
      practica,
      currentMedia,
      setCurrentMedia,
      setShowNoFileInCurrentMediaToast
    );
  }, [practica, currentMedia]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonGrid className="ion-no-padding">
        <Title
          title={index !== undefined ? "Editar practica" : "Nueva practica"}
        />

        <IonRow>
          <IonCol hidden>
            {/* Practica Id*/}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "practicaId",
                label: "",
              }}
            />
          </IonCol>
          <IonCol>
            {/* Practica */}
            <FormSelectPopover
              formMethods={formMethods}
              options={formattedPracticas}
              fieldProps={{
                name: "practica",
                label: "Práctica realizada",
              }}
              validationProps={{
                errorMessage: "Por favor seleccione una práctica",
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {/* Descripción */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "descripcion",
                label: "Descripción de la práctica",
                format: capitalizeFirstLetter,
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {/* Costo */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "costo",
                label: "Costo de la práctica",
                typeNumber: true,
              }}
              validationProps={{
                errorMessage: "Por favor ingrese un monto",
                pattern: validationMoney,
              }}
            />
          </IonCol>
        </IonRow>

        <FileUploader
          tag={{ type: "práctica", detail: practica! }}
          disabled={!practica && index === undefined}
        />

        <AddingSvfBtns
          label={index !== undefined ? "cambios" : "práctica"}
          action={() => {
            setAddingSvfElement(false);
          }}
        />
      </IonGrid>
    </form>
  );
};

export default PracticasForm;
