import React from "react";
import { IonIcon } from "@ionic/react";
import { pencil } from "ionicons/icons";
import moment from "moment";
import { Svfs_resumenProps } from "./types";
import './Svfs_resumen.scss';
import { Sintoma } from "../../interfaces/forms/vetForms/Sintoma";
import { Medicamento } from "../../interfaces/Medicamento";
import { Internacion } from "../../interfaces/forms/vetForms/Internacion";
import { Indicacion } from "../../interfaces/Indicacion";
import { Cirugia } from "../../interfaces/forms/vetForms/Cirugia";
import { Estudio } from "../../interfaces/forms/vetForms/Estudio";
import { Practica } from "../../interfaces/forms/vetForms/Practica";
import { capitalizeEveryWord, capitalizeFirstLetter } from "../../utils/General";
import { findStepIndexByValue } from "../../utils/Form";
import { formatDate } from "../../utils/DateUtilities";

const SVFs_resumen: React.FC<Svfs_resumenProps> = ({ SVFs, setFormStep, setIndex, setEditFromResumen }) => {

  const editIcon = <IonIcon icon={pencil} />

  const handleEdit = (value: string, index: number) => {
    setEditFromResumen(true);
    setIndex(index);
    const step = findStepIndexByValue(value);
    step && setFormStep(step);
  }

  const renderProperties = (object, excludedProps?: string[]) => {
    // Filtrar las propiedades que no son objetos
    const filteredProperties = Object.fromEntries(
      Object.entries(object).filter(([_, value]) => typeof value !== 'object')
    );

    return Object.entries(filteredProperties).map(([propKey, propValue], index) => {
      // Verificar si la propiedad está excluida
      if (!excludedProps?.includes(propKey)) {
        if (propKey === 'costo') {
          return (
            <div key={propKey} className="value">
              Costo: ${propValue}
            </div>
          );
        }
        else if (typeof propValue === 'string' && moment(propValue, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
          // Si la propiedad es una fecha con el formato especificado, llamar a la función formatDate
          return (
            <div key={propKey} className="value">
              {formatDate(propValue)}
            </div>
          );
        }
        else {
          // Otras propiedades
          const className = index === 0 ? 'firstValue' : 'value';
          return (
            <div key={propKey} className={className}>
              {propValue && (
                <div>
                  {capitalizeFirstLetter(String(propValue))}
                </div>
              )}
            </div>
          );
        }
      }
      // Omitir propiedades excluidas
      return null;
    });
  };

  const renderSintomas = (key: string, sintomas: Sintoma[]) => (
    <>
      <div className="key">{capitalizeFirstLetter(key)}</div>
      {sintomas.map((sintoma, index) => (
        <div key={index} className="box ion-margin-bottom">
          <div className="firstValue value">{capitalizeFirstLetter(sintoma.sintoma)}</div>
          <div className="value">{capitalizeFirstLetter(sintoma.descripcion)}</div>
          <div className="value">{capitalizeFirstLetter(sintoma.fechaEstimada)}</div>
          <div onClick={() => handleEdit(key, index)}>
            {editIcon}
          </div>
        </div>
      ))}
    </>
  );


  const renderInternaciones = (key: string, internaciones: Internacion[]) => (
    <>
      <div className="key">{capitalizeFirstLetter(key)}</div>
      {internaciones.map((internacion, index) => (
        <div key={index} className="box ion-margin-bottom">
          <div className="firstValue value">Fecha de inicio: {formatDate(internacion.fechaInicio)}</div>
          <div className="value">Fecha de finalización: {formatDate(internacion.fechaFin)}</div>
          {/* Renderizar propiedades excluyendo 'fechaInicio' y 'fechaFin' */}
          {renderProperties(internacion, ['fechaInicio', 'fechaFin'])}
          <div onClick={() => handleEdit(key, index)}>
            {editIcon}
          </div>
        </div>
      ))}
    </>
  );

  const renderIndicaciones = (key: string, indicaciones: Indicacion[]) => (
    <div className="ion-margin-bottom">
      <div className="key">{capitalizeFirstLetter(key)}</div>
      {indicaciones.map((indicacion, index) => (
        <div key={index} className="box ion-margin-bottom">
          {indicacion.tipoIndicacion === 'Farmacológica' ? (
            <div>
              <div className="firstValue">{capitalizeEveryWord(indicacion.medicamento)}</div>
              <div className="value">Dosaje: {indicacion.dosaje}</div>
              <div className="value">Frecuencia: {indicacion.frecuencia}</div>
              <div className="value">Duración: {indicacion.duracion}</div>
            </div>
          ) : (
            <>
              <div>{renderProperties(indicacion)}</div>
              {indicacion.suggestedVets && indicacion.suggestedVets.length > 0 && (
                <div key={index} className="ion-margin-bottom">
                  <div className="value suggestedVets">Profesionales sugeridos:</div>
                  {indicacion.suggestedVets.map((vet, vetIndex) => (
                    <div key={vetIndex} className="value">
                      {vet.nombre} {vet.apellido} {vet.email}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          <div onClick={() => handleEdit(key, index)}>
            {editIcon}
          </div>
        </div>
      ))}
    </div>
  );


  const renderSVF = (key: string, SVF: (Medicamento | Practica | Estudio | Cirugia)[]) => (
    <>
      <div className="key">{capitalizeFirstLetter(key)}</div>
      {SVF.map((e, index) => (
        <div key={index} className="box ion-margin-bottom">
          {renderProperties(e)}
          <div onClick={() => handleEdit(key, index)}>
            {editIcon}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <div className="svfs-resumen">
      {Object.entries(SVFs).map(([key, value], svfIndex) => {
        switch (key) {
          case "sintomas":
            return (
              <div key={`svf_${svfIndex}`}>
                {/* Renderizar solo si value tiene elementos */}
                {value && value.length > 0 && renderSintomas(key, value as Sintoma[])}
              </div>
            );
          case "internaciones":
            return (
              <div key={`svf_${svfIndex}`}>
                {/* Renderizar solo si value tiene elementos */}
                {value && value.length > 0 && renderInternaciones(key, value as Internacion[])}
              </div>
            );
          case "indicaciones":
            return (
              <div key={`svf_${svfIndex}`}>
                {value && value.length > 0 && renderIndicaciones(key, value as Indicacion[])}
              </div>
            );
          default:
            return (
              <div key={`svf_${svfIndex}`}>
                {value && value.length > 0 && renderSVF(key, value as any)}
              </div>
            );
        }
      })}
    </div>
  );
};

export default SVFs_resumen;
