import {
  ValidationMail,
  validationAlphanumeric,
  validationDNI,
  validationName,
  validationPhone,
} from "../../assets/validations/validationRegex";
import { useSubVetForm } from "../../context/SubVetFormContext";
import {
  Veterinario as VeterinarioInterface,
  getEmptyVeterinario,
} from "../../interfaces/forms/Veterinario";
import { colegios } from "../../utils/Colegios";
import { capitalizeEveryWord } from "../../utils/General";
import Continue from "../Continue";
import FormInput from "../FormInput";
import FormSelectPopover from "../FormSelectPopover";
import Title from "../Title";
import { VeterinarioProps } from "./types";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useForm } from "react-hook-form";

const Veterinario: React.FC<VeterinarioProps> = ({ goForward }) => {
  const { veterinario, setVeterinario } = useSubVetForm();
  const { handleSubmit, control, errors } = useForm<VeterinarioInterface>({
    defaultValues: veterinario ?? getEmptyVeterinario(),
  });

  const formMethods = { control, errors };

  const onSubmit = (values: VeterinarioInterface) => {
    setVeterinario(values);
    goForward();
  };

  return (
    <div className="veterinario ion-margin animate__animated animate__fadeInRight">
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ion-no-padding">
          <Title title="Datos del veterinario" />
          <IonRow>
            <IonCol className="ion-padding-end">
              {/* Nombre */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "nombre",
                  label: "Nombre",
                  format: capitalizeEveryWord,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese su nombre",
                  pattern: validationName,
                }}
              />
            </IonCol>

            <IonCol>
              {/* Apellido */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "apellido",
                  label: "Apellido",
                  format: capitalizeEveryWord,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese su apellido",
                  pattern: validationName,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="ion-padding-end">
              {/* Colegio */}
              <FormSelectPopover
                formMethods={formMethods}
                options={colegios}
                fieldProps={{
                  name: "colegio",
                  label: "Colegio veterinario",
                }}
                validationProps={{
                  errorMessage: "Por favor seleccione su colegio",
                }}
              />
            </IonCol>

            <IonCol>
              {/* DNI */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "dni",
                  label: "DNI",
                  typeNumber: true,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese su DNI",
                  pattern: validationDNI,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="ion-padding-end">
              {/* Email */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "email",
                  label: "Email",
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese su email",
                  pattern: ValidationMail,
                }}
              />
            </IonCol>

            <IonCol>
              {/* Matrícula */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "matricula",
                  label: "Matricula",
                  typeNumber: true,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese su matricula",
                  pattern: validationAlphanumeric,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-margin-bottom">
            <IonCol className="ion-padding-end">
              {/* Teléfono */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "telefono",
                  label: "Teléfono",
                  typeNumber: true,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese su teléfono",
                  pattern: validationPhone,
                }}
              />
            </IonCol>

            <IonCol>
              {/* Domicilio de la clínica */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "domicilio",
                  label: "Domicilio de la clínica",
                  placeholder: "Opcional",
                  format: capitalizeEveryWord,
                }}
              />
            </IonCol>
          </IonRow>

          <div className="ion-padding-top">
            <Continue />
          </div>
        </IonGrid>
      </form>
    </div>
  );
};

export default Veterinario;
