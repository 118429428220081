import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTutorialStorage } from '../hooks/useTutorialStorage';

class VoidContext implements TutorialContext {
  get tutorialDoneStatus(): never {
    throw new Error('Cannot consume context outside of provider');
  }
  get setTutorialDoneStatus(): never {
    throw new Error('Cannot consume context outside of provider');
  }
}

interface TutorialContext {
  tutorialDoneStatus: boolean | undefined;
  setTutorialDoneStatus: (value: boolean | undefined) => void;
}

export const TutorialContext = createContext<TutorialContext>(
  new VoidContext()
);

export const TutorialProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<boolean | undefined>();
  const { tutorialStatus, setTutorialStatus } = useTutorialStorage();

  useEffect(() => {
    setState(tutorialStatus);
  }, [tutorialStatus, setState]);

  const setTutorialDoneStatus = useCallback(
    (value: boolean | undefined) => {
      setState(value);
      setTutorialStatus(value);
    },
    [setState, setTutorialStatus]
  );

  return (
    <TutorialContext.Provider
      value={{ tutorialDoneStatus: state, setTutorialDoneStatus }}
    >
      {children}
    </TutorialContext.Provider>
  );
};
