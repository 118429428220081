import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { MedicamentoCardProps } from './types';
import './MedicamentoCard.scss';
import { capitalizeFirstLetter } from "../../utils/General";

const MedicamentoCard: React.FC<MedicamentoCardProps> = ({ medicamento }) => {
  const { nombre_comercial, drogas, tipo_accion, laboratorio, presentacion, via } = medicamento;

  return (
    <div
      className="medicamento-card ion-margin-vertical"
    >
      {
        medicamento.nombre_comercial &&
        <IonGrid>
          <IonRow hidden={!nombre_comercial}>
            <IonCol className="ion-text-start">
              Nombre
            </IonCol>
            <IonCol className="ion-text-end">
              {capitalizeFirstLetter(nombre_comercial)}
            </IonCol>
          </IonRow>

          <IonRow hidden={!drogas}>
            <IonCol className="ion-text-start">
              Droga
            </IonCol>
            <IonCol className="ion-text-end">
              {capitalizeFirstLetter(drogas)}
            </IonCol>
          </IonRow>

          <IonRow hidden={!tipo_accion}>
            <IonCol className="ion-text-start">
              Tipo de acción
            </IonCol>
            <IonCol className="ion-text-end">
              {capitalizeFirstLetter(tipo_accion)}
            </IonCol>
          </IonRow>

          <IonRow hidden={!laboratorio}>
            <IonCol className="ion-text-start">
              Laboratorio
            </IonCol>
            <IonCol className="ion-text-end">
              {capitalizeFirstLetter(laboratorio)}
            </IonCol>
          </IonRow>

          <IonRow hidden={!presentacion}>
            <IonCol className="ion-text-start">
              Presentación
            </IonCol>
            <IonCol className="ion-text-end">
              {capitalizeFirstLetter(presentacion)}
            </IonCol>
          </IonRow>

          <IonRow hidden={!via}>
            <IonCol className="ion-text-start">
              Vía de administración
            </IonCol>
            <IonCol className="ion-text-end">
              {capitalizeFirstLetter(via)}
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    </div>
  )
}

export default MedicamentoCard;