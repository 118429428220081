import { AxiosResponse } from 'axios';

import axiosInstance from '.';
import { Enfermedad } from '../interfaces/Enfermedad';
import { GET_ENFERMEDADES } from './constants';

export const getEnfermedades = async () => {
  try {
    const { data: enfermedades }: AxiosResponse<Enfermedad[]> = await axiosInstance.get(
      GET_ENFERMEDADES
    );
    return enfermedades;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
