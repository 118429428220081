import { PhotoItem } from "../interfaces/PhotoItem";
import { removeAccents, removeSpaces } from "../utils/General";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Preferences } from "@capacitor/preferences";
import { isPlatform } from "@ionic/react";
import { useEffect } from "react";

const PHOTOS_PREF_KEY = "photos";

interface UsePhotoProps {
  photos: PhotoItem[];
  setPhotos: React.Dispatch<React.SetStateAction<PhotoItem[]>>;
  setTempFile: React.Dispatch<React.SetStateAction<PhotoItem | undefined>>;
  currentMedia: PhotoItem[];
  setShowDuplicateInCurrentMediaToast: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const usePhoto = ({
  photos,
  setPhotos,
  setTempFile,
  currentMedia,
  setShowDuplicateInCurrentMediaToast,
}: UsePhotoProps) => {
  useEffect(() => {
    const loadSaved = async () => {
      const { value } = await Preferences.get({ key: PHOTOS_PREF_KEY });
      const photosInPref: PhotoItem[] = value ? JSON.parse(value) : [];

      if (!isPlatform("hybrid")) {
        for (let photo of photosInPref) {
          try {
            // Verificar si el archivo existe
            const fileExists = await Filesystem.stat({
              path: photo.fileName,
              directory: Directory.Data,
            });

            if (fileExists && fileExists.type === "file") {
              // Si el archivo existe, leerlo
              const file = await Filesystem.readFile({
                path: photo.fileName,
                directory: Directory.Data,
              });
              photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
            } else {
              console.error(`El archivo ${photo.fileName} no existe.`);
            }
          } catch (error) {
            console.error(
              `Error al verificar el archivo ${photo.fileName}:`,
              error
            );
          }
        }
      }
      setPhotos(photosInPref);
    };

    loadSaved();
  }, []);

  useEffect(() => {
    if (photos.length > 0) {
      Preferences.set({ key: PHOTOS_PREF_KEY, value: JSON.stringify(photos) });
    }
  }, [photos]);

  const takePhoto = async (
    source: string,
    tag: {
      type: string;
      detail: string;
    }
  ) => {
    try {
      const cameraSource =
        source === "CAMERA" ? CameraSource.Camera : CameraSource.Photos;
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: cameraSource,
        quality: 100,
      });

      if (photo === null) {
        console.log("El usuario canceló la acción de tomar la foto.");
        return;
      }

      // Generar nombre de archivo único
      const generateUniqueFileName = () => {
        let formattedFileName = removeSpaces(
          removeAccents(tag.detail)
        ).toLowerCase();
        let baseFileName = formattedFileName;
        let fileName = `${formattedFileName}_img.jpeg`;
        let fileNumber = 1;

        // Verificar si el nombre de archivo ya existe en currentMedia
        while (currentMedia.some((media) => media.fileName === fileName)) {
          formattedFileName = `${baseFileName}${fileNumber}`;
          fileName = `${formattedFileName}_img.jpeg`;
          fileNumber++;
        }

        return fileName;
      };

      const fileName = generateUniqueFileName();

      const savedImage: PhotoItem | undefined = await savePhoto(
        photo,
        fileName,
        tag
      );

      savedImage && setTempFile(savedImage);
    } catch (error) {
      console.error("Error al tomar la foto: ", error);
    }
  };

  const savePhoto = async (
    photo: Photo,
    fileName: string,
    tag: {
      type: string;
      detail: string;
    }
  ): Promise<PhotoItem | undefined> => {
    let base64data: string;

    if (isPlatform("hybrid")) {
      const file = await Filesystem.readFile({
        path: photo.path!,
      });

      if (typeof file.data === "string") {
        base64data = file.data;
      } else {
        throw new Error("File data no es un string");
      }
    } else {
      // Desktop
      base64data = await base64FromPath(photo.webPath!);
    }

    // Verificar duplicado
    const id: string = idFromBase64(base64data);
    // const foundedId: boolean = currentMedia.some(
    //   (element) => element.id === id
    // );

    // if (foundedId) {
    //   setShowDuplicateInCurrentMediaToast(true);
    //   return;
    // }

    const formattedTag = {
      ...tag,
      type: removeAccents(tag.type),
    };

    const savedImage = await Filesystem.writeFile({
      path: fileName,
      directory: Directory.Data,
      data: base64data,
    });

    if (isPlatform("hybrid")) {
      return {
        id: id,
        fileName: savedImage.uri,
        webviewPath: Capacitor.convertFileSrc(savedImage.uri),
        tag: formattedTag,
      };
    }

    return {
      id: id,
      fileName: fileName,
      webviewPath: photo.webPath,
      tag: formattedTag,
    };
  };

  const base64FromPath = async (path: string): Promise<string> => {
    const response = await fetch(path);
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("El método no retorno un string");
        }
      };
      reader.readAsDataURL(blob);
    });
  };

  // Genera id único segun contenido de imagen para evitar duplicados en un mismo elemento de un SVF
  const crypto = require("crypto");
  const idFromBase64 = (base64String: string): string => {
    // Decodificar el string base64 a un buffer
    const buffer = Buffer.from(base64String, "base64");

    // Generar un hash usando SHA-256
    const hash = crypto.createHash("sha256").update(buffer).digest("base64");

    // Convertir el hash a base64url-safe
    const base64url = hash
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");

    return base64url.substring(0, 10);
  };

  const deletePhoto = async (fileName: string) => {
    setPhotos(photos.filter((photo) => photo.fileName !== fileName));

    await Filesystem.deleteFile({
      path: fileName,
      directory: Directory.Data,
    });
  };

  const clearPhotos = async () => {
    setPhotos([]);
    await Preferences.remove({ key: PHOTOS_PREF_KEY });
  };

  return {
    takePhoto,
    deletePhoto,
    clearPhotos,
  };
};
