import { useState, useEffect } from 'react';
import { getPracticas as axiosGetPracticas } from '../axios/getPracticas';
import { Practicas } from '../interfaces/Practicas';

export const usePracticas = () => {
  const [practicas, setPracticas] = useState<Practicas[]>([]);
  const [allPracticas, setAllPracticas] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPracticas = async () => {
    setIsLoading(true);
    const practica = await axiosGetPracticas();
    setPracticas(practica)
    setAllPracticas(practica.map((e)=>e.tipo_practica))
    setPracticas(practica)
    setIsLoading(false);
  };

  useEffect(() => {
    getPracticas();
  }, []);
  // Se genera el array de string de allPracticas ya que donde se consumen se necesita un array de strings
  return { practicas, allPracticas, isLoading };
};
