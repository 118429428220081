import React from "react";
import { AlertProps } from './types';

import './Alert.scss';
import { IonAlert } from "@ionic/react";

const Alert: React.FC<AlertProps> = ({ isOpen, setIsOpen, subHeader, buttonsLabel, actions, didDismissActions }) => {
  return (
    <div
      className="alert"
    >
      <IonAlert
        mode="ios"
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false);
          didDismissActions && didDismissActions();
        }}
        header={"Aviso"}
        subHeader={subHeader}
        cssClass="cssClass"
        buttons={[
          {
            text: buttonsLabel.secondary,
            handler: actions.secondary,
            cssClass: "cssClass",
          },
          {
            text: buttonsLabel.primary,
            handler: actions.primary,
          },
        ]}
      />
    </div>
  )
}

export default Alert;