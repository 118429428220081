import { MediaProvider } from "./context/MediaContext";
import { StorageContextProvider } from "./context/StorageContext";
import { SubVetFormProvider } from "./context/SubVetFormContext";
import { TutorialProvider } from "./context/TutorialContext";
import ConfirmPage from "./pages/confirm";
import ErrorPage from "./pages/error";
import Fiesta from "./pages/fiesta";
import HomePage from "./pages/home";
import PolicyPage from "./pages/policy";
import SorteoPage from "./pages/sorteo";
import Tigre from "./pages/tigre";
import VetForm from "./pages/vet-form/index";
import "./styles/global.scss";
import "./theme/variables.scss";
import { IonAlert, IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useState } from "react";
import { Prompt, Route } from "react-router-dom";

const App: React.FC = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  return (
    <div translate="no">
      <IonApp>
        <StorageContextProvider>
          <TutorialProvider>
            <SubVetFormProvider>
              <MediaProvider>
                <IonReactRouter>
                  <IonRouterOutlet ionPage id="appoutlet" key="appoutlet">
                    <Route path="/" component={HomePage} exact />
                    <Route path="/policy" component={PolicyPage} exact />
                    <Route path="/form" component={VetForm} exact />
                    <Route path="/confirm" component={ConfirmPage} exact />
                    <Route path="/error" component={ErrorPage} exact />
                    <Route path="/fiesta" component={Fiesta} exact />
                    <Route path="/tigre" component={Tigre} exact />
                    <Route path="/sorteo" component={SorteoPage} exact />
                  </IonRouterOutlet>
                  <Prompt
                    message={(location, action) => {
                      if (action === "POP") {
                        setShowAlert(true);
                        return false;
                      }
                      return true;
                    }}
                  />
                </IonReactRouter>
              </MediaProvider>
            </SubVetFormProvider>
          </TutorialProvider>
        </StorageContextProvider>

        <IonAlert
          mode="ios"
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header="Advertencia"
          message="¿Estás seguro de abandonar el formulario? Los cambios no guardados se perderán."
          buttons={[
            {
              text: "Cancelar",
              handler: () => setShowAlert(false),
            },
            {
              text: "Aceptar",
              handler: () =>
                window.location.replace(
                  "https://fielvet.mascotasegura.com.ar/fiesta"
                ),
            },
          ]}
        />
      </IonApp>
    </div>
  );
};

export default App;
