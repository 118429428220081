import React, { useEffect } from "react";
import {
  IonPopover,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { ConfirmStepProps } from "./types";
import "./ConfirmStep.scss";
import Ask from "../../assets/askVeterinary.png"
import { findStepIndexByValue, isWithinThreshold, stepsData } from "../../utils/Form";

const ConfirmStep: React.FC<ConfirmStepProps> = ({
  popoverState,
  setShowPopover,
  confirmedSvf,
  setConfirmSvf,
  editingFromResumen,
  formStep,
  goForward,
  goBack,
}) => {

  const show = !confirmedSvf && !editingFromResumen;

  useEffect(() => {
    setShowPopover({ showPopover: isWithinThreshold(formStep), event: undefined })
  }, [formStep])

  return (
    <>
      {show &&
        <IonPopover
          cssClass="confirm-step-popover"
          event={popoverState.event}
          isOpen={popoverState.showPopover}
          backdropDismiss={false}
        >
          <IonGrid>
            <IonRow>
              <IonCol>
                <img src={Ask} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <p>{stepsData[formStep]?.popoverLabel}</p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonButton mode="ios" expand="block" onClick={() => setConfirmSvf(true)}>
                  Si
                </IonButton>
              </IonCol>

              <IonCol size="12">
                <IonButton
                  mode="ios"
                  expand="block"
                  color="dark"
                  onClick={goForward}
                >
                  {formStep === findStepIndexByValue("sintomas") ? "No / Desconozco" : "No"}
                </IonButton>
              </IonCol>

              <IonCol size="12" className="goBack">
                <IonButton mode="ios" expand="block" fill="outline" color="dark" onClick={goBack}>
                  Regresar
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonPopover >
      }
    </>
  );
};

export default ConfirmStep;
