import React from "react";
import { AddingSvfBtnsProps } from './types';

import './AddingSvfBtns.scss';
import { IonButton } from "@ionic/react";

const AddingSvfBtns: React.FC<AddingSvfBtnsProps> = ({ label, action, hideBackBtn, disableSubmitBtn }) => {
  return (
    <div className="adding-svf-btns ion-margin-top">
      <IonButton onClick={action} hidden={hideBackBtn} mode="ios" expand="block" color="dark" fill="outline">Regresar</IonButton>
      <IonButton type="submit" disabled={disableSubmitBtn} mode="ios" expand="block">Guardar {label}</IonButton>
    </div>
  )
}

export default AddingSvfBtns;