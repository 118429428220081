import { AxiosResponse } from 'axios';
import axiosInstance from '.';
import { GET_VETFORM_DATA } from './constants';

export const getVetForm = async (id: string) => {
  try {
    const {data : a}: AxiosResponse<any> = await axiosInstance.get(GET_VETFORM_DATA, {params: {
        id,
      },});
    return a;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
