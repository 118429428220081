import {
  validationData,
  validationMoney,
} from "../../assets/validations/validationRegex";
import { useSubVetForm } from "../../context/SubVetFormContext";
import { Cirugia } from "../../interfaces/forms/Cirugia";
import { setFormValuesFromObject, submitSvf } from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import AddingSvfBtns from "../AddingSvfBtns";
import FormInput from "../FormInput";
import Title from "../Title";
import { CirugiasFormProps } from "./types";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const CirugiasForm: React.FC<CirugiasFormProps> = ({
  index,
  setAddingSvfElement,
  setShowToast,
}) => {
  const { cirugias, setCirugias } = useSubVetForm();
  const { handleSubmit, control, errors, setValue } = useForm<Cirugia>();
  const formMethods = { control, errors };

  const onSubmit = (values: Cirugia) => {
    submitSvf(index, cirugias, setCirugias, values, setShowToast);
  };

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(cirugias[index], setValue);
    }
  }, [index]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonGrid className="ion-no-padding">
        <Title
          title={index !== undefined ? "Editar cirugía" : "Nueva cirugía"}
        />

        <IonRow>
          <IonCol>
            {/* Cirugía */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "cirugia",
                label: "Cirugía realizada",
                format: capitalizeFirstLetter,
              }}
              validationProps={{
                errorMessage: "Por favor ingrese una descripción",
                pattern: validationData,
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {/* Descripción */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "descripcion",
                label: "Descripción de la cirugía",
                format: capitalizeFirstLetter,
              }}
              validationProps={{
                errorMessage: "Por favor ingrese una descripción",
                pattern: validationData,
              }}
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol>
            {/* Costo */}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "costo",
                label: "Costo de la cirugía",
                typeNumber: true,
              }}
              validationProps={{
                errorMessage: "Por favor ingrese un monto",
                pattern: validationMoney,
              }}
            />
          </IonCol>
        </IonRow>

        <AddingSvfBtns
          label={index !== undefined ? "cambios" : "cirugía"}
          action={() => {
            setAddingSvfElement(false);
          }}
        />
      </IonGrid>
    </form>
  );
};

export default CirugiasForm;
