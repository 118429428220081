import "./Step.scss";
import { StepProps } from "./types";
import React, { useEffect } from "react";

const Step: React.FC<StepProps> = ({ title, body, hint, callback }) => {
  useEffect(() => {
    callback?.();
  }, [callback]);

  return (
    <>
      <div className="step__title">{title}</div>
      <div className="step__body">{body}</div>
      <div className="step__hint">{hint}</div>
    </>
  );
};
export default Step;
