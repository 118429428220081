import { getEnfermedades as axiosGetEnfermedades } from "../axios/getEnfermedades";
import { SearchbarOption } from "../components/Searchbar/types";
import { useState, useEffect } from "react";

export const useEnfermedad = () => {
  //TODO type
  const [searchableEnfermedades, setSearchableEnfermedades] = useState<any[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getEnfermedades = async () => {
    setIsLoading(true);
    const enfermedades = await axiosGetEnfermedades();
    const search_enfermedades = enfermedades.map(({ nombre, sinonimos }) => ({
      nombre,
      sinonimos,
    }));
    setSearchableEnfermedades(
      search_enfermedades.map(({ nombre, sinonimos }) => {
        if (sinonimos !== undefined) {
          const mergedValue = sinonimos
            ? [nombre, ...sinonimos].join("-")
            : nombre;
          return { value: mergedValue, label: nombre };
        } else {
          return { value: nombre, label: nombre };
        }
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getEnfermedades();
  }, []);

  return { searchableEnfermedades, isLoading };
};
