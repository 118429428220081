import React, { useEffect } from "react";

import { SubVetFormProps } from './types';
import './SubVetForm.scss';
import { SVF_threshold, getEmptyFieldProps, stepsData } from "../../utils/Form";

import Title from "../Title";
import SvfElement from "../SvfElement";
import RegularOrIndicacion from "../RegularOrIndicacion";
import { IonButton } from "@ionic/react";
import Continue from "../Continue";

const SubVetForm: React.FC<SubVetFormProps> = ({
  state,
  setState,
  form,
  formStep,
  prevSvf,
  setPrevSvf,
  setIndex,
  setShowPopover,
  confirmedSvf,
  setConfirmSvf,
  addingSvfElement,
  setAddingSvfElement,
  showSvfOptions,
  setShowSvfOptions,
  hideSvfOptions,
  setCurrentSvfHasLength,
  setShowToast1: setShowToast,
  veterinario,
  goForward,
}) => {
  const fieldProps = stepsData[formStep]?.fieldProps || getEmptyFieldProps();
  const { nombre, apellido } = veterinario;
  const vetName = nombre + " " + apellido;

  const {
    Svf_title,
    newLabel,
    cardTitleProp,
    cardSubtitleProp,
    deleteLabel,
  } = fieldProps;

  const regularOrIndicacionProps = {
    addingSvfElement,
    setAddingSvfElement,
    setPrevSvf,
    setShowSvfOptions,
    goForward
  };
  
  useEffect(() => {
    setCurrentSvfHasLength(state.length > 0);
  }, [state]);

  //Handle popover de confirmación para c/u SVF
  useEffect(() => {
    if (!state.length && !prevSvf) {
      setShowPopover({ showPopover: true, event: undefined });
    } else {
      setConfirmSvf(true);
      formStep !== SVF_threshold.max && setPrevSvf(undefined);
    }

    return () => setConfirmSvf(false);
  }, []);

  return (
    <div className="sub-vet-form ion-margin">
      {confirmedSvf &&
        <>
          <Title title={Svf_title} hidden={addingSvfElement || hideSvfOptions && !state.length} />

          {!addingSvfElement && (
            <>
              {/* hideSvfOptions hace referencia a aquellos SVF cuyos elementos no pueden ser añadidos como una indicación */}
              {!hideSvfOptions && (state.length === 0 || showSvfOptions) ? (
                <RegularOrIndicacion
                  regularLabel={stepsData[formStep]?.regularLabel}
                  indicacionLabel={`${stepsData[formStep]?.indicacionLabel} ` + vetName + " u otro profesional"}
                  currentSVF={stepsData[formStep].value}
                  {...regularOrIndicacionProps}
                />
              ) : (
                <>
                  {/* Show, edit and delete */}
                  {state.map((item, currentIndex) => (
                    <div key={currentIndex}>
                      <SvfElement
                        index={currentIndex}
                        setIndex={setIndex}
                        state={state}
                        setter={setState}
                        title={item[cardTitleProp]}
                        setAddingSvfElement={setAddingSvfElement}
                        subtitle={item[cardSubtitleProp ?? ""]}
                        deleteLabel={deleteLabel}
                        setShowToast={setShowToast}
                      />
                    </div>
                  ))}

                  <div hidden={addingSvfElement || !state.length} className="ion-margin-top">
                    <IonButton
                      mode="ios"
                      expand="block"
                      fill="outline"
                      color="dark"
                      onClick={() => hideSvfOptions ? setAddingSvfElement(true) : setShowSvfOptions(true)}
                    >
                      {newLabel}
                    </IonButton>

                    <Continue modifyStep={goForward} />
                  </div>
                </>
              )}
            </>
          )}

          {(addingSvfElement || hideSvfOptions && !state.length) && form}
        </>
      }
    </div>
  );
};

export default SubVetForm;
