import "./Alert.scss";
import { AlertProps } from "./types";
import { IonAlert } from "@ionic/react";
import React from "react";

const Alert: React.FC<AlertProps> = ({
  isOpen,
  setIsOpen,
  header,
  subHeader,
  buttonsLabel,
  actions,
  didDismissActions,
  deleteStyle
}) => {
  return (
    <div className="alert">
      <IonAlert
        mode="ios"
        isOpen={isOpen}
        onDidDismiss={() => {
          setIsOpen(false);
          didDismissActions && didDismissActions();
        }}
        header={header ?? "Aviso"}
        subHeader={subHeader}
        cssClass="my-custom-class"
        buttons={[
          {
            text: buttonsLabel.primary,
            handler: actions.primary,
            cssClass: deleteStyle ? "" : "primary",
          },
          {
            text: buttonsLabel.secondary,
            handler: actions.secondary,
            cssClass: deleteStyle ? "delete" : "secondary",
          },
        ]}
      />
    </div>
  );
};

export default Alert;
