import { AddVetForm } from "../interfaces/AddVetForm";
import axiosInstance from "./";
import { VET_FORM_FIESTA } from "./constants";

export const postVetForm = async ({
  vetFormId,
  veterinario,
  consulta,
  sintomas,
  medicamentos,
  estudios,
  practicas,
  cirugias,
  internaciones,
  indicaciones,
  diagnostico,
}: AddVetForm): Promise<boolean> => {
  try {
    await axiosInstance.post(VET_FORM_FIESTA, {
      vetFormId: vetFormId,
      veterinario: veterinario,
      consulta: consulta,
      sintomas: sintomas,
      medicamentos: medicamentos,
      estudios: estudios,
      practicas: practicas,
      cirugias: cirugias,
      internaciones: internaciones,
      indicaciones: indicaciones,
      diagnostico: diagnostico,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
