import React, { useContext, useEffect, useState } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { useForm } from "react-hook-form";
import { MedicamentosFormProps } from "./types";
import { Option, submitSvf } from "../../utils/Form";
import { MedicamentoContext } from "../../context/MedicamentosContext";
import { Medicamento, getEmptyMedicamento } from "../../interfaces/Medicamento";
import Title from "../Title";
import FormInput from "../FormInput";
import FormSelectOrCreate from "../FormSelectOrCreate";
import MedicamentoCard from "../MedicamentoCard";
import AddingSvfBtns from "../AddingSvfBtns";

const MedicamentosForm: React.FC<MedicamentosFormProps> = ({
  medicamentos: medicamentosArray,
  setMedicamentos,
  setAddingSvfElement,
  index,
  setShowToast,
}) => {
  const { searchableMedicamentos, medicamentos } = useContext(MedicamentoContext);
  const { handleSubmit, control, errors, setValue, watch } = useForm<Medicamento>();
  const formMethods = { control, errors, setValue };
  const [medicamento, setMedicamento] = useState<Medicamento>(getEmptyMedicamento);
  const [option, setOption] = useState<Option | null>();

  const nombre_comercial: string = watch('nombre_comercial');

  const medicamentoSetter = () => {
    const found = medicamentos.find(
      (e) => e.nombre_comercial === nombre_comercial
    );

    setMedicamento(() => (found ?? {
      //Medicamento creado por el veterinario
      ...getEmptyMedicamento(),
      nombre_comercial: nombre_comercial,
    }));

    setValue("id", found?.id ?? "0");
  };

  const onSubmit = (values: Medicamento) => {
    submitSvf(index, medicamentosArray, setMedicamentos, values, setShowToast)
  };

  useEffect(() => {
    medicamentoSetter();
  }, [nombre_comercial]);

  useEffect(() => {
    if (index !== undefined) {
      setMedicamento(medicamentosArray[index])
      setOption({ label: medicamentosArray[index].nombre_comercial, value: "" })
    }
  }, [index]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <Title title={index !== undefined ? "Editar medicamento" : "Nuevo medicamento"} />

      <IonGrid className="ion-no-padding">

        <div hidden >
          {/* Id medicamento*/}
          <FormInput
            formMethods={formMethods}
            fieldProps={{
              name: 'id', label: '',
            }}
          />
        </div>

        <IonRow>
          <IonCol>
            <FormSelectOrCreate
              creatable={true}
              options={searchableMedicamentos}
              option={option}

              formMethods={formMethods}

              fieldProps={{
                name: 'nombre_comercial',
                label: 'Puede buscar por droga, nombre comercial o tipo de acción:',
                placeholder: "Seleccione una droga..."
              }}

              validationProps={{
                errorMessage: 'Por favor ingrese una droga',
              }}
            />
          </IonCol>
        </IonRow>

        <MedicamentoCard medicamento={medicamento} />
      </IonGrid>

      <AddingSvfBtns
        label={index !== undefined ? "cambios" : "medicamento"}
        action={() => {
          setAddingSvfElement(false);
          setOption(null);
        }}
      />
    </form>
  );
};

export default MedicamentosForm;
