import { useSubVetForm } from "../../context/SubVetFormContext";
import { useGetDatosUsuario } from "../../hooks/useDatosUsuario";
import { getPetAge } from "../../utils/DateUtilities";
import Continue from "../Continue";
import Spinner from "../Spinner";
import Title from "../Title";
import "./DatosUsuario.scss";
import { DatosUsuarioProps } from "./types";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { paw, person, reader } from "ionicons/icons";
import React, { useEffect } from "react";

const DatosUsuario: React.FC<DatosUsuarioProps> = ({
  siniestroId,
  formStep,
  goForward,
}) => {
  const { setDatosUsuario } = useSubVetForm();
  const { datos } = useGetDatosUsuario(siniestroId);
  const { nombre, apellido, tel_celular } = datos?.persona || {};
  const {
    nombre: nombreMascota,
    animal,
    raza,
    sexo,
    fecha_nacimiento,
  } = datos?.mascota || {};
  const denuncia = datos?.denuncia_administrativa || {};

  useEffect(() => {
    datos && setDatosUsuario(datos);
  }, [datos]);

  return (
    <div className="datos-usuario ion-margin">
      {datos ? (
        <IonGrid className="ion-no-padding">
          <Title title="Datos del tutor" />

          <IonRow>
            <IonCol size="3">
              <IonIcon icon={person} />
            </IonCol>
            <IonCol>
              <div>
                {nombre} {apellido}
              </div>
              <div>{tel_celular}</div>
            </IonCol>
          </IonRow>

          <Title title="Datos del paciente" />

          <IonRow>
            <IonCol size="3">
              <IonIcon icon={paw} />
            </IonCol>
            <IonCol>
              <div>{nombreMascota}</div>
              <div>
                {animal === "Perro" ? "Canino" : "Felino"}, {raza}
              </div>
              <div>
                {sexo === "h" || "H" ? "Macho" : "Hembra"},{" "}
                {getPetAge(fecha_nacimiento)}
              </div>
            </IonCol>
          </IonRow>

          <Title title="Descripción del tutor" />

          <IonRow>
            <IonCol size="3">
              <IonIcon icon={reader} />
            </IonCol>
            <IonCol>
              <div>{denuncia}</div>
            </IonCol>
          </IonRow>

          <div className="ion-padding-top">
            <Continue formStep={formStep} modifyStep={goForward} />
          </div>
        </IonGrid>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default DatosUsuario;
