import axiosInstance from '.';
import { AddVetFormLinkDataReq } from "../interfaces/AddVetFormLinkData";
import { ADD_VETFORM_LINK_DATA_FIESTA} from "./constants";


export const postVetFormLinkData = async ({
    vetFormLinkData
  }: AddVetFormLinkDataReq): Promise<object> => {
    try {
      return await axiosInstance.post(ADD_VETFORM_LINK_DATA_FIESTA, vetFormLinkData);
      // return true;
    } catch (e) {
      console.error(e.response);
      // return false;
      return {}
    }
  };