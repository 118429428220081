import React, { useEffect } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { IndicacionGenericaFormProps } from './types';
import './IndicacionGenericaForm.scss';
import { validationData } from "../../assets/validations/validationRegex";
import { capitalizeFirstLetter } from "../../utils/General";
import { estudioOptions, noFarmacologicaOptions, practicaOptions, setFormValuesFromObject } from "../../utils/Form";
import { indicacionesMaxDate } from "../../utils/DateUtilities";
import FormInput from "../FormInput";
import FormDate from "../FormDate";
import FormSelectPopover from "../FormSelectPopover";

const IndicacionGenericaForm: React.FC<IndicacionGenericaFormProps> = ({ indicaciones, prevSvf, tipoIndicacion, index, formMethods, formattedFechaConsulta }) => {
  const { setValue } = formMethods;

  const avoid = ["Práctica", "practicas", "Estudio", "estudios", "No farmacológica"];

  const regular = ![prevSvf, tipoIndicacion].filter(term => term !== undefined).some(term => avoid.includes(term as string));

  // Reset campo cuando cambia tipoIndicacion
  useEffect(() => {
    setValue("descripcion", undefined)
  }, [tipoIndicacion])

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(indicaciones[index], setValue);
    }
  }, [index]);

  return (
    <div
      className="indicacion-generica-form"
    >
      <IonGrid className="ion-no-padding">

        {regular ?
          <IonRow>
            <IonCol>
              {/* Descripción */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: 'descripcion', label: 'Descripción breve de la indicación',
                  format: capitalizeFirstLetter,
                }}
                validationProps={{
                  errorMessage: 'Por favor ingrese una descripción',
                  pattern: validationData,
                }}
              />
            </IonCol>
          </IonRow>
          :
          <IonRow>
            <IonCol>
              {/* Practica o estudio */}
              <FormSelectPopover
                formMethods={formMethods}
                options={tipoIndicacion === "No farmacológica" ? noFarmacologicaOptions : (tipoIndicacion === "Práctica" || prevSvf === "practicas" ? practicaOptions : estudioOptions)}
                fieldProps={{
                  name: 'descripcion', label: `${tipoIndicacion === "No farmacológica" ? "No farmacológica" : (tipoIndicacion === "Práctica" || prevSvf === "practicas" ? "Práctica a realizar" : "Estudio a realizar")}`
                }}
                validationProps={{
                  errorMessage: 'Por favor seleccione',
                }}
              />
            </IonCol>
          </IonRow>
        }

        <IonRow>
          <IonCol>
            {/* Fecha */}
            <FormDate
              formMethods={formMethods}
              fieldProps={{
                name: 'fecha', label: 'Fecha aproximada', placeholder: "Opcional, para recordatorio del cliente."
              }}
              date={{
                min: formattedFechaConsulta,
                max: indicacionesMaxDate()
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div >
  )
}

export default IndicacionGenericaForm;