import { getGanadores as axiosGetGanadores } from "../axios/getGanadores";
import { useState, useEffect } from "react";

type ganadores = {
  id: number;
  nombre: string | null;
  email: string | null;
  telefono: string | null;
  identificador: string | null;
};

export const useGanadores = () => {
  const [ganadores, setGanadores] = useState<ganadores[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getGaandores = async () => {
    setIsLoading(true);

    const ganadores = await axiosGetGanadores();
    setGanadores(ganadores);

    setIsLoading(false);
  };

  useEffect(() => {
    getGaandores();
  }, []);

  return { ganadores, isLoading };
};
