import {
  validationData,
  validationMoney,
} from "../../assets/validations/validationRegex";
import { useMedia } from "../../context/MediaContext";
import { useSubVetForm } from "../../context/SubVetFormContext";
import { Estudio } from "../../interfaces/forms/Estudio";
import {
  checkAndResetMedia,
  createBase64ArrayFromMedia,
  setFormValuesFromObject,
  submitSvf,
} from "../../utils/Form";
import { capitalizeFirstLetter } from "../../utils/General";
import AddingSvfBtns from "../AddingSvfBtns";
import FileUploader from "../FileUploader";
import FormInput from "../FormInput";
import FormSelectPopover from "../FormSelectPopover";
import Title from "../Title";
import { EstudiosFormProps } from "./types";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

const EstudiosForm: React.FC<EstudiosFormProps> = ({
  formattedEstudios,
  index,
  setAddingSvfElement,
  setShowToast,
}) => {
  const { estudios, setEstudios } = useSubVetForm();
  const { currentMedia, setCurrentMedia, setShowNoFileInCurrentMediaToast } =
    useMedia();
  const { handleSubmit, control, errors, setValue, watch } = useForm<Estudio>();
  const formMethods = { control, errors };
  const estudio: string | undefined = watch("estudio");

  const onSubmit = async (values: Estudio) => {
    values.estudioId = String(formattedEstudios.indexOf(values.estudio!) + 1);

    if (currentMedia) {
      const informe  = await createBase64ArrayFromMedia(currentMedia);
      values.media = currentMedia;
      values.estudioInforme = informe;
    }

    submitSvf(index, estudios, setEstudios, values, setShowToast);
  };

  useEffect(() => {
    if (index !== undefined) {
      setFormValuesFromObject(estudios[index], setValue);
      setCurrentMedia(estudios[index].media!);
    }
  }, [index]);

  //Reset currentMedia cuando hay media cargada con un tipo de estudio especifico
  useEffect(() => {
    checkAndResetMedia(
      estudio,
      currentMedia,
      setCurrentMedia,
      setShowNoFileInCurrentMediaToast
    );
  }, [estudio, currentMedia]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonGrid className="ion-no-padding">
          <Title
            title={index !== undefined ? "Editar estudio" : "Nuevo estudio"}
          />

          <div hidden>
            {/* Estudio Id*/}
            <FormInput
              formMethods={formMethods}
              fieldProps={{
                name: "estudioId",
                label: "",
              }}
            />
          </div>

          <IonRow>
            <IonCol>
              {/* Estudio */}
              <FormSelectPopover
                formMethods={formMethods}
                options={formattedEstudios}
                fieldProps={{
                  name: "estudio",
                  label: "Estudio realizado",
                }}
                validationProps={{
                  errorMessage: "Por favor seleccione un estudio",
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Descripción */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "descripcion",
                  label: "Descripción del estudio",
                  format: capitalizeFirstLetter,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese una descripción",
                  pattern: validationData,
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              {/* Costo */}
              <FormInput
                formMethods={formMethods}
                fieldProps={{
                  name: "costo",
                  label: "Costo del estudio",
                  typeNumber: true,
                }}
                validationProps={{
                  errorMessage: "Por favor ingrese un monto",
                  pattern: validationMoney,
                }}
              />
            </IonCol>
          </IonRow>

          <FileUploader
            tag={{ type: "estudio", detail: estudio! }}
            disabled={!estudio && index === undefined}
          />

          <AddingSvfBtns
            label={index !== undefined ? "cambios" : "estudio"}
            action={() => setAddingSvfElement(false)}
          />
        </IonGrid>
      </form>
    </>
  );
};

export default EstudiosForm;
