import React from 'react';
import { IonItem, IonInput, IonLabel } from '@ionic/react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from './types';
import './FormInput.scss';
import { getErrors } from '../../utils/Form';

const FormInput: React.FC<FormInputProps> = ({
  formMethods,
  fieldProps,
  validationProps
}) => {
  const { control, errors } = formMethods;
  const { name, label, placeholder, format, typeNumber } = fieldProps;
  const { errorMessage, pattern } = validationProps || {};

  return (
    <Controller
      name={name}
      control={control}
      errors={errors}
      rules={{
        required: {
          value: validationProps ? true : false,
          message: errorMessage ?? "",
        },
        pattern: pattern ?? undefined,
      }}
      render={(
        { onChange, name, value }
      ) => (
        <div className="form-input">
          <IonItem lines="inset" className="ion-no-padding">
            <IonLabel position="floating">{label}</IonLabel>
            <IonInput
              onIonChange={onChange}
              name={name}
              value={format ? format(value) : value}
              type={!typeNumber ? "text" : "tel"}
              placeholder={placeholder ? placeholder : ""}
            />
          </IonItem>
          <div
            className="form-input__error-message"
            style={getErrors(errors, name) ? { opacity: 1 } : undefined}
          >
            {getErrors(errors, name)?.message}
          </div>
        </div>
      )}
    />
  );
};

export default FormInput;
