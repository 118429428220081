import { GET_DATOS_USUARIO } from "../axios/constants";
import { getDatosUsuario } from "../axios/getDatosUsuario";
import useSWR from "swr";

export const useGetDatosUsuario = (siniestroId: string) => {
  const { data, error, mutate } = useSWR(
    [GET_DATOS_USUARIO, siniestroId],
    (url) => getDatosUsuario(url, siniestroId)
  );

  return { datos: data, mutate, error };
};
