export const colegios = [
  "Buenos Aires",
  "CABA",
  "Catamarca",
  "Chaco",
  "Chubut",
  "Corrientes",
  "Cordoba",
  "Entre Ríos",
  "Formosa",
  "Jujuy",
  "Misiones",
  "Neuquén",
  "La Pampa",
  "La Rioja",
  "Río Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe  1° CIRCUNSCRIPCIÓN",
  "Santa Fe  2° CIRCUNSCRIPCIÓN",
  "Tierra del Fuego e Islas del Atlantico Sur",
  "Tucumán",
];

export const getProvinces = () => {
  return [
    "Capital Federal",
    "Buenos Aires",
    "Catamarca",
    "Chaco",
    "Chubut",
    "Cordoba",
    "Corrientes",
    "Entre Rios",
    "Formosa",
    "Jujuy",
    "La Pampa",
    "La Rioja",
    "Mendoza",
    "Misiones",
    "Neuquen",
    "Rio Negro",
    "Salta",
    "Santa Cruz",
    "Santa Fe",
    "Santiago del Estero",
    "San Luis",
    "San Juan",
    "Tierra del Fuego",
    "Tucuman",
  ];
};
