import Bin from "../../assets/bin.png";
import Pen from "../../assets/pen.png";
import { formatDate } from "../../utils/DateUtilities";
import { deleteElement } from "../../utils/Form";
import {
  capitalizeEveryWord,
  capitalizeFirstLetter,
  cleanUndefined,
  removeSpecialCharacters,
} from "../../utils/General";
import Alert from "../Alert";
import "./SvfElement.scss";
import { SvfElementProps } from "./types";
import { IonIcon } from "@ionic/react";
import { attachSharp, person } from "ionicons/icons";
import React, { useState } from "react";

const SvfElement: React.FC<SvfElementProps> = ({
  index,
  setIndex,
  state,
  setter,
  title,
  subtitle,
  setAddingSvfElement,
  deleteLabel,
  setShowToast,
}) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const element = state[index];

  const handleEdit = () => {
    setIndex(index);
    setAddingSvfElement(true);
  };

  return (
    <div className="svf-element ion-padding-bottom">
      <div className="svf-element__card ion-margin-top">
        <div className="optionsIcons">
          <div onClick={() => setShowAlert(true)}>
            <img src={Bin} />
          </div>

          <div onClick={() => handleEdit()}>
            <img src={Pen} />
          </div>
        </div>

        {/* Regular interactua con utils/form */}
        <div>
          <div className="title">
            {/* {capitalizeFirstLetter(
              removeSpecialCharacters(title).toLowerCase()
            )} */}
            {capitalizeFirstLetter(
              removeSpecialCharacters(title).toLowerCase()
            )}
          </div>
          <div className="subtitle">
            {subtitle && capitalizeFirstLetter(cleanUndefined(subtitle))}
          </div>

          {/* Síntomas exception */}
          {element.sintoma && element.fechaEstimada && (
            <div>
              <div className="sintomasDate">
                {capitalizeFirstLetter(element.fechaEstimada)}
              </div>
            </div>
          )}

          {element.media && element.media.length !== 0 && (
            <div className="withMedia">
              <IonIcon icon={attachSharp} />
              {element.media.length === 1
                ? "1 elemento adjunto"
                : `${element.media.length} elementos adjuntos`}
            </div>
          )}
        </div>

        {/* Internaciones exception */}
        {element.fechaInicio && element.fechaFin && (
          <div>
            <div className="internacion_dateTitle">
              Inicio: <span>{formatDate(element.fechaInicio)}</span>
            </div>
            <div className="internacion_dateTitle">
              Finalización: <span>{formatDate(element.fechaFin)}</span>
            </div>
            {/* <div className="subtitle">{subtitle}</div> */}
          </div>
        )}

        {/* Indicación farmacológica exception */}
        {element.medicamento && element.dosaje && (
          <div>
            <div className="medicamento">
              {capitalizeEveryWord(element.medicamento)}
            </div>
            <div className="subtitle">Dosaje: {element.dosaje}</div>
            <div className="subtitle">Duración: {element.duracion}</div>
            <div className="subtitle">Frecuencia: {element.frecuencia}</div>
          </div>
        )}

        {/* Veterinarios sugeridos exception */}
        {element.suggestedVets && (
          <div className="suggestedVets-container">
            {element.suggestedVets.map((vet, index) => (
              <div key={index}>
                {index === 0 && (
                  <div className="suggestedVets_title">
                    Profesionales sugeridos
                  </div>
                )}
                <div className="suggestedVet">
                  <IonIcon icon={person} />
                  {vet.nombre} {vet.apellido}
                </div>
                {/* Es posible agregar más campos del vet... */}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Delete element */}
      <Alert
        isOpen={showAlert}
        setIsOpen={setShowAlert}
        subHeader={deleteLabel}
        buttonsLabel={{
          primary: "Conservar",
          secondary: "Eliminar",
        }}
        actions={{
          primary: () => setShowAlert(false),
          secondary: () => {
            deleteElement(setter, index);
            setShowToast(true);
          },
        }}
        deleteStyle
      />
    </div>
  );
};

export default SvfElement;
