import useSWR from 'swr';

import { GET_SINIESTRO } from '../axios/constants';
import { getSiniestro } from '../axios/siniestros';

export const useGetSiniestro = (siniestroId: string) => {
  const { data, isValidating, error,mutate } = useSWR(
    [GET_SINIESTRO, siniestroId],
    (url) => getSiniestro(url, siniestroId),
    {
      refreshInterval:0,
      refreshWhenHidden:false,
      refreshWhenOffline:false,
      revalidateOnFocus:false,
      revalidateOnMount:false,
      revalidateOnReconnect:false
    }
  );

  return { data, error, isValidating, mutate };
};
