import Logo from "../../assets/FielPetLogo.png";
import "./HomePage.scss";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import {
  globeOutline,
  logoFacebook,
  logoInstagram,
  logoWhatsapp,
} from "ionicons/icons";
import React from "react";

const HomePage: React.FC = () => {
  return (
    <IonPage className="confirm-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent>
        <div>
          <div className="ion-padding">
            <h2>FielVet, software para gestion veterinaria</h2>
          </div>

          <div className="ion-padding-top">
            <p>
              ¿Tenes alguna consulta o estas interesado en trabajar con FielPet/FielVet?
            </p>

            <div className="ion-padding">
              <p className="confirm-page__tel-email-box">
                Comunicate con nosotros por teléfono
                <a href="https://wa.me/5493412828296" target="_blank">
                  <IonIcon icon={logoWhatsapp} />
                </a>
                o por mail a
                <a href="mailto:info@fielpet.com.ar">info@fielpet.com.ar</a>
              </p>
            </div>
          </div>

          <IonGrid>
            <IonRow>
              <IonCol>
                <p className="confirm-page__redes">
                  Conoce más de nosotros en nuestras redes:
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <a href="https://www.instagram.com/fiel.pet/">
                  <IonIcon icon={logoInstagram} />
                </a>
              </IonCol>
              <IonCol size="4">
                <a href="https://www.facebook.com/FielPet-2038763733099486">
                  <IonIcon icon={logoFacebook} />
                </a>
              </IonCol>
              <IonCol>
                <a href="https://www.fielpet.com.ar">
                  <IonIcon icon={globeOutline} />
                </a>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
