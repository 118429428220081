import React, { createContext, useContext } from 'react';

import { useGetDocumentacionExistente } from '../hooks/useDocumentacionExistente';
import { useGetIndicaciones } from '../hooks/useIndicaciones';
import { useGetSiniestro } from '../hooks/useSiniestro';
import { useGetSintomasMascota } from '../hooks/useSintomasMascota';

class SiniestroContextType {
  constructor(siniestroId: string) {
    this.siniestroId = siniestroId;
    this.siniestro = useGetSiniestro(this.siniestroId);
    this.documentacion = useGetDocumentacionExistente(this.siniestroId);
    this.indicaciones = useGetIndicaciones(this.siniestroId);
    this.sintomas = useGetSintomasMascota(this.siniestroId);
  }
  siniestroId: string;
  siniestro: ReturnType<typeof useGetSiniestro>;
  documentacion: ReturnType<typeof useGetDocumentacionExistente>;
  indicaciones: ReturnType<typeof useGetIndicaciones>;
  sintomas: ReturnType<typeof useGetSintomasMascota>;
}

const SiniestroContext = createContext<SiniestroContextType | null>(null);

export const SiniestroContextProvider: React.FC<{ siniestroId: string }> = ({
  siniestroId,
  children,
}) => {
  const siniestroContextData = new SiniestroContextType(siniestroId);

  return (
    <SiniestroContext.Provider value={siniestroContextData}>
      {children}
    </SiniestroContext.Provider>
  );
};

export const useSiniestro: () => SiniestroContextType = () => {
  const siniestroContext = useContext(SiniestroContext);
  if (!siniestroContext) throw Error('siniestro context unavailable');
  return siniestroContext;
};
