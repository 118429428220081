import React, { ReactNode } from 'react';
import { CSSProperties } from 'react';
import './ArcItem.css';
import { capitalizeFirstLetter } from '../../utils/General';

export type ArcItemProps = {
  label: string,
  onClick?: (a: any) => any,
  index?: number,
  item_height?: number,
  item_offset?: number,
  currentItemsRefs?: (HTMLDivElement | null)[],
  detailColor?: string,
  detailIcon?: any,
  itemClass?: string
};

const ArcItem: React.FC<ArcItemProps> = ({
  label,
  onClick,
  index,
  item_height,
  item_offset,
  currentItemsRefs,
  detailColor,
  detailIcon,
  itemClass
}) => {
  const DEFAULT_HEIGHT = 35;
  const DEFAULT_OFFSET = 30;
  const getRandomColor: () => string = () => {
    const res = `rgba(${Math.random() * 200 + 20},${Math.random() * 200 + 20},${Math.random() * 200 + 20},1)`; // @TODO
    return res;
  }

  const customStyles: CSSProperties = {
    position: 'relative',
    left: `${item_offset ?? DEFAULT_OFFSET}px`,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    maxWidth: "fit-content",
    padding: '0.9rem',
    fontSize: "0.9rem",
    fontWeight: "500",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    background: "#fff",
    // flexDirection: 'row',
    // justifyContent: 'start',
    // minHeight: `${item_height ?? DEFAULT_HEIGHT}px`,
    // scrollSnapAlign: 'start',
    // boxSizing: 'content-box',
  };

  const itemStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "1rem",
    height: "1rem",
    borderRadius: '50%',
    backgroundColor: detailColor ?? getRandomColor(),
    // minHeight: `${item_height ?? DEFAULT_HEIGHT}px`,
    // minWidth: `${item_height ?? DEFAULT_HEIGHT}px`,
  };

  return (
    <div
      className={`arc-item-hover ${itemClass}`}
      style={customStyles}
      ref={currentItemsRefs && currentItemsRefs !== null && (typeof index === 'number') ? (ref => currentItemsRefs[index] = ref) : undefined}
      key={label}
      onClick={onClick}
    >
      <div style={itemStyles}>
        {detailIcon && <img src={detailIcon} />}
      </div>
      {capitalizeFirstLetter(label)}
    </div>
  )
};

export default ArcItem;
