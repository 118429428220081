export type Consulta = {
  motivoConsulta?: string;
  fechaInicio?: Date;
  costo: string;
  localizacion: string;
};

export const getEmptyConsulta: () => Consulta = () => ({
  motivoConsulta: undefined,
  fechaInicio: undefined,
  costo: "",
  localizacion: "",
});
