import Logo from "../../assets/FielPetLogo.png";
import InfoLayout from "../../components/InfoLayout";
import "./ConfirmPage.scss";
import { IonContent, IonHeader, IonPage } from "@ionic/react";
import React from "react";
import { useLocation } from "react-router";

const ConfirmPage: React.FC = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const vet = searchParams.get("vet") ?? "_";
  const owner = searchParams.get("owner") ?? "_";

  return (
    <IonPage className="confirm-page">
      <IonHeader className="ion-no-border">
        <img src={Logo} />
      </IonHeader>
      <IonContent>
        <InfoLayout
          title={`Muchas gracias ${vet.replace(
            "_",
            " "
          )}. Ahora podemos continuar con el
          reintegro del cliente ${owner.replace("_", " ")}.`}
          hideSubtitle={false}
        />
      </IonContent>
    </IonPage>
  );
};

export default ConfirmPage;
