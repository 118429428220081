import { PhotoItem } from "../PhotoItem";

export type Practica = {
  practica?: string;
  practicaId: string;
  descripcion: string;
  costo: string;
  media?: PhotoItem[];
  practicaInforme?: string[];
};

export const getEmptyPracticas: Practica[] = [];
